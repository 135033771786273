import Axios from "axios";
import Lottie from "lottie-react-web";
import React, { Component } from "react";
import loader from "../../images/loader.json";
import Constants from "../../components/common/Constants";
import Notification from "../../components/Notification";
import closeEyeImg from "../../images/closeEyeImg.png";
import eye from "../../images/eyeImg.png";
export default class ChangePassword extends Component {
  state = {
    email: "",
    btnLoading: false,
    invalid: false,
    error: false,
    sentEmaiLink: false,
    old_pass: "",
    new_pass: "",
    confirm_pass: "",
    notiMessage: "",
    showPassError: "",
    loading: false,
    openEye: false,
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitEmail = (e) => {
    if (
      this.state.old_pass &&
      this.state.new_pass &&
      this.state.confirm_pass &&
      this.state.new_pass === this.state.confirm_pass
    ) {
      e.preventDefault();
      this.setState({
        notiMessage: " Password Changed Successfully ...",
        showError: false,
        btnLoading: true,
      });
      const payload = {
        old_pass: this.state.old_pass,
        new_pass: this.state.new_pass,
      };
      console.log(payload);
      // if (this.state.new_pass === this.state.confirm_pass) {
      Axios.post(Constants.postUrls.confirmPass, payload).then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          this.setState({
            showNotif: true,
            btnLoading: false,
          });
          setTimeout(() => {
            this.setState({
              showNotif: false,
            });
          }, 1500);
          window.location.href = "/dashboard/profile";
        } else {
          if (res.data.status === "fail") {
            this.setState({
              showError: true,
              showPassError: res.data.message,
              btnLoading: false,
            });
            setTimeout(() => {
              this.setState({
                showError: false,
              });
            }, 2000);
            // document.getElementById("myForm").reset();
          }
        }
      });
      // } else {
      //   this.setState({
      //     invalid: true,
      //     showError: true,
      //     btnLoading: false,
      //     showNotif: false,
      //   });
      // }
    } else {
      this.setState({
        invalid: true,
        showError: true,
        btnLoading: false,
        showNotif: false,
        passError: false,
      });
    }
  };
  render() {
    return (
      <div>
        <section className="pwd-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <h2 className="changePassword" style={{ paddingTop: "40px" }}>
                  Change Your Password
                </h2>
                <form
                  className="theme-form changePassword"
                  id="myForm"
                  onSubmit={this.submitEmail}
                >
                  {/* {this.state.sentEmaiLink ? ( */}
                  {/* <div>
                    <h4>
                      We have sent you an email with password reset link. Please
                      click on the link given in the email
                    </h4>
                  </div> */}
                  {/* // ) : ( */}
                  <div className="form-row">
                    <div className="col-md-12">
                      <input
                        type="password"
                        className="form-control"
                        name="old_pass"
                        value={this.state.old_pass}
                        // id="old_pass"
                        placeholder="Old Password"
                        required=""
                        onChange={this.handleChange}
                      />
                      <input
                        type={this.state.openEye ? "text" : "password"}
                        className="form-control"
                        name="new_pass"
                        value={this.state.new_pass}
                        // id="new_pass"
                        placeholder="New Password"
                        required=""
                        onChange={this.handleChange}
                      />
                      {this.state.openEye === true ? (
                        <img
                          src={eye}
                          alt=""
                          className="eyeIcon"
                          style={{
                            top: "88px",
                            right: "23px",
                          }}
                          onClick={(e) =>
                            this.setState({
                              openEye: false,
                              closeEye: true,
                            })
                          }
                        />
                      ) : (
                        <img
                          src={closeEyeImg}
                          alt=""
                          className="closeEyeIcon"
                          style={{
                            top: "88px",
                            right: "23px",
                          }}
                          onClick={(e) =>
                            this.setState({
                              openEye: true,
                              closeEye: false,
                            })
                          }
                        />
                      )}
                      <input
                        type="password"
                        className="form-control"
                        name="confirm_pass"
                        value={this.state.confirm_pass}
                        // id="new_pass"
                        placeholder="Confirm Password"
                        required=""
                        // onChange={this.handleChange}
                        onChange={(e) =>
                          this.setState(
                            {
                              confirm_pass: e.target.value,
                            },
                            () => {
                              if (
                                this.state.new_pass === this.state.confirm_pass
                              ) {
                                this.setState({
                                  passError: false,
                                });
                              } else {
                                this.setState({
                                  passError: true,
                                });
                              }
                            }
                          )
                        }
                      />
                    </div>
                    <div className="col-md-12">
                      {this.state.invalid ? (
                        <p style={{ color: "red" }}>
                          {" "}
                          Password and confirm password should be same.
                        </p>
                      ) : null}
                    </div>
                    {this.state.passError ? (
                      <p
                        style={{
                          color: "red",
                          textAlign: "center  ",
                          marginTop: "20px",
                          textTransform: "capitalize",
                        }}
                      >
                        Password and confirm password should be same.
                      </p>
                    ) : null}

                    <button
                      className="btn btn-solid chamgePassBtn"
                      disabled={
                        this.state.old_pass &&
                        this.state.new_pass &&
                        this.state.confirm_pass
                          ? false
                          : true
                      }
                      type="submit"
                    >
                      {this.state.btnLoading ? (
                        <Lottie
                          options={{
                            animationData: loader,
                          }}
                          style={{
                            width: "40px",
                            height: "20px",
                          }}
                        />
                      ) : (
                        "Change Password"
                      )}
                    </button>
                    <br />
                  </div>
                </form>
                {this.state.showError ? (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center  ",
                      marginTop: "20px",
                      textTransform: "capitalize",
                    }}
                  >
                    {" "}
                    {this.state.showPassError}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </section>
        {this.state.showNotif ? (
          <Notification message={this.state.notiMessage} />
        ) : null}
      </div>
    );
  }
}
