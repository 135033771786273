import React, { Component } from "react";
import Axios from "axios";
import { Navigate } from "react-router-dom";
import init from "../components/helper/WindowToken";
import Constants from "./common/Constants";
import Header from "./common/Header";
import Footer from "./common/Footer";
import notificationLoader from "../images/notification_loader.json";
import Lottie from "lottie-react-web";
export default class EditUserProfile extends Component {
  state = {
    userdetails: {},
    name: "",
    email: "",
    mobile: "",
    city: "",
    stateM: "",
    address_1: "",
    pincode: "",
    redirect: false,
  };
  componentDidMount() {
    // let userToken = localStorage.getItem("token");
    if (init() === "success") {
      Axios.get(Constants.getUrls.user).then((res) => {
        console.log(res);
        this.setState(
          {
            userdetails: res.data,
          },
          () => {
            this.setState({
              name: this.state.userdetails.FIRST_NAME,
              email: this.state.userdetails.email,
              mobile: parseInt(this.state.userdetails.MOBILE),
              city: this.state.userdetails.CITY,
              stateM: this.state.userdetails.STATE,
              address_1: this.state.userdetails.ADDRESS_1,
              address_2: this.state.userdetails.ADDRESS_2,
              pincode: parseInt(this.state.userdetails.PINCODE),
              showCorrectName: true,
            });
          }
        );
      });
    } else {
      alert("null");
    }
  }

  onSubmit = () => {
    if (
      this.state.showCorrectName ||
      this.state.showCorrectEmail ||
      this.state.showCorrectCity ||
      this.state.mobilevalidate ||
      this.state.showCorrectState 
    ) {
      this.setState({ loading: true });
      let userToken = localStorage.getItem("token");
      const payload = {
        name: this.state.name,
        email: this.state.email,
        mobile: this.state.mobile,
        city: this.state.city,
        state: this.state.stateM,
        address_1: this.state.address_1,
        address_2: this.state.address_2,
        pincode: this.state.pincode,
      };
      console.log(payload);
      Axios.post(Constants.postUrls.updateAddress, payload, {
        headers: { Authorization: "Bearer " + userToken },
      }).then((res) => {
        console.log(res);
        this.setState({
          redirect: true,
          loading: false,
        });
      });
    } else {
      alert("Enter Valid Details");
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  validateName = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        name: text,
        showCorrectName: false,
        showNameError: true,
      });
      return false;
    } else {
      this.setState({
        name: text,
        showCorrectName: true,
        showNameError: false,
      });
      console.log("Name is Correct");
    }
  };
  validateEmail = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      this.setState({
        email: text,
        showCorrectEmail: false,
        showEmailError: true,
      });
      return false;
    } else {
      this.setState({
        email: text,
        showCorrectEmail: true,
        showEmailError: false,
      });
      console.log("Email is Correct");
    }
  };

  validateMobile(text) {
    const reg = /^[0]?[789]\d{9}$/;
    if (reg.test(text) === false) {
      this.setState({
        mobilevalidate: false,
        mobileNumber: text,
        showmobileError: true,
      });
      return false;
    } else {
      this.setState({
        mobilevalidate: true,
        mobileNumber: text,
        message: "",
        showmobileError: false,
      });
      return true;
    }
  }
  validateCity = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        city: text,
        showCorrectCity: false,
        showCityError: true,
      });
      return false;
    } else {
      this.setState({
        city: text,
        showCorrectCity: true,
        showCityError: false,
      });
      console.log("Name is Correct");
    }
  };
  validateState = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        stateM: text,
        showCorrectState: false,
        showStateError: true,
      });
      return false;
    } else {
      this.setState({
        stateM: text,
        showCorrectState: true,
        showStateError: false,
      });
      console.log("Name is Correct");
    }
  };
  validatePincode(text) {
    const reg = /^[0]?[789]\d{5}$/;
    if (reg.test(text) === false) {
      this.setState(
        {
          showCorrectPincode: false,
          pincode: text,
          showPincodeError: true,
        },
        () => {
          console.log(this.state.showCorrectPincode);
        }
      );
      return false;
    } else {
      this.setState(
        {
          showCorrectPincode: true,
          pincode: text,
          showPincodeError: false,
        },
        () => {
          console.log(this.state.showCorrectPincode);
        }
      );
      return true;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/dashboard/profile" />;
    } else {
      return (
        <div>
          <Header />

          <div className="container">
            <div className="theme-card formdashborad">
              <div
                className="theme-form editProfile"
                // style={{ width: "50%", margin: "50px auto 50px" }}
              >
                <div className="form-group">
                  <label htmlFor="email">Name</label>
                  <input
                    style={{ width: "100%", fontSize: 15 }}
                    type="text"
                    className="form-control"
                    name="name"
                    //   value={this.state.userdetails.FIRST_NAME}
                    defaultValue={this.state.name}
                    required=""
                    onChange={(e) => {
                      this.setState({ name: e.target.value }, () => {
                        this.validateName(this.state.name);
                      });
                    }}
                  />
                  {this.state.showNameError && this.state.name.length > 0 ? (
                    <p style={{ color: "red", fontSize: 10 }}>
                      Enter valid name
                    </p>
                  ) : null}
                </div>

                <div className="form-group">
                  <label htmlFor="review">Email</label>
                  <input
                    style={{ width: "100%", fontSize: 15 }}
                    type="text"
                    className="form-control"
                    name="email"
                    //   value={this.state.userdetails.email}
                    defaultValue={this.state.email}
                    required=""
                    onChange={(e) => {
                      this.setState({ email: e.target.value }, () => {
                        this.validateEmail(this.state.email);
                      });
                    }}
                  />
                  {this.state.showEmailError && this.state.email.length > 0 ? (
                    <p style={{ color: "red", fontSize: 10 }}>
                      Enter valid e-mail address
                    </p>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="review">Local Address</label>
                  <input
                    style={{ width: "100%", fontSize: 15 }}
                    type="text"
                    className="form-control"
                    name="address_1"
                    //   value={this.state.userdetails.address}
                    defaultValue={this.state.address_1}
                    required="true"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="review">Permanent Address</label>
                  <input
                    style={{ width: "100%", fontSize: 15 }}
                    type="text"
                    className="form-control"
                    name="address_2"
                    //   value={this.state.userdetails.address}
                    defaultValue={this.state.address_2}
                    required=""
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="review">Town / City </label>
                  <input
                    style={{ width: "100%", fontSize: 15 }}
                    type="text"
                    className="form-control"
                    name="city"
                    //   value={this.state.userdetails.CITY}
                    defaultValue={this.state.city}
                    required=""
                    onChange={(e) => {
                      this.setState({ city: e.target.value }, () => {
                        this.validateCity(this.state.city);
                      });
                    }}
                  />
                  {this.state.showCityError && this.state.city.length > 0 ? (
                    <p style={{ color: "red", fontSize: 10 }}>
                      Enter valid City Name
                    </p>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="review">Pincode </label>
                  <input
                    style={{ width: "100%", fontSize: 15 }}
                    type={"number"}
                    className="form-control"
                    name="pincode"
                    defaultValue={this.state.pincode}
                    required=""
                    maxlength="6"
                    onChange={(e) => {
                      this.setState({ pincode: e.target.value }, () => {
                        // this.validatePincode(this.state.pincode);
                      });
                    }}
                  />
                  {/* {this.state.showPincodeError ? (
                    <p style={{ color: "red", fontSize: 10 }}>
                      Enter valid Pincode
                    </p>
                  ) : null} */}
                </div>
                <div className="form-group">
                  <label htmlFor="review">State </label>
                  <input
                    style={{ width: "100%", fontSize: 15 }}
                    type="text"
                    className="form-control"
                    name="state"
                    //   value={this.state.userdetails.STATE}
                    defaultValue={this.state.stateM}
                    required=""
                    onChange={(e) => {
                      this.setState({ stateM: e.target.value }, () => {
                        this.validateState(this.state.stateM);
                      });
                    }}
                  />
                  {this.state.showStateError && this.state.stateM.length > 0 ? (
                    <p style={{ color: "red", fontSize: 10 }}>
                      Enter valid State
                    </p>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="review">Phone </label>
                  <input
                    style={{ width: "100%", fontSize: 15 }}
                    type={"number"}
                    className="form-control"
                    name="mobile"
                    maxlength="10"
                    defaultValue={this.state.mobile}
                    required=""
                    onChange={(e) => {
                      this.setState({ mobile: e.target.value }, () => {
                        this.validateMobile(this.state.mobile);
                      });
                    }}
                  />
                  {this.state.showmobileError &&
                  this.state.mobile.length > 0 ? (
                    <p style={{ color: "red", fontSize: 10 }}>
                      Enter valid mobile
                    </p>
                  ) : null}
                </div>
                <button
                  className="btn btn-solid"
                  onClick={() => {
                    this.onSubmit();
                  }}
                >
                  {this.state.loading ? (
                    <Lottie
                      options={{
                        animationData: notificationLoader,
                      }}
                      style={{
                        width: "30px",
                        height: "30px",
                        margin: 0,
                      }}
                    />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }
  }
}
