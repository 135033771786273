import React, { Component } from "react";
import "../scss/Subjects.scss";
import subject_bg from "../images/sub_back.png";
import axios from "axios";
import Constants from "./common/Constants";
import { Link } from "react-router-dom";
import Header from "./common/Header";
import Footer from "./common/Footer";
import notFound from "../images/77703-no-data-found.json";
import Lottie from "react-lottie";
export default class Subjects extends Component {
  state = {
    subjects: [],
    streamName: "",
    streamId: window.location.pathname.split("/")[2],
    attempts: [],
    attempt: "",
    attemptId: "",
    attemptShow: false,
    attemptName: "",
    colorCodes: ["#2f65b4", "#df7504", "#279b14", "#6f1561"],
    comboSub: [],
    mainLoader: true,
    batch_id: "",
    subject_id: "",
    sub_id: "",
  };
  componentDidMount() {
    this.fetchData();
    axios.get(Constants.getUrls.getAttempts).then((res) => {
      this.setState(
        {
          attempts: res.data,
          attemptId: res.data[0].id,
          attemptName: res.data[0].title,
        },
        () => {
          // this.getSingleAttempt(this.state.attemptId);
          this.getSubjects();
        }
      );
    });
  }

  setIds = (sub) => {
    this.setState({
      answer3: !this.state.answer3,
    });
  };

  async fetchData(sub) {
    try {
      const resp = await axios.get(Constants.getUrls.courseLandingAllData + "?subjectMasterId=" + sub + "&subjectWingId=" + sub + "&subjectId=" + sub);
      let subject_array = resp.data.filter.reverse();
        this.setState({
          language: 'Hindi',
          batch_id: resp.data.wings[0].batch_wings_id,
          subject_id: resp.data.filter[0].id,
        });
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle rate limit exceeded error
        console.log('Too many requests. Please try again later.');
        await new Promise(resolve => setTimeout(resolve, 5000)); // Introduce a delay of 5 seconds before retrying
        this.fetchData(); // Retry the API request
      } else {
        // Handle other errors
        console.log('An error occurred.');
      }
    }
  }

  getSubjects = async () => {
    await axios
      .get(
        Constants.getUrls.getStreamsSubject +
          "?stream_id=" +
          this.state.streamId
      )
      .then((resp) => {
        if (resp.data.data.length > 0) {
          const NormalSubjects = resp.data.data.filter(
            (item) => item.combo_type === "Normal"
          );
          const ComboSubjects = resp.data.data.filter(
            (item) => item.combo_type === "Combo"
          );
          this.setState({
            subjects: NormalSubjects,
            comboSub: ComboSubjects,
            streamName: resp.data.data[0].stream.name,
            mainLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("subjectserr", error.response);
      });
  };

  getSingleAttempt = (id) => {
    axios.get(Constants.getUrls.attempts + '/' + id).then((resp) => {
      console.log(resp);
      if (resp.data.status === "suceess") {
        this.setState(
          {
            attemptName: resp.data.data.title,
          },
          () => {
            if (resp.data.data.id !== 4) {
              this.getSubjects();
            }
          }
        );
      }
    });
  };

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notFound,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        <Header />
        <section className="section1sub">
          <h1 className="fonts" style={{
            fontSize: window.innerWidth < 768 ? "25px" : "35px",
            position: "relative",
            left: window.innerWidth < 768 ? "80px" : "55px",
            top: window.innerWidth < 768 ? "40px" : "5px",
            }}> {this.state.streamName}</h1>
        </section>
        <div className=" paddingContainer">
          <section className="section2 ">
            <div className="row">
              <div className="col-sm-6">
                <h3>
                  Showing Courses for{" "}
                  <span style={{ color: "#077c07" }}>
                    {this.state.attemptName}
                  </span>{" "}
                </h3>
              </div>
              <div className="col-sm-6">
                <div className="selectTab">
                  <span> Select Your CA Foundation Attempt</span>
                  <span>
                    {" "}
                    <select
                      name="attempt"
                      id=""
                      value={this.state.attemptId}
                      style={{ color: "#747474" }}
                      className="selectAttSub"
                      onChange={(e) => {
                        this.setState(
                          {
                            attemptShow: true,
                            attemptId: e.target.value,
                          },
                          () => {
                            this.getSingleAttempt(this.state.attemptId);
                          }
                        );
                      }}
                    >
                      {/* <option value="">Select Attempt</option> */}
                      {this.state.attempts.map((attempt) => (
                        <option value={attempt.id} key={attempt.id}>
                          {attempt.title}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="section3">
            <div className="row">
              {!this.state.mainLoader && (
                <>
                  {this.state.subjects.length > 0 ? (
                    <>
                      {this.state.subjects.map((subject, index) => (
                        <div
                          className="col-md-3"
                          key={index}
                          style={{ marginTop: "8px" }}
                          // onMouseOver={() => this.fetchData(subject.id)}
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            to={
                              "/course_landing" + "?streamId=" +
                              btoa(this.state.streamId) +
                              "&attemptId=" +
                              btoa(this.state.attemptId) +
                              "&subjectId=" +
                              btoa(subject.id) 
                              + "&language=" + 'Hindi' + 
                              "&batchId=" +
                              // btoa(this.state.batch_id) +
                              "&subject_filter_id=" 
                              // + btoa(this.state.subject_id)
                            }
                          >
                            <div className="cardMain">
                              <div
                                className={
                                  window.innerWidth === 1396
                                    ? "courseCard13961"
                                    : "courseCard1"
                                }
                                style={{
                                  backgroundColor: this.state.colorCodes.at(
                                    index % 4
                                  ),
                                }}
                              >
                                <h4 className="courseTitle">
                                  {" "}
                                  {subject.title}
                                </h4>
                                <div className="cardSecPart">
                                  <h5>Smart Classroom - live + Recorded</h5>
                                  <h5 className="doubtSolving">
                                    24 × 7 Doubt Solving Portal
                                  </h5>
                                  <h5 className="personalized">
                                    Personalized Course Completion Strategy
                                  </h5>
                                  <h5 className="printedNotes">
                                    Printed Notes
                                  </h5>
                                  <h5 className="liveQuiz">
                                    Live Quiz & Regular Test Series
                                  </h5>
                                  <button className="getDetails">
                                    Get Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div
                      className="regiBatches"
                      style={{
                        textAlign: "center",
                        marginTop: "5%",
                        marginLeft: "8%",
                        fontSize: "24px",
                        fontFamily: "raleway",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        height={200}
                        width={300}
                        isStopped={this.state.isStopped}
                        isPaused={this.state.isPaused}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </section>
          <section className="section4">
            <div
              className="container-fluid container-fluid-Hidden"
              style={{ marginTop: "-35px" }}
            >
              <div className="row combosArea">
                <div className="col-md-12" style={{ marginTop: "23px" }}>
                  <div className="col-md-3">
                    <h1 className="subCom">
                      Subject <br /> Combos
                    </h1>
                  </div>
                  <div className="col-md-3">
                    <h5 className="smartClass">
                      Smart Classroom - live + Recorded
                    </h5>
                    <h5 className="doubtSolvingCom">
                      24 × 7 Doubt Solving Portal
                    </h5>
                    <h5 className="personalizedCom">
                      Personalized Course Completion Strategy
                    </h5>
                  </div>
                  <div className="col-md-3">
                    <h5 className="smartClass">Printed Notes</h5>
                    <h5 className="doubtSolvingCom">
                      Live Quiz & Regular Test Series
                    </h5>
                    <h5 className="personalizedCom"></h5>
                  </div>
                  <div className="col-md-3">
                    {this.state.comboSub?.length > 0 ? (
                      this.state.comboSub?.map((item) => (
                        <button className="getDetailsCom">
                          <Link
                            to={
                              "/combo_subject_courses/" +
                              this.state.streamId +
                              "/" +
                              this.state.attemptId +
                              "/" +
                              item.id
                            }
                          >
                            Get Details
                          </Link>
                        </button>
                      ))
                    ) : (
                      <button className="getDetailsCom">
                        <Link
                          to={
                            "/combo_subject_courses/" +
                            this.state.streamId +
                            "/" +
                            this.state.attemptId
                          }
                        >
                          Get Details
                        </Link>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid divClass">
              <div className="row combosArea1">
                <div className="col-md-12 subTitle">
                  <div className="col-md-3">
                    {this.state.comboSub?.length > 0 ? (
                      this.state.comboSub?.map((item) => (
                        <h5 className="subCom">
                          <Link
                            to={
                              "/combo_subject_courses/" +
                              this.state.streamId +
                              "/" +
                              this.state.attemptId +
                              "/" +
                              item.id
                            }
                          >
                            Subject Combos
                          </Link>
                        </h5>
                      ))
                    ) : (
                      <h5 className="subCom">
                        <Link
                          to={
                            "/combo_subject_courses/" +
                            this.state.streamId +
                            "/" +
                            this.state.attemptId
                          }
                        >
                          Subject Combos
                        </Link>
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section5">
            <div className="container-fluid">
              <div className="row clickSubject">
                <h4 className="clickParagraph">
                  Click on any of the subjects above or Combo Package to view
                  all the options available with us for particular subject. You
                  need to select attempt so that we... Click on any of the
                  subjects above or Combo Package to view all the options
                  available with us for particular subject. You need to select
                  attempt so that we can
                </h4>
              </div>
            </div>
          </section>
          <section className="section6">
            <div className="container-fluid">
              <div className="row bottomText">
                <h1 className="marathiText">तैयारी आपकी...जिम्मेदारी हमारी</h1>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}
