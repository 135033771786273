import React, { Component } from "react";
import Lottie from "react-lottie";
import notificationLoader from "../images/notification_loader.json";

export default class CheckoutRegitrationNotification extends Component {
  render() {
    return (
      <div className="notificationWrapper">
        <Lottie
          options={{
            animationData: notificationLoader,
          }}
          style={{
            width: "30px",
            height: "30px",
            // marginRight: "16px",
          }}
        />
        <p>{this.props.message}</p>
      </div>
    );
  }
}
