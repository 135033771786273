import React, { Component } from "react";
import "../scss/FaceToFaceDetailFooter.scss";
import subject_bg from "../images/sub_back.png";
import akSir from "../images/akSir.png";
import axios from "axios";
import Constants from "./common/Constants";
import moment from "moment";
import pdf from "../images/pdf.png";
import { Link } from "react-router-dom";
import init from "./helper/WindowToken";
import FacetoFaceDetailResp from "./Screens/FacetoFaceDetailResp";
import Header from "./common/Header";
import Footer from "./common/Footer";
import checked_radio from "../images/checked_radio.png";
import unchecked_radio from "../images/unchecked_radio.png";
import notFound from "../images/77703-no-data-found.json";
import loader from "../images/97739-loader.json";
import Lottie from "react-lottie";
export default class FaceToFaceDetail extends Component {
  state = {
    answer: false,
    answer1: false,
    answer2: false,
    answer3: false,
    subjects: [],
    streamName: "",
    CatId: window.location.pathname.split("/")[2],
    LevelId: window.location.pathname.split("/")[3],
    streamId: window.location.pathname.split("/")[4],
    attempts: [],
    attempt: "",
    attemptId: "",
    attemptShow: false,
    attemptName: "",
    subjectId: "",
    userDetails: [],
    subjectName: "",
    LectureData: [],
    colorCodes: ["#2f65b4", "#df7504", "#279b14", "#6f1561"],
    f2f: true,
    liveS: false,
    isLoading: true,
  };
  componentDidMount() {
    this.getUserInfo();
    this.getsubject();

    axios.get(Constants.getUrls.getAttempts).then((res) => {
      console.log(res);
      this.setState(
        {
          attempts: res.data,
          attemptId: res.data[0].id,
          isLoading: false,
        },
        () => {
          this.getSingleAttempt(this.state.attemptId);
        }
      );
    });
  }

  getUserInfo = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.user).then((res) => {
        console.log(res.data);
        this.setState({ userDetails: res.data }, () => {
          console.log(this.state.userInfo);
        });
      });
    }
  };

  getSingleAttempt = (id) => {
    this.setState({ isLoading: true });
    axios.get(Constants.getUrls.attempts + "/" + id).then((resp) => {
      console.log(resp);
      if (resp.data.status === "suceess") {
        this.setState(
          {
            attemptName: resp.data.data.title,
          },
          () => {
            axios
              .get(
                Constants.getUrls.getFacetoFace +
                  "?category_id=" +
                  this.state.CatId +
                  "&level_id=" +
                  this.state.LevelId +
                  "&stream_id=" +
                  this.state.streamId +
                  "&subject_id=" +
                  this.state.subjectId
              )
              .then((resp) => {
                console.log(resp);
                this.setState(
                  {
                    LectureData: resp.data.data,
                    isLoading: false,
                    streamName: resp.data.data[0].stream.name,
                  },
                  () => {
                    console.log(this.state.LectureData, this.state.streamName);
                  }
                );
              });
          }
        );
      }
    });
  };

  getsubject = () => {
    this.setState({ isLoading: true });
    axios
      .get(
        Constants.getUrls.getSubjectsFacetoFace +
          "?stream_id=" +
          this.state.streamId
      )
      .then((resp) => {
        console.log(resp);
        this.setState(
          {
            subjects: resp.data.data,

            subjectId: resp.data.data[0].id,
            subjectName: resp.data.data[0].title,
          },
          () => {
            console.log(this.state.subjectId);
            axios
              .get(
                Constants.getUrls.getFacetoFace +
                  "?category_id=" +
                  this.state.CatId +
                  "&level_id=" +
                  this.state.LevelId +
                  "&stream_id=" +
                  this.state.streamId +
                  "&subject_id=" +
                  this.state.subjectId
              )
              .then((resp) => {
                console.log(resp);
                this.setState(
                  {
                    LectureData: resp.data.data,
                    streamName: resp.data.data[0].stream.name,
                    isLoading: false,
                  },
                  () => {
                    console.log(this.state.LectureData, this.state.streamName);
                  }
                );
              });
          }
        );
      });
  };
  // checkedBatch = (id) => {
  //   let wingsId = id;
  //   let istrue = false;
  //   if (this.state.array_batch_wings_id.length > 0) {
  //     let batch = this.state.array_batch_wings_id.find((v) => v.id === id);
  //     if (batch) {
  //       istrue = true;
  //     } else {
  //       istrue = false;
  //     }
  //   } else {
  //     istrue = false;
  //   }
  //   return istrue;
  // };
  subjectData = (id) => {
    this.setState(
      {
        subjectId: id,
        isLoading: true,
      },
      () => {
        axios
          .get(
            Constants.getUrls.getFacetoFace +
              "?category_id=" +
              this.state.CatId +
              "&level_id=" +
              this.state.LevelId +
              "&stream_id=" +
              this.state.streamId +
              "&subject_id=" +
              this.state.subjectId
          )
          .then((resp) => {
            console.log(resp);
            this.setState(
              {
                LectureData: resp.data.data,
                streamName: resp.data.data[0].stream.name,
                isLoading: false,
              },
              () => {
                console.log(this.state.LectureData, this.state.streamName);
              }
            );
          });
      }
    );
  };
  showAnswer = (e) => {
    this.setState({
      answer: !this.state.answer,
    });
  };
  showAnswer1 = (e) => {
    this.setState({
      answer1: !this.state.answer1,
    });
  };
  showAnswer2 = (e) => {
    this.setState({
      answer2: !this.state.answer2,
    });
  };
  showAnswer3 = (e) => {
    this.setState({
      answer3: !this.state.answer3,
    });
  };

  dateFormat = (cdate) => {
    let date = moment(cdate).format("Do MMM YYYY");
    return date;
  };
  handleFace = (e) => {
    this.setState({
      f2f: !this.state.f2f,
      liveS: !this.state.liveS,
    });
  };
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notFound,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        <Header />
        <div className="Responsive">
          <FacetoFaceDetailResp />
        </div>
        <div className="Web">
          <section className="section1" style={{ height: "80px" }}>
            <h1 className="fonts"> Face to Face | Live Stream</h1>
            <h5 className="fonts titleName">{this.state.streamName}</h5>
          </section>
          <section className="section2" style={{ textAlign: "center" }}>
            <span className="showing">
              Face to Face | Live Stream Batches for{" "}
              <span className="jun"> {this.state.attemptName} Attempt</span>{" "}
              <select
                name="attempt"
                id=""
                className="selectAtt"
                value={this.state.attemptId}
                onChange={(e) => {
                  this.setState(
                    {
                      attemptShow: true,
                      attemptId: e.target.value,
                    },
                    () => {
                      this.getSingleAttempt(this.state.attemptId);
                    }
                  );
                }}
              >
                <option value="">Change Attempt</option>
                {this.state.attempts.map((attempt) => (
                  <option value={attempt.id}>{attempt.title}</option>
                ))}
              </select>
            </span>
          </section>
          <section className="section3 container">
            <div className="row">
              <div className="col-sm-3">
                <div className="chooseSubjects" style={{ marginTop: "10px" }}>
                  <h3 className="typeName">Choose Subject</h3>
                  {this.state.subjects.map((course) => (
                    <div className="checkDiv1">
                      <button
                        className="batch_button"
                        name="subject"
                        onClick={(e) => this.subjectData(course.id)}
                        value={this.state.subjectId}
                      >
                        {this.state.subjectId === course.id ? (
                          <img
                            className="button_radio"
                            src={checked_radio}
                            alt=""
                          />
                        ) : (
                          <img
                            className="button_radio"
                            src={unchecked_radio}
                            alt=""
                          />
                        )}{" "}
                        <span>{course.title}</span>
                      </button>
                    </div>
                  ))}
                </div>
                <div className="lectureNote1">
                  <h5 className="lectures">
                    Request to conduct 3 Days <br />
                    Marathon Batch in your City
                  </h5>
                </div>
              </div>
              <div className="col-sm-9">
                {this.state.isLoading ? (
                  <Lottie
                    options={defaultLoader}
                    height={80}
                    width={80}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}
                  />
                ) : (
                  <section className="section4">
                    <div className="row">
                      {this.state.LectureData.length > 0 ? (
                        this.state.LectureData.map((batch) => (
                          <div className="col-md-4 margSec4">
                            <div className="row">
                              <div className="col-md-7">
                                <h5
                                  className="registration1"
                                  style={{ fontSize: "13px" }}
                                >
                                  Registration Ends On{" "}
                                  {batch?.batch_ended_date
                                    ? this.dateFormat(batch.batch_ended_date)
                                    : "NA"}
                                  {/* {this.dateFormat(
                                              batch.batch.registration_end_date
                    )} */}
                                </h5>
                              </div>
                              <div className="col-md-5">
                                <button
                                  className="FabatchButton"
                                  style={{ backgroundColor: batch?.color }}
                                >
                                  {batch?.city ? batch.city : "NA"}
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="courseDivArea">
                                <div className="fabatchBackColor">
                                  <div className="col-md-12">
                                    <img
                                      src={Constants.imgUrl + batch.image}
                                      alt=""
                                      className="akPic1"
                                      style={{
                                        height: "165px",
                                        width: "290px",
                                      }}
                                    />

                                    <div className="col-md-8 courseRightArea">
                                      <h4 className="subjTitle">
                                        {/* {this.state.streamName} */}
                                      </h4>
                                      <h4 className="coursenametitle">
                                        {/* {this.state.subjectName} */}
                                      </h4>
                                      <h4 className="subjTitle">
                                        {/* {batch.faculty_detail[0].faculty.name} */}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="daysAreaCourses">
                                      <div style={{ textAlign: "center" }}>
                                        <h5 className="subnaame">
                                          {this.state.subjectName
                                            ? this.state.subjectName
                                            : ""}
                                        </h5>
                                      </div>
                                      <div className="BatchStarts">
                                        Batch Starts on :{" "}
                                        {this.dateFormat(
                                          batch.batch_started_date
                                        )}{" "}
                                      </div>
                                      <div className="FaDays">{batch.day}</div>
                                      {/* <div className="faSpot">
                                      Spot Registration Fees will be{" "}
                                      {batch.fees}/-
                                    </div> */}
                                      <div className="faDivRadio">
                                        <form action="./">
                                          <div>
                                            <input
                                              type="radio"
                                              id="face2face"
                                              value="Face to Face"
                                              checked={this.state.f2f}
                                              onChange={this.handleFace}
                                            />{" "}
                                            Face to Face
                                          </div>
                                        </form>
                                        <div>
                                          <input
                                            type="radio"
                                            id="liveS"
                                            value="Live Stream"
                                            checked={this.state.liveS}
                                            onChange={this.handleFace}
                                          />{" "}
                                          Live Stream
                                        </div>
                                      </div>
                                      <div className="faCheck">
                                        <img
                                          src={pdf}
                                          alt="pdf"
                                          width={30}
                                          height={30}
                                        />
                                        Check Batch Curriculum
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="enrollAreaCourse">
                                      <div className="col-md-12">
                                        <div className="col-md-6">
                                          <h5
                                            className="faHexTime"
                                            style={{ color: batch?.color }}
                                          >
                                            {/* <span>&#9201;</span> */}
                                            {batch.fees}/-
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <Link
                                            to="/facetofacecheckout"
                                            state={{
                                              data: batch,
                                              subjectId: this.state.subjectId,
                                              userDetails:
                                                this.state.userDetails,
                                            }}
                                          >
                                            <button
                                              className="faEnrollBtnCourse"
                                              style={{
                                                backgroundColor: batch?.color,
                                              }}
                                            >
                                              Enroll Now
                                            </button>
                                          </Link>
                                        </div>
                                        <div className="col-md-3">
                                          {/* <h5 className="courseAmt">
                                {batch.course
                                ? batch.course.variant
                                  ? batch.course.variant[0]
                                    ? batch.course.variant[0].price
                                    : batch.course.PRICE
                                  : batch.course.PRICE
                                : batch.course.PRICE}
                              /-
                            </h5> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          className="col-md-4 regiBatches"
                          style={{
                            textAlign: "center",
                            marginTop: "11%",
                            marginLeft: "39%",
                          }}
                        >
                          <Lottie
                            options={defaultOptions}
                            height={200}
                            width={300}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className="row"
                      style={{
                        marginTop:
                          this.state.LectureData.length > 0 ? "55px" : "27%",
                      }}
                      onClick={this.showAnswer}
                    >
                      <div className="batchSystem">
                        <div className="row">
                          <div className="col-md-10 col-xs-10">
                            <h4 className="questions">
                              What is backup facility ?
                            </h4>
                          </div>
                          {this.state.answer ? (
                            <div className="col-md-2 arrowHexRight col-xs-2">
                              {/* <span
                                style={{ fontSize: "30px" }}
                                onClick={this.showAnswer}
                              >
                                &#129171;
                              </span> */}
                              <i
                                class="fa-solid fa-caret-down"
                                style={{ fontSize: "18px", marginTop: "10px" }}
                                onClick={this.showAnswer}
                              ></i>
                            </div>
                          ) : (
                            <div className="col-md-2 arrowHexRight col-xs-2">
                              <i
                                class="fa-solid fa-caret-right"
                                style={{ fontSize: "18px", marginTop: "10px" }}
                                onClick={this.showAnswer}
                              ></i>
                            </div>
                          )}
                        </div>
                        {this.state.answer ? (
                          <h5 className="answers">
                            We provide automatic lorem Ipsum is simply dummy
                            text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type
                            specimen book.
                          </h5>
                        ) : null}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{ marginTop: "20px" }}
                      onClick={this.showAnswer1}
                    >
                      <div className="batchSystem">
                        <div className="row">
                          <div className="col-md-10 col-xs-10">
                            <h4 className="questions">
                              What is difference between face to face and live
                              stream ?
                            </h4>
                          </div>
                          {this.state.answer1 ? (
                            <div className="col-md-2 arrowHexRight col-xs-2">
                              <i
                                class="fa-solid fa-caret-down"
                                style={{ fontSize: "18px", marginTop: "10px" }}
                                onClick={this.showAnswer1}
                              ></i>
                            </div>
                          ) : (
                            <div className="col-md-2 arrowHexRight col-xs-2">
                              <i
                                class="fa-solid fa-caret-right"
                                style={{ fontSize: "18px", marginTop: "10px" }}
                                onClick={this.showAnswer1}
                              ></i>
                            </div>
                          )}
                        </div>
                        {this.state.answer1 ? (
                          <h5 className="answers">
                            We provide automatic lorem Ipsum is simply dummy
                            text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type
                            specimen book.
                          </h5>
                        ) : null}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{ marginTop: "20px" }}
                      onClick={this.showAnswer2}
                    >
                      <div className="batchSystem">
                        <div className="row">
                          <div className="col-md-10 col-xs-10">
                            <h4 className="questions">
                              What is Face to Face Batch and Benefit of Face to
                              Face ?
                            </h4>
                          </div>
                          {this.state.answer2 ? (
                            <div className="col-md-2 arrowHexRight col-xs-2">
                              <i
                                class="fa-solid fa-caret-down"
                                style={{ fontSize: "18px", marginTop: "10px" }}
                                onClick={this.showAnswer2}
                              ></i>
                            </div>
                          ) : (
                            <div className="col-md-2 arrowHexRight col-xs-2">
                              <i
                                class="fa-solid fa-caret-right"
                                style={{ fontSize: "18px", marginTop: "10px" }}
                                onClick={this.showAnswer2}
                              ></i>
                            </div>
                          )}
                        </div>
                        {this.state.answer2 ? (
                          <h5 className="answers">
                            We provide automatic lorem Ipsum is simply dummy
                            text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type
                            specimen book.
                          </h5>
                        ) : null}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{ marginTop: "20px" }}
                      onClick={this.showAnswer3}
                    >
                      <div className="batchSystem">
                        <div className="row">
                          <div className="col-md-10 col-xs-10">
                            <h4 className="questions">
                              Where I can get more Details about this course ?
                            </h4>
                          </div>
                          {this.state.answer3 ? (
                            <div className="col-md-2 arrowHexRight col-xs-2">
                              <i
                                class="fa-solid fa-caret-down"
                                style={{ fontSize: "18px", marginTop: "10px" }}
                                onClick={this.showAnswer3}
                              ></i>
                            </div>
                          ) : (
                            <div className="col-md-2 arrowHexRight col-xs-2">
                              <i
                                class="fa-solid fa-caret-right"
                                style={{ fontSize: "18px", marginTop: "10px" }}
                                onClick={this.showAnswer3}
                              ></i>
                            </div>
                          )}
                        </div>
                        {this.state.answer3 ? (
                          <h5 className="answers">
                            We provide automatic lorem Ipsum is simply dummy
                            text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text
                            ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type
                            specimen book.
                          </h5>
                        ) : null}
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}
