import React, { Component } from "react";
import Axios from "axios";
import Lottie from "lottie-react-web";
import loader from "../../images/loader.json";
import Constants from "../common/Constants";
import Header from "../common/Header";

export default class ForgetPassword extends Component {
  state = {
    email: "",
    btnLoading: false,
    invalid: false,
    error: false,
    sentEmaiLink: false,
    old_pass: "",
    new_pass: "",
    confirm_pass: "",
    notiMessage: "",
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitEmail = (e) => {
    e.preventDefault();
    console.log(this.state.email);
    if (this.state.email !== "") {
      this.setState({ btnLoading: true });
      Axios.post(Constants.postUrls.forgetPassword, {
        email: this.state.email,
      }).then((res) => {
        console.log(res);
        this.setState({ btnLoading: false, sentEmaiLink: true });
      });
    } else {
      this.setState({ invalid: true });
    }
  };
  validateEmail = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      this.setState({
        email: text,
        showCorrectEmail: false,
        showEmailError: true,
      });
      return false;
    } else {
      this.setState({
        email: text,
        showCorrectEmail: true,
        showEmailError: false,
      });
      console.log("Email is Correct");
    }
  };
  render() {
    return (
      <div>
        <Header />
        <section
          className="pwd-page section-b-space"
          // style={{ width: "58%", margin: "65px auto" }}
        >
          {
            window.innerWidth < 768 ? 
            <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <h2 style={{ fontSize: "16px",position:"relative",left:"0px" }}>Change Your Password</h2>
                <form className="theme-form" onSubmit={this.submitEmail}>
                  {this.state.sentEmaiLink ? (
                    <div>
                      <h4>
                        We have sent you an email with password reset link.
                        Please click on the link given in the email
                      </h4>
                    </div>
                  ) : (
                    <div className="form-row">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={this.state.email}
                          placeholder="Email"
                          required=""
                          onChange={(e) => {
                            this.setState({ email: e.target.value }, () => {
                              this.validateEmail(this.state.email);
                            });
                          }}
                          style={{position:"relative",left:"0px"}}
                        />
                        {this.state.showEmailError &&
                        this.state.email.length > 0 ? (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Enter valid e-mail address
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        {this.state.invalid ? (
                          <p> * Please enter a correct Email.</p>
                        ) : null}
                      </div>

                      <button
                        href="/"
                        className="btn btn-solid"
                        disabled={this.state.email ? false : true}
                        style={{position:"relative",left:"1px", width:"300px"}}
                      >
                        {this.state.btnLoading ? (
                          <Lottie
                            options={{
                              animationData: loader,
                            }}
                            style={{
                              width: "40px",
                              height: "20px",
                            }}
                          />
                        ) : (
                          "Send Email Link"
                        )}
                      </button>
                    </div>
                  )}
                </form>
                {this.state.showError ? (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center  ",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    Password not Changed{" "}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          :
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <h2 style={{ fontSize: "22px",position:"relative",left:"100px" }}>Change Your Password</h2>
                <form className="theme-form" onSubmit={this.submitEmail}>
                  {this.state.sentEmaiLink ? (
                    <div>
                      <h4>
                        We have sent you an email with password reset link.
                        Please click on the link given in the email
                      </h4>
                    </div>
                  ) : (
                    <div className="form-row">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={this.state.email}
                          placeholder="Email"
                          required=""
                          onChange={(e) => {
                            this.setState({ email: e.target.value }, () => {
                              this.validateEmail(this.state.email);
                            });
                          }}
                          style={{position:"relative",left:"-12px"}}
                        />
                        {this.state.showEmailError &&
                        this.state.email.length > 0 ? (
                          <p style={{ color: "red", fontSize: 10 }}>
                            Enter valid e-mail address
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        {this.state.invalid ? (
                          <p> * Please enter a correct Email.</p>
                        ) : null}
                      </div>

                      <button
                        href="/"
                        className="btn btn-solid"
                        disabled={this.state.email ? false : true}
                        style={{position:"relative",left:"3px", width:"525px"}}
                      >
                        {this.state.btnLoading ? (
                          <Lottie
                            options={{
                              animationData: loader,
                            }}
                            style={{
                              width: "40px",
                              height: "20px",
                            }}
                          />
                        ) : (
                          "Send Email Link"
                        )}
                      </button>
                    </div>
                  )}
                </form>
                {this.state.showError ? (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center  ",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    Password not Changed{" "}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          }
        </section>
      </div>
    );
  }
}
