import React, { Component } from "react";

export default class OrderDetails extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    // console.log(this.props.location.state.data);
    // let data = this.props.location.state.data;
    console.log(this.props);
    // if (!this.props.location.via) {
    //   setTimeout(() => {
    //     this.setState({ redirect: true });
    //   }, 5000);
    // }
    // this.setState({
    //   data,
    // });
  }
  render() {
    return (
      //   <div></div>
      <div>
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className={
                    this.state.data.PAYMENT_STATUS == "Collected"
                      ? " success-text"
                      : "failed-text"
                  }
                >
                  <i
                    className={
                      this.state.data.PAYMENT_STATUS == "Collected"
                        ? "fa fa-check-circle"
                        : "fa fa-remove"
                    }
                    aria-hidden="true"
                  ></i>
                  <h2>
                    {this.state.data.PAYMENT_STATUS == "Collected"
                      ? "Thank You!"
                      : "Sorry!"}
                  </h2>
                  <p>
                    Payment Has Been{" "}
                    {this.state.data.PAYMENT_STATUS == "Collected"
                      ? "Recieved"
                      : "Failed"}
                  </p>
                  <p>
                    Transaction ID:
                    {this.state.data.TRANSACTION_ID}
                    {/* {payment.paymentID ? payment.paymentID : payment.id} */}
                  </p>
                  <p>
                    Order Date:
                    {new Date(this.state.data.ORDER_DATE)
                      .toDateString()
                      .slice(4, 15)}
                    {/* {payment.paymentID ? payment.paymentID : payment.id} */}
                  </p>
                  <p>
                    Product Key:
                    {this.state.data.PRODUCT_KEY
                      ? this.state.data.PRODUCT_KEY
                      : "NA"}
                    {/* {payment.paymentID ? payment.paymentID : payment.id} */}
                  </p>
                  <p>
                    Windows Google Drive Link:
                    {this.state.data.course ? (
                      this.state.data.course.WINDOW_GD_LINK ? (
                        <a
                          href={this.state.data.course.WINDOW_GD_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.state.data.course.WINDOW_GD_LINK}
                        </a>
                      ) : (
                        "NA"
                      )
                    ) : (
                      "NA"
                    )}
                  </p>
                  <p>
                    Android Google Drive Link:
                    {this.state.data.course ? (
                      this.state.data.course.ANDROID_GD_LINK ? (
                        <a
                          href={this.state.data.course.ANDROID_GD_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.state.data.course.ANDROID_GD_LINK}
                        </a>
                      ) : (
                        "NA"
                      )
                    ) : (
                      "NA"
                    )}
                  </p>
                  <p>
                    Common Google Drive Link:
                    {this.state.data.course ? (
                      this.state.data.course.COMMON_GD_LINK ? (
                        <a
                          href={this.state.data.course.COMMON_GD_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.state.data.course.COMMON_GD_LINK}
                        </a>
                      ) : (
                        "NA"
                      )
                    ) : (
                      "NA"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="product-order">
                  <h3>your order details</h3>
                  {/* {items.map((item, index) => { */}
                  {/* return ( */}
                  <div className="row product-order-detail">
                    <div className="col-sm-6 col-xs-6 order_detail">
                      <div>
                        <h4>product name</h4>
                      </div>
                    </div>
                    <div className="col-sm-3 col-xs-3 order_detail">
                      <div>
                        <h4>quantity</h4>
                      </div>
                    </div>
                    <div className="col-sm-3 col-xs-3 order_detail">
                      <div>
                        <h4>price</h4>
                      </div>
                    </div>
                  </div>
                  {/* {this.state.data.map(item => ( */}
                  <div className="row product-order-detail">
                    <div className="col-sm-6 col-xs-6 order_detail">
                      <div>
                        {/* <h5>{this.state.data.course.TITLE}</h5> */}
                        {/* {this.state.data.course.TYPE === "COURSES"
                          ? this.state.data.course.TITLE
                          : this.state.data.course.TITLE} */}
                        {this.state.data.course
                          ? this.state.data.course.TYPE === "COURSES"
                            ? this.state.data.course.TITLE
                            : this.state.data.course.TITLE
                          : null}
                      </div>
                    </div>
                    <div className="col-sm-3 col-xs-3 order_detail">
                      <div>
                        <h5>{this.state.data.QUANTITY}</h5>
                      </div>
                    </div>
                    <div className="col-sm-3 col-xs-3 order_detail">
                      <div>
                        <h5>₹ {Math.round(this.state.data.GRAND_TOTAL)}</h5>
                      </div>
                    </div>
                  </div>
                  {/* // ))} */}
                  <div className="total-sec">
                    <ul>
                      <li>
                        subtotal{" "}
                        <span>
                          {/* {symbol} */}
                          {/* {orderTotal} */}₹{" "}
                          {Math.round(this.state.data.GRAND_TOTAL)}
                        </span>
                      </li>
                      <li>
                        shipping{" "}
                        <span>₹{Math.round(this.state.data.SHIPPING)} </span>
                      </li>
                      <li>
                        tax(GST) <span>₹0</span>
                      </li>
                    </ul>
                  </div>
                  <div className="final-total">
                    <h3>
                      total
                      <span>
                        {/* {symbol} */}
                        {/* {orderTotal} */}₹{" "}
                        {Math.round(this.state.data.GRAND_TOTAL)}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row order-success-sec">
                  {/* <div className="col-sm-6">
                      <h4>summary</h4>
                      <ul className="order-detail">
                        <div>
                        </div>
                        <li>
                        </li>
                      </ul>
                    </div> */}
                  <div className="col-sm-12">
                    <h4>shipping address</h4>
                    <ul className="order-detail">
                      <li>{this.state.data.ADDRESS} </li>
                      <li>{this.state.data.ADDRESS_2} </li>
                      <li>
                        Contact No.
                        {this.state.data.CONTACT_NO}
                      </li>
                    </ul>
                  </div>

                  <div
                    className="col-sm-12 payment-mode"
                    style={{ marginTop: "16px" }}
                  >
                    <h4>payment method</h4>
                    <p>
                      Card/Net banking acceptance subject to device
                      availability.
                    </p>
                  </div>
                  {/* <div className="col-md-12">
                      <div className="delivery-sec">
                        <h3>expected date of delivery</h3>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
