import React, { Component } from "react";
import "../../scss/CourseDetailResp.scss";
import subject_bg from "../../images/subject_bg.jpg";

import ak_classes_book from "../../images/ak_classes_book.png";
import pdf from "../../images/pdf.png";
import facImg from "../../images/amansirimg.png";
import axios from "axios";
import Constants from "../common/Constants";

import { Link } from "react-router-dom";
import YoutubeEmbed from "../YoutubeEmbed";
import init from "../helper/WindowToken";
import renderHTML from "react-render-html";
import loader from "../../images/97739-loader.json";
import Lottie from "react-lottie";
export default class CourseDetailResp extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    answer: false,
    attemptType: window.location.pathname.split("/")[5],
    batch_id: window.location.pathname.split("/")[5],
    subject_id: window.location.pathname.split("/")[4],
    course_id: window.location.pathname.split("/")[6],
    batch_wings_id: window.location.pathname.split("/")[7],
    subjectDetails: "",
    courseDetails: "",
    variantDetails: [],
    showBolck: true,
    showDemo: true,
    variants: [],

    // for variant state
    oss: [],
    views: [],
    medias: [],
    osType: [],
    mediaType: [],
    viewType: [],
    variant: [],
    courseDetails: [],
    variant_id: "",
    offerPrice: "",
    base_offerPrice: "",
    offPrice: 0,
    isLoading: true,
    userDetails: [],
    about: "",
    data: {
      faculty_detail: {
        about_faculty: "",
      },
    },
  };
  componentDidMount() {
    // this.userData();
    console.log(this.props);
    this.getProductDetails();
    this.getUserInfo();
  }

  // userData = () =>{
  //   let token = localStorage.getItem("token");
  //   // console.log(init());
  //   // if (init() === "success") {
  //     console.log(token);
  //     axios.get(Constants.getUrls.user).then((resUser) => {
  //       console.log("user deatail", resUser);
  //       this.setState(
  //         {
  //           userDetails: resUser.data,
  //           login: true,
  //         },
  //         () => {
  //           console.log(this.state.userDetails);
  //         }
  //       );
  //     });
  //   // }
  // }
  getUserInfo = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.user).then((res) => {
        console.log(res.data);
        this.setState({ userDetails: res.data }, () => {
          console.log(this.state.userInfo);
        });
      });
    }
  };

  getProductDetails = () => {
    axios
      .get(
        Constants.getUrls.productDetails +
          "?batch_id=" +
          this.state.batch_id +
          "&subject_id=" +
          this.state.subject_id +
          "&course_id=" +
          this.state.course_id +
          "&batch_wings_id=" +
          this.state.batch_wings_id
      )
      .then((resp) => {
        // console.log(resp);
        this.setState(
          {
            offPrice: resp.data.data.course.VALUE,
            subjectDetails: resp.data.data,
            courseDetails: resp.data.data.course,
            variantDetails: resp.data.data.course.variant,
            isLoading: false,
          },
          () => {
            this.setState({
              about: this.state.courseDetails?.faculties?.about_us,
            });
            console.log(this.state.subjectDetails);
            console.log(this.state.courseDetails);
          }
        );
        this.getActiveVariants();
      })
      .catch((err) => {
        if (err) {
          console.log(err.respones);
        }
      });
  };

  handleCoupon = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  };
  verifyCoupon = () => {
    if (!this.state.couponApplied) {
      this.setState({
        couponFail: false,
        couponSuccess: false,
      });
      const payload = {
        coupon: this.state.coupon_code,
        product_id: this.state.course_id,
      };
      console.log(payload);
      axios.post(Constants.postUrls.coupon, payload).then((res) => {
        console.log(res);
        if (res.data.status === "fail") {
          this.setState({
            couponFail: true,
            couponSuccess: false,
            coupon_msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({
              openCoupon: false,
              coupon_code: "",
              couponSuccess: false,
              couponFail: false,
            });
          }, 1500);
        } else {
          const couponAmount = parseInt(res.data.coupon_detail.DISCOUNT_AMOUNT);
          const discountedPrice = this.state.offPrice
            ? this.state.offPrice - couponAmount
            : couponAmount;
          const mainPrice = this.state.mainPrice - couponAmount;
          this.setState({
            couponFail: false,
            couponSuccess: true,
            coupon: couponAmount,
            coupon_msg: "Wohho! Coupon Applied! ₹ " + couponAmount + " off.",
            offPrice: discountedPrice,
            mainPrice: mainPrice,
            couponApplied: true,
          });
          setTimeout(() => {
            // this.setState({
            //   openCoupon: false,
            //   coupon_code: "",
            //   couponSuccess: false,
            //   couponFail: false,
            // });
          }, 1500);
        }
      });
    } else {
      this.setState({
        couponFail: true,
        couponSuccess: false,
        coupon_msg: "Coupon already applied",
      });
      setTimeout(() => {
        this.setState({ openCoupon: false });
      }, 1500);
    }
  };

  getActiveVariants = () => {
    axios
      .get(Constants.getUrls.getCoursesActiveVarient + this.state.course_id)
      .then((respVarient) => {
        console.log("variants", respVarient);
        this.setState({
          variants: respVarient.data.product.variant,
        });

        let VarArray = respVarient.data.product.variant.filter(
          (variant) =>
            variant.status === "active" || variant.status === "Active"
        );
        console.log(VarArray);
        if (respVarient.data.product.TYPE === "COURSES") {
          let oss = [];
          let views = [];
          let medias = [];
          if (VarArray ? VarArray?.length > 0 : "") {
            let osidOfZero = VarArray[0].os_type_id;
            VarArray.forEach((item) => {
              if (oss ? oss?.length > 0 : "") {
                const findOS = oss.find((os) => os.id === item.os_type_id);

                if (!findOS) {
                  oss.push(item.os_type);
                }
              } else {
                oss.push(item.os_type);
              }
              if (item.os_type_id === osidOfZero) {
                if (medias ? medias?.length > 0 : "") {
                  const findMedia = medias.find(
                    (media) => media.id === item.media_type_id
                  );
                  if (!findMedia) {
                    medias.push(item.media_type);
                  }
                } else {
                  medias.push(item.media_type);
                }
              }
              // console.log("medias", medias);
              if (medias ? medias?.length > 0 : "") {
                if (
                  item.os_type_id === osidOfZero &&
                  medias[0].id === item.media_type_id
                ) {
                  if (views ? views?.length > 0 : "") {
                    const findViews = views.find(
                      (view) => view.id === item.view_and_validity_id
                    );
                    if (!findViews) {
                      views.push(item.view_and_validity);
                    }
                  } else {
                    views.push(item.view_and_validity);
                  }
                }
              }
            });

            this.setState(
              {
                oss,
                views,
                medias,
                osType: osidOfZero,
                mediaType: respVarient.data.product.variant[0].media_type_id,
                viewType:
                  respVarient.data.product.variant[0].view_and_validity_id,
                variant: respVarient.data.product.variant,
                variant_id: respVarient.data.product.variant[0].id,
                price: respVarient.data.product.variant[0].price,
                // offerPrice: respVarient.data.product.variant[0].price,
                totalAmt:
                  respVarient.data.product.variant[0].price -
                  respVarient.data.product.VALUE,
                base_offerPriceVariant:
                  respVarient.data.product.variant[0].price,
              },
              () => {
                console.log("price", this.state.price);
                this.setState(
                  {
                    mainPrice: this.state.price - this.state.offPrice,
                  },
                  () => {
                    console.log("main", this.state.mainPrice);
                  }
                );
              }
            );
          }
        }
      });
  };

  handleOS = (e) => {
    // console.log(e);
    this.setState(
      {
        osType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        console.log(this.state.osType);

        // let oss = [];
        let views = [];
        let medias = [];
        if (this.state.variant ? this.state.variant?.length > 0 : "") {
          this.state.variant.forEach((item) => {
            console.log(item.os_type_id, this.state.osType);
            if (item.os_type_id === parseInt(this.state.osType)) {
              if (medias ? medias?.length > 0 : "") {
                const findMedia = medias.find(
                  (media) => media.id === item.media_type_id
                );
                if (!findMedia) {
                  medias.push(item.media_type);
                }
              } else {
                medias.push(item.media_type);
              }
            }
            // console.log(medias);
            if (medias ? medias?.length > 0 : "") {
              if (
                item.os_type_id === parseInt(this.state.osType) &&
                item.media_type_id === medias[0].id
              ) {
                if (views ? views?.length > 0 : "") {
                  const findViews = views.find(
                    (view) => view.id === item.view_and_validity_id
                  );
                  if (!findViews) {
                    views.push(item.view_and_validity);
                  }
                } else {
                  views.push(item.view_and_validity);
                }
              }
            }
          });
          console.log("views", views);
          console.log("medias", medias);
          const selectedArray = this.state.variant.filter(
            (item) => item.os_type_id === parseInt(this.state.osType)
          );
          this.setState(
            {
              // oss,
              views,
              medias,
              // osType: osidOfZero,
              mediaType: selectedArray[0].media_type_id,
              viewType: selectedArray[0].view_and_validity_id,
              variant_id: selectedArray[0].id,
              price: selectedArray[0].price,
              offerPrice: selectedArray[0].offer_price,
              base_offerPrice: selectedArray[0].offer_price,
            },
            () => {
              console.log(this.state.price, this.state.offPrice);
              this.setState(
                {
                  mainPrice: this.state.price - this.state.offPrice,
                },
                () => {
                  console.log(this.state.mainPrice);
                }
              );
            }
          );
        }
      }
    );
  };

  handleMedia = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        mediaType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        let views = [];
        if (this.state.variant ? this.state.variant?.length > 0 : "") {
          this.state.variant.forEach((item) => {
            // console.log(item.os_type_id, this.state.osType);
            if (
              item.os_type_id === parseInt(this.state.osType) &&
              item.media_type_id === parseInt(this.state.mediaType)
            ) {
              if (views ? views?.length > 0 : "") {
                const findViews = views.find(
                  (view) => view.id === item.view_and_validity_id
                );
                if (!findViews) {
                  views.push(item.view_and_validity);
                }
              } else {
                views.push(item.view_and_validity);
              }
            }
          });
          console.log("views", views);
          const selectedArray = this.state.variant.filter(
            (item) =>
              item.os_type_id === parseInt(this.state.osType) &&
              item.media_type_id === parseInt(this.state.mediaType)
          );
          this.setState(
            {
              // oss,
              views,
              // medias,
              // osType: osidOfZero,
              // mediaType: selectedArray[0].media_type_id,
              viewType: selectedArray[0].view_and_validity_id,
              variant_id: selectedArray[0].id,
              price: selectedArray[0].price,
              offerPrice: selectedArray[0].offer_price,
              base_offerPrice: selectedArray[0].offer_price,
            },
            () => {
              console.log(this.state.price, this.state.offPrice);
              this.setState(
                {
                  mainPrice: this.state.price - this.state.offPrice,
                },
                () => {
                  console.log(this.state.mainPrice);
                }
              );
            }
          );
        }
      }
    );
  };

  handleViews = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        viewType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        const selectedObject = this.state.variant.find(
          (item) =>
            item.os_type_id === parseInt(this.state.osType) &&
            item.media_type_id === parseInt(this.state.mediaType) &&
            item.view_and_validity_id === parseInt(this.state.viewType)
        );
        console.log(selectedObject);
        this.setState(
          {
            variant_id: selectedObject.id,
            price: selectedObject.price,
            offerPrice: selectedObject.offer_price,
            base_offerPrice: selectedObject.offer_price,
          },
          () => {
            console.log(this.state.price, this.state.offPrice);
            this.setState(
              {
                mainPrice: this.state.price - this.state.offPrice,
              },
              () => {
                console.log(this.state.mainPrice);
              }
            );
          }
        );
      }
    );
  };

  showAnswer = (e) => {
    this.setState({
      answer: !this.state.answer,
    });
  };
  download(id, filepath) {
    axios
      .get(
        "https://admin.akclasses.in/api/download_question_pdf/" +
          80 +
          "?file=" +
          filepath
      )
      .then((res) => {
        console.log(res);
      });
  }

  render() {
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        {this.state.isLoading ? (
          <div style={{ marginTop: 100 }}>
            <Lottie
              options={defaultLoader}
              height={80}
              width={80}
              isStopped={this.state.isStopped}
              isPaused={this.state.isPaused}
            />
          </div>
        ) : (
          <>
            <section className="section1" style={{ height: "124px" }}>
              <h1 className="fonts1">Course Details</h1>

              <h5 className="titleName1">{this.state.courseDetails?.TITLE}</h5>
            </section>
            <div className="container contain2">
              <div className="row">
                <div className="col-sm-8">
                  <section className="section2" style={{ marginTop: "-130px" }}>
                    <div className=" attemptCourseDetail3">
                      {this.state.showDemo ? (
                        <YoutubeEmbed
                          embedId={this.state.courseDetails?.DEMO_LINK}
                          width={"596px"}
                          height={"318.26px"}
                        />
                      ) : (
                        <YoutubeEmbed
                          embedId={this.state.courseDetails?.DEMO_LINK1}
                          width={"596px"}
                          height={"318.26px"}
                        />
                      )}
                      {/* <img
                          src={
                            Constants.imgUrl +
                            "/" +
                            this.state.subjectDetails?.course?.THUMB_IMAGE_PATH
                          }
                          alt=""
                          width={"279.02px"}
                          height={"148.99px"}
                          style={{
                            position: "relative",
                            left: "25px",
                            top: "23px"
                          }}
                        /> */}
                      {/* <div className="section3">
                          <div className="col-sm-4 rightSide"> */}
                      <div className="variants">
                        <div className="qnti">
                          {this.state.courseDetails?.TITLE}
                        </div>
                        <div className="batchDet">
                          <span className="paramName">
                            {this.state.subjectDetails?.batch?.batch_name}
                          </span>{" "}
                          for attempt:{" "}
                          <span className="attem">
                            {this.state.subjectDetails?.batch?.attempt?.title}
                          </span>
                        </div>
                        <div className="totlP">Rs.{this.state.mainPrice}</div>
                        <div className="inclusive">
                          *Prices Inclusive of all taxes
                        </div>
                        <div className="borderPrices"></div>
                        <div className="oprSystem">
                          <h4>Operating Systems</h4>
                          <div className="col-md-12 varAlign">
                            {this.state.oss.map((os, index) => (
                              <>
                                <div className="col-md-6 col-xs-6">
                                  <span
                                    className="varName"
                                    style={{ marginRight: 0 }}
                                  >
                                    <input
                                      type="radio"
                                      value={os.id}
                                      checked={
                                        os.id === this.state.osType
                                          ? true
                                          : false
                                      }
                                      onClick={() => this.handleOS(os.id)}
                                    />{" "}
                                    {os.os_type}
                                  </span>
                                </div>
                              </>
                            ))}
                            <div className="oprBorder"></div>
                          </div>
                        </div>

                        <div className="oprSystem2">
                          <h4>Media</h4>
                          <div className="col-md-12 varAlign">
                            {this.state.medias.map((media, index) => (
                              <>
                                <div className="col-md-8 col-xs-8">
                                  <span
                                    className="varName"
                                    style={{ marginRight: 0 }}
                                  >
                                    <input
                                      type="radio"
                                      value={media.id}
                                      checked={
                                        media.id === this.state.mediaType
                                          ? true
                                          : false
                                      }
                                      onClick={() => this.handleMedia(media.id)}
                                    />{" "}
                                    {media.media_type}
                                  </span>
                                </div>
                              </>
                            ))}
                            <div className="oprBorder"></div>
                          </div>
                        </div>

                        <div className="col-md-12 oprSystem3">
                          <div className="col-md-12 col-xs-12 varAlign">
                            <h4>Views</h4>
                            <div>
                              {this.state.views.map((view, index) => {
                                const [viewsPart, validityPart] =
                                  view.view_and_validity.split(" Views ");
                                return (
                                  <span
                                    className="varName"
                                    style={{ marginRight: 0 }}
                                  >
                                    <input
                                      type="radio"
                                      value={view.id}
                                      checked={
                                        view.id === this.state.viewType
                                          ? true
                                          : false
                                      }
                                      onClick={() => this.handleViews(view.id)}
                                    />{" "}
                                    {viewsPart + " Views"}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                          <div className="col-md-12 col-xs-12 oprSystem4 varAlign">
                            <h4>Validity</h4>
                            <div>
                              {this.state.views.map((view, index) => {
                                const [viewsPart, validityPart] =
                                  view.view_and_validity.split(" Views ");

                                return (
                                  <span
                                    className="varName"
                                    style={{ marginRight: 0 }}
                                  >
                                    <input
                                      type="radio"
                                      value={view.id}
                                      checked={
                                        view.id === this.state.viewType
                                          ? true
                                          : false
                                      }
                                      onChange={() => this.handleViews(view.id)}
                                    />{" "}
                                    {validityPart}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                          <div className="oprBorder"></div>
                        </div>

                        <div className="col-md-12 col-xs-12 applyBtnArea">
                          <div className="col-md-6 col-xs-6">
                            <span
                              className="applyCoupon"
                              onClick={() => {
                                this.setState({ openCoupon: true });
                              }}
                            >
                              Apply Coupon
                            </span>
                          </div>
                          <div className="col-md-6 col-xs-6">
                            {this.state.couponSuccess ? (
                              <span className="couponSuccessMessageResp">
                                {this.state.coupon_msg}
                              </span>
                            ) : null}
                            {this.state.couponFail ? (
                              <span className="couponFailMessageResp">
                                {this.state.coupon_msg}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {this.state.openCoupon && (
                          <>
                            <div className={"couponWrapperResp"}>
                              <input
                                name="coupon_code"
                                onChange={this.handleCoupon}
                                value={this.state.coupon_code}
                                placeholder="Enter code here.."
                                style={{
                                  padding: "6px 10px",
                                  width: "62%",
                                }}
                              />
                              <button
                                onClick={this.verifyCoupon}
                                className="verifyBtn"
                              >
                                Verify
                              </button>
                            </div>
                          </>
                        )}
                        {this.state.courseDetails.stock_status === 0 ? (
                          <div
                            className="col-md-12 col-xs-12"
                            style={{ position: "relative", top: "45px" }}
                          >
                            <Link
                              to="/checkout"
                              state={{
                                coupon: this.state.coupon_code
                                  ? this.state.coupon_code
                                  : "",
                                batchId: this.state.batch_id,
                                batchWingId: this.state.batch_wings_id,
                                base_total: this.state.totalAmt,
                                coupon_amount: this.state.coupon,
                                grand_total: this.state.coupon_code
                                  ? this.state.totalAmt - this.state.coupon
                                  : this.state.totalAmt,
                                sku: this.state.courseDetails?.SKU,

                                product_details: {
                                  TITLE: this.state.courseDetails?.TITLE,
                                  product_id: this.state.course_id,
                                  quantity: 1,
                                  type: "COURSE",
                                  variant_id: this.state.variant_id,
                                  os_type_id: this.state.osType,
                                  media_type_id: this.state.mediaType,
                                  attempt: this.state.attemptType,
                                  view_and_validity_id: this.state.mediaType,
                                },
                                product: this.state.data,
                                userDetails: this.state.userDetails,
                                courseDetails: this.state.courseDetails,
                              }}
                              onClick={() =>
                                this.setState(
                                  {
                                    base_total: this.state.offerPrice,
                                  },
                                  () => {
                                    console.log(this.state.base_total);
                                  }
                                )
                              }
                            >
                              <button className="buyCourseBtn">
                                Buy This Course
                              </button>
                            </Link>
                          </div>
                        ) : (
                          <>
                            <span
                              style={{
                                fontSize: "22px",
                                position: "absolute",
                                zIndex: "999",
                                top: "563px",
                                left: "36%",
                                color: "#000",
                                fontWeight: "bold",
                              }}
                            >
                              Out of Stock
                            </span>
                            <div
                              className="buyBtnArea"
                              style={{
                                color: "white",
                                textAlign: "center",
                                fontSize: "18px",
                                filter: "blur(1.5px)",
                                pointerEvents: "none",
                              }}
                            >
                              <Link
                                to="/checkout"
                                state={{
                                  coupon: this.state.coupon_code
                                    ? this.state.coupon_code
                                    : "",
                                  batchId: this.state.batch_id,
                                  batchWingId: this.state.batch_wings_id,
                                  base_total: this.state.totalAmt,
                                  coupon_amount: this.state.coupon,
                                  grand_total: this.state.coupon_code
                                    ? this.state.totalAmt - this.state.coupon
                                    : this.state.totalAmt,
                                  sku: this.state.courseDetails?.SKU,

                                  product_details: {
                                    TITLE: this.state.courseDetails?.TITLE,
                                    product_id: this.state.course_id,
                                    quantity: 1,
                                    type: "COURSE",
                                    variant_id: this.state.variant_id,
                                    os_type_id: this.state.osType,
                                    media_type_id: this.state.mediaType,
                                    attempt: this.state.attemptType,
                                    view_and_validity_id: this.state.mediaType,
                                  },
                                  product: this.state.data,
                                  userDetails: this.state.userDetails,
                                }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      base_total: this.state.offerPrice,
                                    },
                                    () => {
                                      console.log(this.state.base_total);
                                    }
                                  )
                                }
                              >
                                <button className="buyCourseBtn">
                                  Buy This Course
                                </button>
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </section>
                  <div className=" attemptCourseDetail4">
                    <div className="aboutCourse">About The Course</div>{" "}
                    <p
                      className="courseP1"
                      dangerouslySetInnerHTML={{
                        __html: this.state.courseDetails.SHORT_DESCRIPTION
                          ? this.state.courseDetails.SHORT_DESCRIPTION
                          : "No data available",
                      }}
                    ></p>
                    {/* <p className="courseP1">The CA foundation course is a basic course designed for students who wish to pursue Chartered Accountancy (CA). It is conducted by Institute of Chartered Accountants of India (ICAI). The course consists of four subjects – Principles and Practices of Accounting, Mercantile Law, General Economics and Quantitative Aptitude. The course is essential for anyone who wishes to become a qualified chartered accountant. Principles and Practices of Accounting, Mercantile Law, General Economics and Quantitative Aptitude. </p> */}
                    {/* <ul className="activate">
                      <li className="font">
                        Students can activate course instantly after purchase by
                        login to their dashboard/profile on AKClasses Website.{" "}
                        <span className="activationProcess">
                          Click here to know about Activation Process.
                        </span>{" "}
                      </li>
                      <li className="font">
                        Books will be delivered within 5 to 6 days after order
                        completion.
                      </li>
                    </ul> */}
                    <div className="borderBottom"></div>
                    <div className="col-md-12 pdfArea">
                      <div className="col-md-8 topicWise">
                        Topic wise complete duration sheet available so that
                        students can plan their studies at best
                      </div>
                      <a
                        href={
                          "https://admin.akclasses.in/api/download_question_pdf/ " +
                          this.state.course_id +
                          "?file=" +
                          this.state.courseDetails.SYLLABUS_PDF
                        }
                        style={{ textDecoration: "none" }}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={() => {
                          this.download(
                            this.state.course_id,
                            this.state.courseDetails.SYLLABUS_PDF
                          );
                        }}
                      >
                        <div className="col-md-4 greenBack">
                          <img src={pdf} alt="" className="pdfImg" />
                          <span className="donloadpdf">Download PDF</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="systemResp">
                    <p className="sysText">System Requirements</p>
                    <ul className="activate2">
                      <li className="font2">
                        Windows Laptop - Minimum Configuration - Windows
                        Operating System 10 or above, Minimum intel 3 processor.{" "}
                      </li>
                      <li className="font2">
                        Android with version 9 and above
                      </li>
                    </ul>
                  </div>
                  <div className="regardingFacResp">
                    <p className="facText">About The Faculty</p>
                    <div className="facBorder"></div>
                    <div className="col-md-12 col-xs-12 facDetails">
                      <div className="col-md-2 col-xs-2">
                        <img
                          src={
                            Constants.imgUrl +
                            this.state.subjectDetails?.course?.faculties?.image
                          }
                          alt=""
                          style={{ width: "65px", height: "65px" }}
                        />
                      </div>
                      <div className="col-md-6 col-xs-6 nameArea">
                        <div className="facname">
                          {this.state.subjectDetails?.course?.faculties?.name}
                        </div>
                        <div className="facProfession">
                          {
                            this.state.subjectDetails?.course?.faculties
                              ?.about_us
                          }
                        </div>
                      </div>
                      <div className="col-md-4 col-xs-4">
                        <div className="knowMore">Know More</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
