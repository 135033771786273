import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../scss/CourseLanding.scss";
import Constants from "../common/Constants";
import notFound from "../../images/77703-no-data-found.json";
import Lottie from "react-lottie";
import loader from "../../images/97739-loader.json";
import renderHTML from "react-render-html";

let currentUrlParams = new URL(window.location);
export default class CourseLandingResp extends Component {
  state = {
    answer: false,
    answer1: false,
    answer2: false,
    answer3: false,
    streamName: "",
    subjectName: "",
    streamId: "",
    attemptId: "",
    subjectId: "",
    language: "",
    batchId: "",
    subject_filter_id: "",
    attempts: [],
    batchWings: [],
    courses: [],
    price: "",
    courseId: "",
    sameDate: "",
    getUpcomingDays: [],
    batchWingId: "",
    ischeck: false,
    array_batch_wings_id: [],
    subjectFilter: [],
    subject_filter_clicked: false,
    getLastDays: [],
    array_subject_filter_ids: [],
    isLoading: true,
  };

  showAnswer = (e) => {
    this.setState({
      answer: !this.state.answer,
    });
  };
  showAnswer1 = (e) => {
    this.setState({
      answer1: !this.state.answer1,
    });
  };
  showAnswer2 = (e) => {
    this.setState({
      answer2: !this.state.answer2,
    });
  };
  showAnswer3 = (e) => {
    this.setState({
      answer3: !this.state.answer3,
    });
  };
  LightenDarkenColor(col, amt) {
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
  hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;
    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;
    return s;
  }
  color(r, g, b) {
    return "#" + this.hex2(r) + this.hex2(g) + this.hex2(b);
  }
  shade(col, light) {
    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);
    if (light < 0) {
      r = (1 + light) * r;
      g = (1 + light) * g;
      b = (1 + light) * b;
    } else {
      r = (1 - light) * r + light * 255;
      g = (1 - light) * g + light * 255;
      b = (1 - light) * b + light * 255;
    }
    return this.color(r, g, b);
  }

  componentDidMount() {
    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);

    // Set a new parameter or update an existing one
    // params.set('subject_filter_id', '5654665465');
    // Get the current URL
    var urls = window.location.href;

    // Decode the URL
    // var decodedURL = decodeURIComponent(urls);

    // // Print the decoded URL

    // // Create a new URL with the updated parameters
    // // var newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + params.toString();
    // var newUrl = decodedURL;

    // Modify the URL without reloading the page
    // window.history.pushState({ path: newUrl }, '', newUrl);

    //Get Params
    var streamIdParams = params.get("streamId");
    var attemptIdParams = params.get("attemptId");
    var subjectIdParams = params.get("subjectId");
    var languageParams = params.get("language");
    var batchIdParams = params.get("batchId");
    var subject_filter_idParams = params.get("subject_filter_id");

    var streamId = atob(streamIdParams);
    var attemptId = atob(attemptIdParams);
    var subjectId = atob(subjectIdParams);
    var language = languageParams;
    var batchId = atob(batchIdParams);
    var subject_filter_id = atob(subject_filter_idParams);

    this.setState(
      {
        streamId: streamId,
        attemptId: attemptId,
        subjectId: subjectId,
        language: language,
        batchId: batchId,
        subject_filter_id: subject_filter_id,
      },
      () => {
        this.fetchData();
      }
    );
  }

  async fetchData() {
    try {
      const resp = await axios.get(
        Constants.getUrls.courseLandingAllData +
          "?subjectMasterId=" +
          this.state.subjectId +
          "&subjectWingId=" +
          this.state.subjectId +
          "&subjectId=" +
          this.state.subjectId
      );
      if (currentUrlParams.searchParams.get("language") !== null) {
        this.setState({
          language: currentUrlParams.searchParams.get("language"),
        });
      }

      let subject_array = resp.data.filter;
      if (this.state.batchId === "null" || this.state.batchId == "") {
        this.setState(
          {
            batchId: resp.data.wings[0].batch_wings_id,
            subject_filter_id: subject_array.reverse()[0].id,
          },
          () => {}
        );
      }
      // let filtWings = [];
      // resp.data.wings.map((item) => {
      //   if (item.batch_wing.attempt_id == this.state.attemptId) {
      //     filtWings.push(item);
      //   }
      // });
      let filtWings = [];
      resp.data.wings.map((bwings) => {
        console.log(bwings.batch_wing.attempt_id);
        console.log(this.state.attemptId);
        if (bwings.batch_wing.attempt_id.includes(this.state.attemptId)) {
          filtWings.push(bwings);
        }
      });
      console.log(filtWings);

      this.setState(
        {
          subjectName: resp.data.subject_master.title,
          attempts: resp.data.attempts.data,
          // batchWings: resp.data.wings,
          batchWings: filtWings,
          // batchId: this.state.batchId,
          // batchId: resp.data.wings[0].batch_wings_id,
          subjectFilter: subject_array.reverse(),
        },
        async () => {
          var params = new URLSearchParams(window.location.search);
          var encryptedbatchId = btoa(this.state.batchId);
          params.set("batchId", encryptedbatchId);
          var urls = window.location.href;
          var newUrl = decodeURIComponent(
            window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              params.toString()
          );
          window.history.pushState({ path: newUrl }, "", newUrl);

          await new Promise((resolve) => setTimeout(resolve, 5000)); // Introduce a delay of 5 seconds

          //attempt
          this.getSingleAttempt(this.state.attemptId);

          //subject filters
          // this.setState(
          //   {
          // subject_filter_id: this.state.subject_filter_id,
          // },
          // () => {
          var params = new URLSearchParams(window.location.search);
          var encryptedsubject_filter_id = btoa(this.state.subject_filter_id);
          params.set("subject_filter_id", encryptedsubject_filter_id);
          var urls = window.location.href;
          var newUrl = decodeURIComponent(
            window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              params.toString()
          );
          window.history.pushState({ path: newUrl }, "", newUrl);
          this.state.array_subject_filter_ids.push({
            id: this.state.subjectFilter[0].id,
          });
          this.setState({
            array_subject_filter_ids: this.state.array_subject_filter_ids,
          });
          //end

          //Courses all
          this.state.array_batch_wings_id.push({ id: this.state.batchId });
          this.setState({
            array_batch_wings: this.state.array_batch_wings_id,
            batchId: atob(encryptedbatchId),
            subject_filter_id: atob(encryptedsubject_filter_id),
          });
          //   }
          // );

          await new Promise((resolve) => setTimeout(resolve, 5000)); // Introduce another delay of 5 seconds

          const respAll = await axios
            .get(
              Constants.getUrls.coursesAll +
                this.state.subjectId +
                "?batch_wings_id=" +
                this.state.batchId +
                "&language=" +
                this.state.language +
                "&attempt=" +
                this.state.attemptId +
                "&subject_filter_id=" +
                this.state.subject_filter_id
            )
            .then((respAll) => {
              let allCourses = [];
              respAll.data.data.wingsCourses.forEach((item) => {
                if (
                  (item.course.project_course_status === "both" ||
                    item.course.project_course_status === "ak1") &&
                  item.course.IS_DELETED === 0
                ) {
                  allCourses.push(item);
                }
              });
              let activeCourses = [];
              allCourses.forEach((item) => {
                if (item.course.STATUS === "ACTIVE") {
                  activeCourses.push(item);
                }
              });
              this.setState(
                {
                  courses: activeCourses,
                  loadingWing: false,
                  price: respAll.data
                    ? respAll.data.course
                      ? respAll.data.course.variant
                        ? respAll.data.course.variant[0]
                          ? respAll.data.course.variant[0].price
                          : null
                        : null
                      : null
                    : null,
                  courseId: respAll.data
                    ? respAll.data._id
                      ? respAll.data._id
                      : 0
                    : 0,
                  isLoading: false,
                },
                () => {
                  const GetDays = (d, Mention_today = false) => {
                    var DateArray = [];
                    var days = d;
                    for (var i = 0; i < days; i++) {
                      if (!Mention_today && i == 0) {
                        i = 1;
                        days += 1;
                      }
                      var date = new Date();
                      var last = new Date(
                        date.getTime() + i * 24 * 60 * 60 * 1000
                      );
                      var day = last.getDate().toString();
                      var month = (last.getMonth() + 1).toString();
                      var year = last.getFullYear();
                      var monthSplit = month.split("");
                      var daySplit = day.split("");
                      const fulld =
                        Number(year) +
                        "-" +
                        (monthSplit[1] ? Number(month) : "0" + monthSplit[0]) +
                        "-" +
                        (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                      DateArray.push(fulld);
                    }
                    return DateArray;
                  };
                  const currentDate = new Date();
                  const currentDateTime = currentDate.getTime();
                  const last30DaysDate = new Date(
                    currentDate.setDate(currentDate.getDate() - 30)
                  );
                  const last30DaysDateTime = last30DaysDate.getTime();
                  const last30DaysList = this.state.courses.filter((x) => {
                    const elementDateTime = new Date(
                      x.batch.registration_end_date
                    ).getTime();
                    if (
                      elementDateTime <= currentDateTime &&
                      elementDateTime > last30DaysDateTime
                    ) {
                      return true;
                    }
                    return false;
                  });
                  this.setState(
                    {
                      getUpcomingDays: GetDays(100),
                      getLastDays: last30DaysList,
                    },
                    () => {}
                  );
                  let arr1 = this.state.courses;
                  let arr2 = this.state.getUpcomingDays;
                  let arr3 =
                    this.state.getUpcomingDays !== undefined &&
                    arr1.filter((arr1Item) =>
                      arr2.includes(
                        arr1Item
                          ? arr1Item.batch
                            ? arr1Item.batch.registration_start_date
                              ? arr1Item.batch.registration_start_date
                              : ""
                            : ""
                          : ""
                      )
                    );
                  this.setState({
                    sameDate: arr3,
                    same: true,
                  });
                }
              );
            })
            .catch((error) => {
              if (error.response && error.response.status === 429) {
                // Handle rate limit exceeded error
              } else {
                // Handle other errors
              }
            });
        },
        1000
      );
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle rate limit exceeded error
        await new Promise((resolve) => setTimeout(resolve, 5000)); // Introduce a delay of 5 seconds before retrying
        this.fetchData(); // Retry the API request
      } else {
        // Handle other errors
      }
    }
  }

  getSingleAttempt = (id) => {
    this.setState({ isLoading: true });
    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var encryptedattemptId = btoa(this.state.attemptId);
    // Set a new parameter or update an existing one
    params.set("attemptId", encryptedattemptId);
    // Get the current URL
    var urls = window.location.href;
    // Create a new URL with the updated parameters
    var newUrl = decodeURIComponent(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.toString()
    );
    // Modify the URL without reloading the page
    window.history.pushState({ path: newUrl }, "", newUrl);

    axios.get(Constants.getUrls.attempts + "/" + id).then((resp) => {
      if (resp.data.status === "suceess") {
        var params = new URLSearchParams(window.location.search);
        var encryptedbatchId = btoa(this.state.batchWings[0].batch_wings_id);
        // Set a new parameter or update an existing one
        params.set("batchId", encryptedbatchId);
        var newUrl = decodeURIComponent(
          window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?" +
            params.toString()
        );
        // Modify the URL without reloading the page
        window.history.pushState({ path: newUrl }, "", newUrl);

        this.setState(
          {
            attemptName: resp.data.data.title,
            attemptId: resp.data.data.id,
            batchId: this.state.batchWings[0].batch_wings_id,
          },
          () => {
            axios
              .get(
                Constants.getUrls.coursesAll +
                  this.state.subjectId +
                  "?batch_wings_id=" +
                  this.state.batchId +
                  "&language=" +
                  this.state.language +
                  "&attempt=" +
                  this.state.attemptId +
                  "&subject_filter_id=" +
                  this.state.subject_filter_id
              )
              .then((respAll) => {
                let allCourses = [];
                respAll.data.data.wingsCourses.forEach((item) => {
                  if (
                    (item.course.project_course_status === "both" ||
                      item.course.project_course_status === "ak1") &&
                    item.course.IS_DELETED === 0
                  ) {
                    allCourses.push(item);
                  }
                });
                let activeCourses = [];
                allCourses.forEach((item) => {
                  if (item.course.STATUS === "ACTIVE") {
                    activeCourses.push(item);
                  }
                });
                this.setState(
                  {
                    courses: activeCourses,
                    loadingWing: false,
                    price: respAll.data
                      ? respAll.data.course
                        ? respAll.data.course.variant
                          ? respAll.data.course.variant[0]
                            ? respAll.data.course.variant[0].price
                            : null
                          : null
                        : null
                      : null,
                    courseId: respAll.data
                      ? respAll.data._id
                        ? respAll.data._id
                        : 0
                      : 0,
                    isLoading: false,
                  },
                  () => {
                    const GetDays = (d, Mention_today = false) => {
                      var DateArray = [];
                      var days = d;
                      for (var i = 0; i < days; i++) {
                        if (!Mention_today && i == 0) {
                          i = 1;
                          days += 1;
                        }
                        var date = new Date();
                        var last = new Date(
                          date.getTime() + i * 24 * 60 * 60 * 1000
                        );
                        var day = last.getDate().toString();
                        var month = (last.getMonth() + 1).toString();
                        var year = last.getFullYear();
                        var monthSplit = month.split("");
                        var daySplit = day.split("");
                        const fulld =
                          Number(year) +
                          "-" +
                          (monthSplit[1]
                            ? Number(month)
                            : "0" + monthSplit[0]) +
                          "-" +
                          (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                        DateArray.push(fulld);
                      }
                      return DateArray;
                    };
                    this.setState({
                      getUpcomingDays: GetDays(100),
                    });
                    let arr1 = this.state.courses;
                    let arr2 = this.state.getUpcomingDays;
                    let arr3 =
                      this.state.getUpcomingDays !== undefined &&
                      arr1.filter((arr1Item) =>
                        arr2.includes(
                          arr1Item
                            ? arr1Item.batch
                              ? arr1Item.batch.registration_start_date
                                ? arr1Item.batch.registration_start_date
                                : ""
                              : ""
                            : ""
                        )
                      );
                    this.setState({
                      sameDate: arr3,
                      same: true,
                    });
                  }
                );
              });
          }
        );
      }
    });
  };

  dateFormat = (cdate) => {
    let date = moment(cdate).format("D MMM YYYY");
    return date;
  };

  changeLanguage = (language) => {
    const retryAttempts = 3; // Number of retry attempts
    const retryDelay = 1000; // Delay between retry attempts in milliseconds

    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var encryptedlanguage = language;
    // Set a new parameter or update an existing one
    params.set("language", encryptedlanguage);
    // Get the current URL
    var urls = window.location.href;
    // Create a new URL with the updated parameters
    var newUrl = decodeURIComponent(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.toString()
    );
    // var newUrl = decodedURL;

    // Modify the URL without reloading the page
    window.history.pushState({ path: newUrl }, "", newUrl);

    this.setState({ isLoading: true });
    if (language === "Hindi") {
      this.setState({
        backHindiColor: true,
        backEnglishColor: false,
      });
    } else {
      this.setState({
        backHindiColor: false,
        backEnglishColor: true,
      });
    }
    this.setState({
      loadingWing: true,
    });

    this.setState(
      {
        language: language,
      },
      () => {
        const payload = {
          course_id: this.state.courseId,
          batch_wings_id: this.state.batchId,
          language: this.state.language,
        };

        axios
          .get(
            Constants.getUrls.coursesAll +
              this.state.subjectId +
              "?batch_wings_id=" +
              this.state.batchId +
              "&language=" +
              this.state.language +
              "&attempt=" +
              this.state.attemptId +
              "&subject_filter_id=" +
              this.state.subject_filter_id
          )
          .then((respAll) => {
            let allCourses = [];
            respAll.data.data.wingsCourses.forEach((item) => {
              if (
                (item.course.project_course_status === "both" ||
                  item.course.project_course_status === "ak1") &&
                item.course.IS_DELETED === 0
              ) {
                allCourses.push(item);
              }
            });
            let activeCourses = [];
            allCourses.forEach((item) => {
              if (item.course.STATUS === "ACTIVE") {
                activeCourses.push(item);
              }
            });
            this.setState(
              {
                courses: activeCourses,
                loadingWing: false,
                price: respAll.data
                  ? respAll.data.course
                    ? respAll.data.course.variant
                      ? respAll.data.course.variant[0]
                        ? respAll.data.course.variant[0].price
                        : null
                      : null
                    : null
                  : null,
                courseId: respAll.data
                  ? respAll.data._id
                    ? respAll.data._id
                    : 0
                  : 0,
                isLoading: false,
              },
              () => {
                const GetDays = (d, Mention_today = false) => {
                  var DateArray = [];
                  var days = d;
                  for (var i = 0; i < days; i++) {
                    if (!Mention_today && i == 0) {
                      i = 1;
                      days += 1;
                    }
                    var date = new Date();
                    var last = new Date(
                      date.getTime() + i * 24 * 60 * 60 * 1000
                    );
                    var day = last.getDate().toString();
                    var month = (last.getMonth() + 1).toString();
                    var year = last.getFullYear();
                    var monthSplit = month.split("");
                    var daySplit = day.split("");
                    const fulld =
                      Number(year) +
                      "-" +
                      (monthSplit[1] ? Number(month) : "0" + monthSplit[0]) +
                      "-" +
                      (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                    DateArray.push(fulld);
                  }
                  return DateArray;
                };
                this.setState({
                  getUpcomingDays: GetDays(100),
                });
                let arr1 = this.state.courses;
                let arr2 = this.state.getUpcomingDays;
                let arr3 =
                  this.state.getUpcomingDays !== undefined &&
                  arr1.filter((arr1Item) =>
                    arr2.includes(
                      arr1Item
                        ? arr1Item.batch
                          ? arr1Item.batch.registration_start_date
                            ? arr1Item.batch.registration_start_date
                            : ""
                          : ""
                        : ""
                    )
                  );
                this.setState({
                  sameDate: arr3,
                  same: true,
                });
              }
            );
          });
      }
    );
  };

  getCoursesWing = (batchWingId, e, index) => {
    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var encryptedbatchId = btoa(batchWingId);
    // Set a new parameter or update an existing one
    params.set("batchId", encryptedbatchId);
    // Get the current URL
    var urls = window.location.href;

    // Decode the URL
    // var decodedURL = decodeURIComponent(urls);

    // Print the decoded URL

    // Create a new URL with the updated parameters
    var newUrl = decodeURIComponent(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.toString()
    );
    // var newUrl = decodedURL;

    // Modify the URL without reloading the page
    window.history.pushState({ path: newUrl }, "", newUrl);

    this.setState({ isLoading: true, batchId: batchWingId }, () => {});
    axios
      .get(
        Constants.getUrls.coursesAll +
          this.state.subjectId +
          "?batch_wings_id=" +
          batchWingId +
          "&language=" +
          this.state.language +
          "&attempt=" +
          this.state.attemptId +
          "&subject_filter_id=" +
          this.state.subject_filter_id
      )
      .then((resp) => {
        let allCourses = [];
        resp.data.data.wingsCourses.forEach((item) => {
          if (
            (item.course.project_course_status === "both" ||
              item.course.project_course_status === "ak1") &&
            item.course.IS_DELETED === 0
          ) {
            allCourses.push(item);
          }
        });
        let activeCourses = [];
        allCourses.forEach((item) => {
          if (item.course.STATUS === "ACTIVE") {
            activeCourses.push(item);
          }
        });
        this.setState(
          {
            courses: activeCourses,
            loadingWing: false,
            batchId: batchWingId,
            price: resp.data
              ? resp.data.course
                ? resp.data.course.variant
                  ? resp.data.course.variant[0]
                    ? resp.data.course.variant[0].price
                    : null
                  : null
                : null
              : null,
            isLoading: false,
          },
          () => {
            const GetDays = (d, Mention_today = false) => {
              var DateArray = [];
              var days = d;
              for (var i = 0; i < days; i++) {
                if (!Mention_today && i == 0) {
                  i = 1;
                  days += 1;
                }
                var date = new Date();
                var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);
                var day = last.getDate().toString();
                var month = (last.getMonth() + 1).toString();
                var year = last.getFullYear();
                var monthSplit = month.split("");
                var daySplit = day.split("");
                const fulld =
                  Number(year) +
                  "-" +
                  (monthSplit[1] ? Number(month) : "0" + monthSplit[0]) +
                  "-" +
                  (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                DateArray.push(fulld);
              }
              return DateArray;
            };
            this.setState({
              getUpcomingDays: GetDays(100),
            });
            let arr1 = this.state.courses;
            let arr2 = this.state.getUpcomingDays;
            let arr3 =
              this.state.getUpcomingDays !== undefined && arr1
                ? arr1.filter((arr1Item) =>
                    arr2.includes(
                      arr1Item
                        ? arr1Item.batch
                          ? arr1Item.batch.registration_start_date
                            ? arr1Item.batch.registration_start_date
                            : ""
                          : ""
                        : ""
                    )
                  )
                : null;
            this.setState({
              sameDate: arr3,
              same: true,
            });
          }
        );
      });
    //   }
    // );
    // }
  };

  checkedBatch = (id) => {
    let wingsId = id;
    let istrue = false;
    if (this.state.array_batch_wings_id.length > 0) {
      let batch = this.state.array_batch_wings_id.find((v) => v.id === id);
      if (batch) {
        istrue = true;
      } else {
        istrue = false;
      }
    } else {
      istrue = false;
    }
    return istrue;
  };
  checkedSubjects = (id) => {
    let istrue = false;
    if (this.state.array_subject_filter_ids.length > 0) {
      let subject = this.state.array_subject_filter_ids.find(
        (v) => v.id === id
      );
      if (subject) {
        istrue = true;
      } else {
        istrue = false;
      }
    } else {
      istrue = false;
    }
    return istrue;
  };
  // changeActive = (e) => {
  //   const value = e.target.value;
  //   let arrayId =[this.state.array_batch_wings_id]
  //   if(!arrayId.includes(value)){
  //     arrayId.push({"id":value});
  //   }
  //   this.getCoursesWing(value)

  // let ischeck = false;
  // this.state.array_batch_wings_id.forEach((v) => {
  //   if (v.id !== value) {
  //     this.state.array_batch_wings_id.push({ id: value });
  //   }
  // });
  // };

  // changeActive=(e)=>{
  // }
  subjectFilter = () => {
    axios
      .get(
        Constants.getUrls.subjectFilters + "?subject_id=" + this.state.subjectId
      )
      .then((res) => {
        let subject_array = res.data[0];

        this.setState({ subjectFilter: subject_array.reverse() }, () => {
          this.setState({
            subject_filter_id: this.state.subjectFilter[0]?.id,
          });
          this.state.array_subject_filter_ids.push({
            id: this.state.subjectFilter[0]?.id,
          });
          this.setState({
            array_subject_filter_ids: this.state.array_subject_filter_ids,
          });
        });
      });
  };
  getCoursesFilter = (id) => {
    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var encryptedsubject_filter_id = btoa(id);
    // Set a new parameter or update an existing one
    params.set("subject_filter_id", encryptedsubject_filter_id);
    // Get the current URL
    var urls = window.location.href;

    // Decode the URL
    // var decodedURL = decodeURIComponent(urls);

    // Print the decoded URL

    // Create a new URL with the updated parameters
    var newUrl = decodeURIComponent(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.toString()
    );
    // var newUrl = decodedURL;

    // Modify the URL without reloading the page
    window.history.pushState({ path: newUrl }, "", newUrl);

    this.setState(
      {
        subject_filter_id: id,
        isLoading: true,
      },
      () => {
        axios
          .get(
            Constants.getUrls.coursesAll +
              this.state.subjectId +
              "?batch_wings_id=" +
              this.state.batchId +
              "&language=" +
              this.state.language +
              "&attempt=" +
              this.state.attemptId +
              "&subject_filter_id=" +
              id
          )
          .then((resp) => {
            let allCourses = [];
            resp.data.data.wingsCourses.forEach((item) => {
              if (
                (item.course.project_course_status === "both" ||
                  item.course.project_course_status === "ak1") &&
                item.course.IS_DELETED === 0
              ) {
                allCourses.push(item);
              }
            });
            let activeCourses = [];
            allCourses.forEach((item) => {
              if (item.course.STATUS === "ACTIVE") {
                activeCourses.push(item);
              }
            });
            this.setState({
              courses: activeCourses,
              isLoading: false,
            });
          });
      }
    );
  };
  selectBatch = (subject_id, batch_id, course_id, batch_wings_id) => {
    window.location.href =
      "/course_details/" +
      this.state.streamId +
      "/" +
      this.state.attemptId +
      "/" +
      subject_id +
      "/" +
      batch_id +
      "/" +
      course_id +
      "/" +
      batch_wings_id;
  };

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notFound,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        <section className="section1" style={{ marginTop: "14px" }}>
          {/* {this.state.courses.map((batch) => (
            <h1 className="fonts"> {batch.course.TITLE}</h1>
          ))} */}
          <h1 className="fonts"> {this.state.subjectName}</h1>
        </section>
        <div
          className={window.innerWidth > 768 ? "container-fluid" : "container"}
        >
          <div className="row">
            <div className="lang-resp-container">
              <h5>
                Lectures are available in English for
                <span>South Indian Students.</span>
              </h5>
              <div className="lang-resp-btn">
                <button
                  className={
                    this.state.language === "Hindi"
                      ? "hindiBtnSelected"
                      : "hindiBtnGray"
                  }
                  style={{
                    borderTopRightRadius:
                      this.state.language === "English" ? "0rem" : "2rem",
                    borderBottomRightRadius:
                      this.state.language === "English" ? "0rem" : "2rem",
                  }}
                  onClick={() => this.changeLanguage("Hindi")}
                >
                  Hindi
                </button>
                <button
                  className={
                    this.state.language === "English"
                      ? "englishBtnSelected"
                      : "englishBtnGray"
                  }
                  onClick={() => this.changeLanguage("English")}
                >
                  English
                </button>
              </div>
            </div>
            <div className="lang-resp-divider"></div>

            {/* <div className="col-6 col-xs-8 col-md-6">
              <div className="lectureAvailable">
                <h5 className="">
                  Lectures are available in English for South Indian Students.
                </h5>
              </div>
            </div>
            <div className="col-6 col-xs-6">
              <span className="spanRow">
                <button
                  className={
                    this.state.language === "Hindi"
                      ? "hindiBtnSelected"
                      : "hindiBtnGray"
                  }
                  onClick={() => this.changeLanguage("Hindi")}
                >
                  Hindi
                </button>
                <button
                  className={
                    this.state.language === "English"
                      ? "englishBtnSelected"
                      : "englishBtnGray"
                  }
                  onClick={() => this.changeLanguage("English")}
                >
                  English
                </button>
              </span>
            </div> */}
          </div>

          <section>
            <div className="container-fluid">
              <div className="row attemptWrapper">
                <p className="attemptTitle">
                  Showing Courses for{" "}
                  <span className="jun">{this.state.attemptName} Attempt</span>{" "}
                </p>
                <span>
                  <select
                    name="attempt"
                    id=""
                    value={parseInt(this.state.attemptId)}
                    className="selectAttempts"
                    onChange={(e) => {
                      this.setState(
                        {
                          attemptShow: true,
                          attemptId: e.target.value,
                        },
                        () => {
                          this.getSingleAttempt(this.state.attemptId);
                          window.location.reload();
                        }
                      );
                    }}
                  >
                    <option value="">Change Attempt</option>
                    {this.state.attempts.map((attempt) => (
                      <option value={attempt.id}>{attempt.title}</option>
                    ))}
                  </select>
                </span>

                <select
                  name="subject_filter_id"
                  id=""
                  className="subjectFilterBtn"
                  // className="selectAtt"
                  // value={
                  //   this.state.subject_filter_clicked
                  //     ? this.state.subject_filter_id
                  //     : "Subject Filters"
                  // }
                  value={parseInt(this.state.subject_filter_id)}
                  onChange={(e) => {
                    this.setState(
                      {
                        subject_filter_id: e.target.value,
                        subject_filter_clicked: true,
                      },
                      () => {
                        this.getCoursesFilter(this.state.subject_filter_id);
                      }
                    );
                  }}
                >
                  {/* <option value={this.state.subject_filter_id}>
                    Subject Filters
                  </option> */}
                  {this.state.subjectFilter.map((course) => (
                    <option value={course.id}>{course.title}</option>
                  ))}
                </select>
              </div>
              <div className="batchWrapper">
                {this.state.batchWings.map((wing, i) => (
                  <div className="checkDiv" key={i}>
                    <button
                      className="wingBtn"
                      onClick={(e) => {
                        this.setState({ selectedIndex: i }, () => {
                          this.getCoursesWing(wing.batch_wing?.id, e, i);
                        });
                      }}
                      style={{
                        backgroundColor:
                          this.state.selectedIndex === i ||
                          parseInt(this.state.batchId) === wing.batch_wing?.id
                            ? "#003399"
                            : "gray",
                        color: this.state.selectedIndex === i ? "#fff" : "#fff",
                      }}
                    >
                      {wing.batch_wing?.title ? wing.batch_wing.title : "NA"}
                    </button>
                  </div>
                ))}
              </div>
              {this.state.isLoading ? (
                <Lottie
                  options={defaultLoader}
                  height={80}
                  width={80}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
              ) : (
                <div className="row container">
                  {this.state.courses.length > 0 ? (
                    this.state.courses.map((batch) => (
                      <div
                        className={
                          window.innerWidth >= 768
                            ? "courseWrap col-xs-6"
                            : "courseWrap"
                        }
                      >
                        <div className="row">
                          <div className="col-md-7 col-xs-7">
                            {batch?.batch?.registration_end_date === null ? (
                              ""
                            ) : (
                              <h5
                                className="registration"
                                style={{ marginLeft: "8px" }}
                              >
                                Registration Ends on &nbsp;
                                {this.dateFormat(
                                  batch?.batch?.registration_end_date
                                )}
                              </h5>
                            )}
                          </div>
                          <div className="col-md-5 col-xs-5">
                            <button
                              className="udanBtn"
                              style={{ backgroundColor: batch?.batch.color }}
                            >
                              {batch?.batch?.batch_name}
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="courseDivAreaLanding">
                            {/* {this.state.getUpcomingDays.includes(
                              batch?.batch?.registration_end_date
                            ) ? (
                              ""
                            ) : (
                              <div className="overLayWrap"></div>
                            )} */}
                            {moment().format("YYYY-MM-DD") ===
                              moment(batch?.batch?.registration_end_date)
                                .add(1, "month")
                                .startOf("month")
                                .format("YYYY-MM-DD") && (
                              <div className="overLayWrap"></div>
                            )}
                            <div className="batchBackColor">
                              <div>
                                <img
                                  src={
                                    Constants.imgUrl + batch.course.IMAGE_PATH
                                  }
                                  alt=""
                                  // width={322}
                                  // height={165}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "0.5rem",
                                  }}
                                />
                              </div>
                              <div className="row">
                                <div className="daysAreaCourse">
                                  <div style={{ textAlign: "center" }}>
                                    <h5 className="subnaame">
                                      {batch.course.TITLE}
                                    </h5>
                                  </div>
                                  <div className="daysDivCourse">
                                    {batch?.course?.amenity_details?.map(
                                      (amenity) => {
                                        let item = amenity.descriptions
                                          .replace(/<br>/g, "")
                                          .replace(/<div>/g, "");
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              margin: "0.5rem 0rem",
                                            }}
                                          >
                                            <i class="fa-solid fa-check hexCode"></i>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: item,
                                              }}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div
                                  className="enrollAreaCourse "
                                  style={{
                                    // opacity:
                                    //   !this.state.getUpcomingDays.includes(
                                    //     batch.batch?.registration_end_date
                                    //   ) &&
                                    //   !this.state.getLastDays.find(
                                    //     (item) =>
                                    //       item.batch.registration_end_date ===
                                    //       batch.batch.registration_end_date
                                    //   )
                                    //     ? 0.5
                                    //     : 1,
                                    opacity:
                                      moment().format("YYYY-MM-DD") ===
                                      moment(
                                        batch?.batch?.registration_end_date
                                      )
                                        .add(1, "month")
                                        .startOf("month")
                                        .format("YYYY-MM-DD")
                                        ? 0.5
                                        : 1,
                                  }}
                                >
                                  <div className="col-md-12">
                                    <div
                                      className="col-md-4 col-xs-4"
                                      style={{
                                        position: "relative",
                                        top: "-8px",
                                        left: "7px",
                                      }}
                                    >
                                      <h5
                                        className="hexTime"
                                        style={{ color: batch?.batch.color }}
                                      >
                                        {/* <span
                                          style={{
                                            fontSize: "18px",
                                            marginRight: "6px",
                                          }}
                                        >
                                          &#9201;
                                        </span> */}
                                        <i
                                          class="fa-regular fa-clock"
                                          style={{
                                            fontSize: "18px",
                                            marginRight: "6px",
                                          }}
                                        ></i>
                                        {batch.course
                                          ? batch.course.DURATION
                                          : ""}
                                      </h5>
                                    </div>
                                    <div
                                      className="col-md-5 col-xs-5"
                                      style={{
                                        position: "relative",
                                        top: "-8px",
                                        left: "12px",
                                      }}
                                    >
                                      {!this.state.getUpcomingDays.includes(
                                        batch.batch?.registration_end_date
                                      ) &&
                                      !this.state.getLastDays.find(
                                        (item) =>
                                          item.batch.registration_end_date ===
                                          batch.batch.registration_end_date
                                      ) ? (
                                        <Link
                                          to={
                                            "/course_details/" +
                                            this.state.streamId +
                                            "/" +
                                            this.state.attemptId +
                                            "/" +
                                            this.state.subjectId +
                                            "/" +
                                            batch.batch_id +
                                            "/" +
                                            batch.course_id +
                                            "/" +
                                            batch.batch_wings_id
                                          }
                                        >
                                          <button
                                            className="enrollBtnCourse"
                                            style={{
                                              backgroundColor:
                                                batch?.batch.color,
                                            }}
                                          >
                                            Enroll Now
                                          </button>
                                        </Link>
                                      ) : (
                                        <Link
                                          to={
                                            "/course_details/" +
                                            this.state.streamId +
                                            "/" +
                                            this.state.attemptId +
                                            "/" +
                                            this.state.subjectId +
                                            "/" +
                                            batch.batch_id +
                                            "/" +
                                            batch.course_id +
                                            "/" +
                                            batch.batch_wings_id
                                          }
                                        >
                                          <button
                                            className="enrollBtnCourse"
                                            style={{
                                              backgroundColor:
                                                batch?.batch.color,
                                            }}
                                          >
                                            Enroll Now
                                          </button>
                                        </Link>
                                      )}
                                    </div>
                                    <div
                                      className="col-md-3 col-xs-3"
                                      style={{
                                        position: "relative",
                                        top: "-5px",
                                        left: "-20px",
                                      }}
                                    >
                                      <h5
                                        className="courseAmt"
                                        style={{ color: batch?.batch.color }}
                                      >
                                        {batch.course
                                          ? batch.course.variant
                                            ? batch.course.variant[0]
                                              ? batch.course.variant[0].price
                                              : batch.course.PRICE
                                            : batch.course.PRICE
                                          : batch.course.PRICE}
                                        /-
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="col-md-4 regiBatches"
                      style={{
                        textAlign: "center",
                        marginTop: "20%",
                        marginLeft: "-10%",
                        fontSize: "24px",
                        fontFamily: "raleway",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        height={200}
                        width={300}
                        isStopped={this.state.isStopped}
                        isPaused={this.state.isPaused}
                      />
                    </div>
                  )}
                </div>
              )}

              <div
                className="row"
                style={{
                  marginTop: this.state.courses.length > 0 ? "55px" : "27%",
                }}
                onClick={this.showAnswer}
              >
                <div className="batchSystem courseDropdown">
                  <div className="row">
                    <div className="col-md-10 col-xs-10">
                      <h4 className="questions">
                        What is Batch System and it's Benefits?
                      </h4>
                    </div>
                    {this.state.answer ? (
                      <div className="col-md-2 arrowHexRight col-xs-2">
                        {/* <span
                          style={{ fontSize: "30px" }}
                          onClick={this.showAnswer}
                        >
                          &#129171;
                        </span> */}
                        <i
                          class="fa-solid fa-caret-down"
                          style={{ fontSize: "18px", marginTop: "10px" }}
                          onClick={this.showAnswer}
                        ></i>
                      </div>
                    ) : (
                      <div className="col-md-2 arrowHexRight col-xs-2">
                        <i
                          class="fa-solid fa-caret-right"
                          style={{ fontSize: "18px", marginTop: "10px" }}
                          onClick={this.showAnswer}
                        ></i>
                      </div>
                    )}
                  </div>
                  {this.state.answer ? (
                    <h5 className="answers">
                      We provide automatic lorem Ipsum is simply dummy text of
                      the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book.
                    </h5>
                  ) : null}
                </div>
              </div>

              <div
                className="row"
                style={{ marginTop: "20px" }}
                onClick={this.showAnswer1}
              >
                <div className="batchSystem courseDropdown">
                  <div className="row">
                    <div className="col-md-10 col-xs-10">
                      <h4 className="questions">What is Target Strategy?</h4>
                    </div>
                    {this.state.answer1 ? (
                      <div className="col-md-2 arrowHexRight col-xs-2">
                        <i
                          class="fa-solid fa-caret-down"
                          style={{ fontSize: "18px", marginTop: "10px" }}
                          onClick={this.showAnswer1}
                        ></i>
                      </div>
                    ) : (
                      <div className="col-md-2 arrowHexRight col-xs-2">
                        <i
                          class="fa-solid fa-caret-right"
                          style={{ fontSize: "18px", marginTop: "10px" }}
                          onClick={this.showAnswer1}
                        ></i>
                      </div>
                    )}
                  </div>
                  {this.state.answer1 ? (
                    <h5 className="answers">
                      We provide automatic lorem Ipsum is simply dummy text of
                      the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book.
                    </h5>
                  ) : null}
                </div>
              </div>

              <div
                className="row"
                style={{ marginTop: "20px" }}
                onClick={this.showAnswer2}
              >
                <div className="batchSystem courseDropdown">
                  <div className="row">
                    <div className="col-md-10 col-xs-10">
                      <h4 className="questions">Importance of Live Session</h4>
                    </div>
                    {this.state.answer2 ? (
                      <div className="col-md-2 arrowHexRight col-xs-2">
                        <i
                          class="fa-solid fa-caret-down"
                          style={{ fontSize: "18px", marginTop: "10px" }}
                          onClick={this.showAnswer2}
                        ></i>
                      </div>
                    ) : (
                      <div className="col-md-2 arrowHexRight col-xs-2">
                        <i
                          class="fa-solid fa-caret-right"
                          style={{ fontSize: "18px", marginTop: "10px" }}
                          onClick={this.showAnswer2}
                        ></i>
                      </div>
                    )}
                  </div>
                  {this.state.answer2 ? (
                    <h5 className="answers">
                      We provide automatic lorem Ipsum is simply dummy text of
                      the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book.
                    </h5>
                  ) : null}
                </div>
              </div>

              <div
                className="row"
                style={{ marginTop: "20px" }}
                onClick={this.showAnswer3}
              >
                <div
                  className="batchSystem courseDropdown"
                  style={{ width: "100% !important" }}
                >
                  <div className="row">
                    <div className="col-md-10 col-xs-10">
                      <h4 className="questions">
                        Know more Benefits of Doubt Portal
                      </h4>
                    </div>
                    {this.state.answer3 ? (
                      <div className="col-md-2 arrowHexRight col-xs-2">
                        <i
                          class="fa-solid fa-caret-down"
                          style={{ fontSize: "18px", marginTop: "10px" }}
                          onClick={this.showAnswer3}
                        ></i>
                      </div>
                    ) : (
                      <div className="col-md-2 arrowHexRight col-xs-2">
                        <i
                          class="fa-solid fa-caret-right"
                          style={{ fontSize: "18px", marginTop: "10px" }}
                          onClick={this.showAnswer3}
                        ></i>
                      </div>
                    )}
                  </div>
                  {this.state.answer3 ? (
                    <h5 className="answers">
                      We provide automatic lorem Ipsum is simply dummy text of
                      the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book.
                    </h5>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
