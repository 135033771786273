import React, { Component } from "react";
import Constants from "components/common/Constants";
import { Animated } from "react-animated-css";
import Modal from "react-awesome-modal";
import Batches from "../Batches";
import Books from "../Books";
import Footer from "../common/Footer";
import Header from "../common/Header";
import JoinNow from "../JoinNow";
import Poster from "../Poster";
import Testimonials from "../Testimonials";
import Popupmodel from "../Popupmodel";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { axios } from "axios";

import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
export default class HomePage extends Component {
  state = {
    label: [],
    slider: [],
    popup: [],
  };

  // componentDidMount() {
  //   this.getSlideModal();
  // }

  // getSlideModal = () => {
  //   axios.get(Constants.getUrls.slider).then((res) => {
  //     console.log(res.data);
  //     this.setState({
  //       popup: res.data,
  //     });
  //   });
  // };
  render() {
    return (
      <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
        {/* this showing a modal slider when page is open  */}
        <Popupmodel />
        <Header />
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1000}
          isVisible={true}
          animateOnce={true}
        > */}
        <Poster />
        {/* </ScrollAnimation> */}
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1000}
          isVisible={true}
          animateOnce={true}
        > */}
        <Batches />
        {/* </ScrollAnimation> */}
        <Books />
        <Testimonials />
        <JoinNow />
        <Footer />
      </div>
    );
  }
}
