import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Sidebar extends Component {
  state = {
    navClose: { right: "0px" },
    openMainScreen: false,
  };
  logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  render() {
    return (
      <div>
        <div className="dashboard-left webDisplay" style={{ left: 0 }}>
          <div className="block-content">
            <Link
              to="/dashboard/activation"
              className="dashboradLeftSidebarContents"
            >
              Activation Process
            </Link>
            <Link
              to="/dashboard/lecturesLink"
              className="dashboradLeftSidebarContents"
            >
              Player Software File
            </Link>

            <Link
              to="/dashboard/orders"
              className="dashboradLeftSidebarContents"
            >
              {/* My Orders */}
              Video Lecture (Smart Classroom)/Books
            </Link>

            <Link
              to="/dashboard/facetofaceorders"
              className="dashboradLeftSidebarContents"
            >
              {/* Face to Face Orders */}
              Face to Face & Live Stream Batch
            </Link>
            <Link
              to="/dashboard/profile"
              className="dashboradLeftSidebarContents"
            >
              My Profile
            </Link>
            <Link
              to="/dashboard/change-password"
              className="dashboradLeftSidebarContents"
            >
              Change Password
            </Link>
            <Link
              to=""
              className="last dashboradLeftSidebarContents logoutDashboard"
              onClick={this.logout}
            >
              Logout
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
