import React, { Component } from "react";
// import "../scss/cours";
import "../scss/FaceToFaceDetailFooter.scss";
import Lottie from 'react-lottie';
// import subject_bg from "../../images/subject_bg.jpg";
import subject_bg from "../images/sub_back.png";
// import ak_classes_book from "../../images/ak_classes_book.png";

// import akSir from "../images/akSir.png";
import axios from "axios";
import Constants from "./common/Constants";
import TestSeries from "./Screens/TestSeries";
import moment from "moment";
import pdf from "../images/pdf.png";
// import { Link } from "react-router-dom";
import init from "./helper/WindowToken";
import Header from "./common/Header";
import Footer from "./common/Footer";
import animationData from "../images/comingsoon.json"
import Books from "components/Books";



export default class BooksListing extends Component {
  state = {
    answer: false,
    answer1: false,
    answer2: false,
    answer3: false,
    subjects: [],
    streamName: "",
    CatId: window.location.pathname.split("/")[2],
    LevelId: window.location.pathname.split("/")[3],
    streamId: window.location.pathname.split("/")[4],
    attempts: [],
    attempt: "",
    attemptId: "",
    attemptShow: false,
    attemptName: "",
    subjectId: "",
    userDetails: [],
    subjectName: "",
    LectureData: [],
    colorCodes: ["#2f65b4", "#df7504", "#279b14", "#6f1561"],
  };
  // componentDidMount() {
  //   this.getUserInfo();
  //   this.getsubject();

  //   axios.get(Constants.getUrls.getAttempts).then((res) => {
  //     console.log(res);
  //     this.setState(
  //       {
  //         attempts: res.data,
  //         attemptId: res.data[0].id,
  //       },
  //       () => {
  //         this.getSingleAttempt(this.state.attemptId);
  //       }
  //     );
  //   });
  // }

  // getUserInfo = () => {
  //   if (init() === "success") {
  //     axios.get(Constants.getUrls.user).then((res) => {
  //       console.log(res.data);
  //       this.setState({ userDetails: res.data }, () => {
  //         console.log(this.state.userInfo);
  //       });
  //     });
  //   }
  // };

  // getSingleAttempt = (id) => {
  //   axios.get(Constants.getUrls.attempts + "/" + id).then((resp) => {
  //     console.log(resp);
  //     if (resp.data.status === "suceess") {
  //       this.setState({
  //         attemptName: resp.data.data.title,
  //       });
  //     }
  //   });
  // };

  // getsubject = () => {
  //   axios
  //     .get(
  //       Constants.getUrls.getSubjectsFacetoFace +
  //       "?stream_id=" +
  //       this.state.streamId
  //     )
  //     .then((resp) => {
  //       console.log(resp);
  //       this.setState(
  //         {
  //           subjects: resp.data.data,
  //           subjectId: resp.data.data[0].id,
  //           subjectName: resp.data.data[0].title,
  //         },
  //         () => {
  //           console.log(this.state.subjectId);
  //           this.subjectData();
  //         }
  //       );
  //     });
  // };
  // checkedBatch = (id) => {
  //   let wingsId = id;
  //   let istrue = false;
  //   if (this.state.array_batch_wings_id.length > 0) {
  //     let batch = this.state.array_batch_wings_id.find((v) => v.id === id);
  //     if (batch) {
  //       istrue = true;
  //     } else {
  //       istrue = false;
  //     }
  //   } else {
  //     istrue = false;
  //   }
  //   return istrue;
  // };
  // subjectData = () => {
  //   axios
  //     .get(
  //       Constants.getUrls.getFacetoFace +
  //       "?category_id=" +
  //       this.state.CatId +
  //       "&level_id=" +
  //       this.state.LevelId +
  //       "&stream_id=" +
  //       this.state.streamId +
  //       "&subject_id=" +
  //       this.state.subjectId
  //     )
  //     .then((resp) => {
  //       console.log(resp);
  //       this.setState(
  //         {
  //           LectureData: resp.data.data,
  //           streamName: resp.data.data[0].stream.name,
  //         },
  //         () => {
  //           console.log(this.state.LectureData, this.state.streamName);
  //         }
  //       );
  //     });
  // };
  // showAnswer = (e) => {
  //   this.setState({
  //     answer: !this.state.answer,
  //   });
  // };
  // showAnswer1 = (e) => {
  //   this.setState({
  //     answer1: !this.state.answer1,
  //   });
  // };
  // showAnswer2 = (e) => {
  //   this.setState({
  //     answer2: !this.state.answer2,
  //   });
  // };
  // showAnswer3 = (e) => {
  //   this.setState({
  //     answer3: !this.state.answer3,
  //   });
  // };

  // dateFormat = (cdate) => {
  //   let date = moment(cdate).format("Do MMMM YYYY");
  //   return date;
  // };
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <div>
        <Header />

        <div >
          <section classeName="testSeries" >
            <div className="testSeries ">
              {/* <img src={subject_bg} alt="" width={1740} height={85} /> */}
              <h1 className="fonts">Books Details</h1>
              <h5 classeName="fonts titleName"> Books Description</h5>
            </div>
          </section>
          <div className="">
            <div className="row">
              <div className="col-sm-12">
                <section className="" style={{ MarginTop: "130px" }}>
                  {/* <div className="anim">
                    <Lottie options={defaultOptions}
                      height="50%"
                      width="50%"
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused} />
                  </div> */}
                  <Books/>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>


    )

  }
};