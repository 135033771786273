import Axios from "axios";
// import firebase from "firebase";
import { css } from "glamor";
import React, { Component } from "react";
// import { Beforeunload } from "dreact-beforeunloa";
// import ReactDOM from "react-dom";
// import renderHTML from "react-render-html";
// import ScrollToBottom from "react-scroll-to-bottom";
// import YouTube from "react-youtube";
// import "../Assets/css/video.css";
// import clock from "../Assets/Images/clock.png";
// import Constants from "../Common/Constants";
const ROOT_CSS = css({
  height: 450,
});
export default class LiveSessions extends Component {
  state = {
    LiveStreaming: [],
    // id: this.props.match.params.id,
    singleStream: {},
    messages: [],
    messageKeys: [],
    oldestKey: "",
    userNo: "",
    userName: "",
    textMessage: "",
    currentStack: [],
    theposition: window.pageYOffset,
    showLoadMore: false,
    showJoinedNotification: false,
    attendeeName: "",
    showCount: false,
    attendanceCount: 0,
    userRole: "user",
    showAttendee: false,
    allViewers: [],
  };
  // onUnload = e => {
  //   e.preventDefault();
  //   e.returnValue = "";
  // };
  // componentDidMount() {
  //   window.addEventListener("beforeunload", () => alert());

  //   ReactDOM.findDOMNode(this.refs.chatBox).addEventListener(
  //     "scroll",
  //     this.checkScroll
  //   );
  //   const firebaseConfig = {
  //     apiKey: "AIzaSyBCfqCkmWRmTZV887b53_r00xHJwKsVvUM",
  //     authDomain: "ak-classes.firebaseapp.com",
  //     databaseURL: "https://ak-classes.firebaseio.com",
  //     projectId: "ak-classes",
  //     storageBucket: "ak-classes.appspot.com",
  //     messagingSenderId: "197903627850",
  //     appId: "1:197903627850:web:e8839e80fcde3162fbd8b3",
  //     measurementId: "G-84C4W89ZXH"
  //   };
  //   if (firebase.apps.length === 0) {
  //     firebase.initializeApp(firebaseConfig);
  //   }

  //   const id = this.state.id;
  //   Axios.get(Constants.getUrls.LiveStreaming + "?type=Premium").then(res => {
  //     console.log(res);
  //     const singleStream = res.data.data.find(
  //       element => parseInt(element.id) === parseInt(id)
  //     );
  //     console.log(singleStream);
  //     this.setState({ LiveStreaming: res.data.data, singleStream });
  //   });
  //   let userToken = localStorage.getItem("token");
  //   if (localStorage.token) {
  //     Axios.get(Constants.getUrls.user, {
  //       headers: { Authorization: "Bearer " + userToken }
  //     }).then(res => {
  //       console.log(res);
  //       this.setState(
  //         {
  //           userdetails: res.data,
  //           UserType: res.data.TYPE,
  //           userNo: res.data.MOBILE,
  //           userName: res.data.FIRST_NAME,
  //           userRole: res.data.role
  //         },
  //         () => {
  //           this.getMessages();
  //           this.updateJoinedCount();
  //           this.getViewers();
  //           if (this.state.userRole === "faculty") {
  //             this.getAttendanceCount();
  //             this.setState({ showCount: true });
  //           }
  //           console.log(this.state.userRole);
  //         }
  //       );
  //     });
  //   }
  // }
  // componentWillUnmount() {
  //   ReactDOM.findDOMNode(this.refs.chatBox).removeEventListener(
  //     "scroll",
  //     this.checkScroll
  //   );
  //   this.removeAttendee();
  // }
  // converTime = time => {
  //   let d = new Date(time);
  //   let c = new Date();
  //   let result = (d.getHours() < 10 ? "0" : "") + d.getHours() + ":";
  //   result += (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
  //   if (c.getDay() !== d.getDay()) {
  //     result =
  //       d.getDate() +
  //       "/" +
  //       parseInt(d.getMonth() + 1) +
  //       "/" +
  //       d.getFullYear() +
  //       " " +
  //       result;
  //   }
  //   return result;
  // };

  // getMessages = async () => {
  //   firebase
  //     .database()
  //     .ref("livesessions")
  //     .child(this.state.id)
  //     .child("messages")
  //     .on("value", collection => {
  //       console.log(collection.numChildren());
  //     });
  //   firebase
  //     .database()
  //     .ref("livesessions")
  //     .child(this.state.id)
  //     .child("messages")
  //     .orderByKey()
  //     .limitToLast(15)
  //     .on("child_added", value => {
  //       this.setState(
  //         prevState => {
  //           return {
  //             messages: [...prevState.messages, value.val()],
  //             messageKeys: [...prevState.messageKeys, value.key],
  //             showLoadMore: true
  //           };
  //         },
  //         () => {
  //           this.setState({ oldestKey: this.state.messageKeys[0] }, () => {
  //             setTimeout(() => {
  //             }, 1);
  //           });
  //         }
  //       );
  //     });
  // };
  // fetchNextBatch = async () => {
  //   console.log("oldestkey", this.state.oldestKey);

  //   this.setState({ loadingMessage: false, currentStack: [] });
  //   let msgsKeys = [];
  //   let currentStack = [];
  //   firebase
  //     .database()
  //     .ref("livesessions")
  //     .child(this.state.id)
  //     .child("messages")
  //     .orderByKey()
  //     .endAt(this.state.oldestKey)
  //     .limitToLast(15)
  //     .on("child_added", value => {
  //       msgsKeys.push(value.key);
  //       if (value.key !== this.state.oldestKey) {
  //         currentStack.push(value.val());
  //       }

  //     });
  //   this.setState(
  //     {
  //       currentStack
  //     },
  //     () => {
  //       console.log(this.state.currentStack);
  //       if (this.state.currentStack.length === 0) {
  //         this.setState({ showLoadMore: false });
  //       }
  //       this.setState(
  //         {
  //           messages: [...this.state.currentStack, ...this.state.messages],
  //           messageKeys: [...msgsKeys]
  //         },
  //         () => {
  //           this.setState({
  //             oldestKey: this.state.messageKeys[0],
  //             loadingMessage: false
  //           });
  //         }
  //       );
  //     }
  //   );

  // };

  // sendMessage = async e => {
  //   e.preventDefault();
  //   if (this.state.textMessage !== "") {
  //     console.log(this.state.id);
  //     let msgId = firebase
  //       .database()
  //       .ref("livesessions")
  //       .child(this.state.id)
  //       .child("messages")
  //       .push().key;
  //     const messageBody = {
  //       message: this.state.textMessage,
  //       time: firebase.database.ServerValue.TIMESTAMP,
  //       userNo: this.state.userNo,
  //       userName: this.state.userName
  //     };
  //     let updates = {};
  //     updates[
  //       "livesessions/" + this.state.id + "/messages/" + msgId
  //     ] = messageBody;
  //     firebase
  //       .database()
  //       .ref()
  //       .update(updates);
  //     this.setState({ textMessage: "" });
  //   }
  // };

  // handleChange = e => {
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   });
  //   console.log(this.state.textMessage);
  // };

  // checkScroll = () => {
  //   console.log(this.state.theposition);
  // };

  // updateJoinedCount = async () => {
  //   const messageBody = {
  //     name: this.state.userName,
  //     time: firebase.database.ServerValue.TIMESTAMP,
  //     userNo: this.state.userNo
  //   };
  //   let updates = {};
  //   updates[
  //     "livesessions/" + this.state.id + "/attendance/" + this.state.userNo
  //   ] = messageBody;
  //   firebase
  //     .database()
  //     .ref()
  //     .update(updates);
  // };
  // removeAttendee = async () => {
  //   firebase
  //     .database()
  //     .ref("livesessions")
  //     .child(this.state.id)
  //     .child("attendance")
  //     .child(this.state.userNo)
  //     .remove();
  // };
  // getAttendanceCount = async () => {
  //   firebase
  //     .database()
  //     .ref("livesessions")
  //     .child(this.state.id)
  //     .child("attendance")
  //     .on("value", collection => {
  //       this.setState({ attendanceCount: collection.numChildren() });
  //     });
  //   firebase
  //     .database()
  //     .ref("livesessions")
  //     .child(this.state.id)
  //     .child("attendance")
  //     .on("child_added", value => {
  //       console.log("attendance", value.val(), this.state.userNo);
  //       if (this.state.userNo !== value.val().userNo) {
  //         this.setState({
  //           showJoinedNotification: true,
  //           attendeeName: value.val().name
  //         });
  //         setTimeout(() => {
  //           this.setState({ showJoinedNotification: false, attendeeName: "" });
  //         }, 3000);
  //       }
  //     });
  // };
  // getViewers = () => {
  //   firebase
  //     .database()
  //     .ref("livesessions")
  //     .child(this.state.id)
  //     .child("attendance")
  //     .on("child_added", value => {
  //       console.log(value.val(), "users");
  //       if (value.val().userNo !== this.state.userNo)
  //         this.setState(prevState => {
  //           return {
  //             allViewers: [...prevState.allViewers, value.val()]
  //           };
  //         });
  //     });
  // };
  // showAttendee = () => {
  //   this.setState({ showAttendee: !this.state.showAttendee });
  // };

  render() {
    const opts = {
      height: "550",
      width: "100%",
      playerVars: {
        autoplay: 0,
        showInfo: 0,
        modestbranding: 1,
      },
    };
    return (
      <div>
        <p style={{ textAlign: "center", marginTop: "50px", fontSize: "19px" }}>
          Live Sessions Not Available.
        </p>
      </div>
      // <Beforeunload
      //   onBeforeunload={() => {
      //     this.removeAttendee();
      //   }}
      // >
      //   <div className="container">
      //     {this.state.showJoinedNotification ? (
      //       <div
      //         style={{
      //           position: "fixed",
      //           right: 8,
      //           top: 16,
      //           borderRadius: 3,
      //           alignItems: "center",
      //           justifyContent: "center",
      //           zIndex: 9999,
      //           backgroundColor: "green",
      //           display: "flex",
      //           padding: "16px"
      //         }}
      //       >
      //         <p style={{ color: "#fff", fontSize: 16, marginBottom: 0 }}>
      //           {this.state.attendeeName} joined live session
      //         </p>
      //       </div>
      //     ) : null}

      //     {this.state.userRole === "faculty" ? (
      //       <div
      //         style={{
      //           position: "fixed",
      //           bottom: 0,
      //           right: 32,
      //           background: "#fff",
      //           zIndex: 9999,
      //           maxHeight: "500px",
      //           overflow: "scroll"
      //         }}
      //         onClick={this.showAttendee}
      //       >
      //         <div style={{ padding: "16px 32px", background: "#0f66b9" }}>
      //           {this.state.showCount ? (
      //             <p style={{ color: "#fafafa", marginBottom: 0 }}>
      //               {this.state.attendanceCount} people are watching
      //             </p>
      //           ) : null}
      //         </div>
      //         {this.state.showAttendee ? (
      //           <div style={{ padding: "4px 8px", border: "1px solid #ddd" }}>
      //             {this.state.allViewers.map((viewer, index) => (
      //               <p
      //                 style={{
      //                   borderBottom: "1px solid #ddd",
      //                   marginBottom: 0,
      //                   padding: "16px 0"
      //                 }}
      //               >
      //                 {viewer.name}
      //               </p>
      //             ))}
      //           </div>
      //         ) : null}
      //       </div>
      //     ) : null}

      //     <div
      //       className="row"
      //       style={{ marginTop: "20px", marginBottom: "50px" }}
      //     >
      //       <div className="col-sm-8 col-md-8">
      //         <YouTube
      //           // className="ytp-chrome-top"
      //           videoId={
      //             this.state.singleStream?.link
      //               ? this.state.singleStream?.link
      //               : "fDNN5IDNj5o"
      //           }
      //           opts={opts}
      //           onReady={this._onReady}
      //           ref="vidRef"
      //         />

      //         <div className="" style={{ margin: "32px 0" }}>
      //           <div
      //             style={{
      //               textAlign: "center",
      //               justifyContent: "center",
      //               alignItems: "center",
      //               display: "flex",
      //               marginBottom: 16
      //             }}
      //           >
      //             <h3
      //               className="liveVideo"

      //             >
      //               Upcoming Live Sessions
      //             </h3>
      //           </div>

      //           <div className="row">
      //             {this.state.LiveStreaming.map(video =>
      //               parseInt(this.state.id) !== parseInt(video.id) ? (
      //                 <div className="col-sm-4 col-md-4">
      //                   <a
      //                     href={video?.link}
      //                     target="_blank"
      //                     rel="noopener noreferrer"
      //                   >
      //                     <img
      //                       src={Constants.serverDomain + video.image}
      //                       width="100%"
      //                     />

      //                     <p
      //                       style={{
      //                         color: "black",
      //                         marginTop: "10px",
      //                         fontSize: "16px"
      //                       }}
      //                     >
      //                       {" "}
      //                       {video.title}{" "}
      //                     </p>
      //                     <p

      //                     >
      //                       {renderHTML(video.description)}
      //                     </p>
      //                     <p
      //                       style={{
      //                         position: "absolute",
      //                         top: "61%",
      //                         left: "70%",
      //                         color: "black",
      //                         fontWeight: "bold"
      //                       }}
      //                     >
      //                       {" "}
      //                       {video.time}{" "}
      //                     </p>
      //                     <img
      //                       src={clock}
      //                       width="5%"
      //                       style={{
      //                         position: "absolute",
      //                         top: "10%",
      //                         left: "86%"
      //                       }}
      //                     />
      //                   </a>
      //                 </div>
      //               ) : null
      //             )}
      //           </div>
      //         </div>
      //       </div>
      //       <div className="col-sm-4" style={{}}>
      //         <h3
      //           style={{
      //             background: "rgb(15, 102, 185)",
      //             padding: "10px",
      //             color: "white",
      //             textAlign: "center",
      //             width: "100%",
      //             fontWeight: "bold"
      //           }}
      //         >
      //           Comment Section
      //         </h3>

      //         <ScrollToBottom
      //           className={ROOT_CSS}
      //           useScrollToTop={() => {
      //             alert();
      //           }}
      //           followButtonClassName="scrollToBottomButton"
      //         >
      //           <div
      //             ref="chatBox"
      //             style={{
      //               padding: "16px",
      //               border: "1px solid #ddd",
      //               display: "flex",
      //               flexDirection: "column",
      //               background: "#ffd54c",
      //               position: "relative",
      //               height: "auto"
      //             }}
      //             onScroll={this.checkScroll}
      //           >
      //             {this.state.messages.length >= 15 &&
      //             this.state.showLoadMore &&
      //             this.state.singleStream.chat_status === "active" ? (
      //               <p
      //                 onClick={this.fetchNextBatch}
      //                 style={{
      //                   display: "inline-block",
      //                   padding: "8px 10px",
      //                   background: "rgb(15, 102, 185)",
      //                   color: "#fff",
      //                   textAlign: "center",
      //                   width: "80%",
      //                   alignSelf: "center",
      //                   cursor: "pointer"
      //                 }}
      //               >
      //                 Load More Messages
      //               </p>
      //             ) : null}
      //             {this.state.singleStream.chat_status === "inactive" ? (
      //               <p
      //                 style={{
      //                   textAlign: "center",
      //                   position: "absolute",
      //                   top: "50%",
      //                   width: "100%",
      //                   left: 0
      //                 }}
      //               >
      //                 Chat service will be available during live session
      //               </p>
      //             ) : null}

      //             {this.state.singleStream.chat_status === "active"
      //               ? this.state.messages.map((message, index) => (
      //                   <div
      //                     style={{
      //                       textAlign:
      //                         message.userNo === this.state.userNo
      //                           ? "right"
      //                           : "left",
      //                       borderRadius: 16,
      //                       background: "#fff",
      //                       maxWidth: "60%",
      //                       minWidth: "80px",
      //                       display: "block",
      //                       margin: "6px 0",
      //                       padding: "8px 12px",
      //                       alignSelf:
      //                         this.state.userNo === message.userNo
      //                           ? "flex-end"
      //                           : "flex-start",
      //                       borderBottomLeftRadius:
      //                         message.userNo === this.state.userNo ? 16 : 0,
      //                       borderBottomRightRadius:
      //                         message.userNo === this.state.userNo ? 0 : 16
      //                     }}
      //                   >
      //                     {this.state.userNo !== message.userNo ? (
      //                       <p
      //                         style={{
      //                           marginBottom: "4px",
      //                           fontWeight: "bold",
      //                           textTransform: "capitalize"
      //                         }}
      //                       >
      //                         {message.userName}
      //                       </p>
      //                     ) : null}
      //                     <p
      //                       style={{
      //                         marginBottom: 0,
      //                         fontSize: "15px",
      //                         marginBottom: "4px",
      //                         color: "rgb(15, 102, 185)",
      //                         lineHeight: "20px"
      //                       }}
      //                     >
      //                       {message.message}
      //                     </p>
      //                     <p
      //                       style={{
      //                         marginBottom: 0,
      //                         fontSize: "10px",
      //                         color: "rgb(15, 102, 185)",
      //                         opacity: ".5"
      //                       }}
      //                     >
      //                       {this.converTime(message.time)}
      //                     </p>
      //                   </div>
      //                   // </div>
      //                 ))
      //               : null}
      //           </div>
      //         </ScrollToBottom>
      //         {this.state.singleStream.chat_status === "active" ? (
      //           <div style={{ padding: "16px 0" }}>
      //             <form onSubmit={this.sendMessage}>
      //               <input
      //                 type="text"
      //                 placeholder={`Try 'Hello Sir' `}
      //                 onChange={this.handleChange}
      //                 style={{
      //                   width: "77%",
      //                   padding: "8px 16px",
      //                   borderRadius: "8px",
      //                   border: "2px solid rgb(15, 102, 185)"
      //                 }}
      //                 name="textMessage"
      //                 value={this.state.textMessage}
      //               />
      //               <button
      //                 type="submit"
      //                 style={{
      //                   width: "20%",
      //                   marginLeft: "8px",
      //                   background: "rgb(15, 102, 185)",
      //                   color: "#fff",
      //                   fontSize: "16px",
      //                   fontWeight: "bold",
      //                   boxShadow: "none",
      //                   outline: "none",
      //                   border: "none",
      //                   padding: "8px 16px",
      //                   borderRadius: "6px"
      //                 }}
      //               >
      //                 Send
      //               </button>
      //             </form>
      //           </div>
      //         ) : null}
      //       </div>
      //     </div>
      //   </div>
      // </Beforeunload>
    );
  }
}
