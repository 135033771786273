import React, { Component } from "react";
import "../scss/Header.scss";
import removeIcon from "../images/removeImg.png";
import cancel from "../images/cancel.png";
import next from "../images/next.png";

import eye from "../images/eyeImg.png";
import closeEyeImg from "../images/closeEyeImg.png";
import akLogo from "../images/akLogo.png";
import profile from "../images/user (3).png";
import menu from "../images/menu.png";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SnackbarContent,
  TextField,
} from "@mui/material";
// import CheckoutRegitrationNotification from '../images/notification_loader.json';
import axios from "axios";
import Constants from "./common/Constants";
import { Link } from "react-router-dom";
import init from "./helper/WindowToken";

export default class Header extends Component {
  state = {
    showRegister: false,
    showLogin: false,
    overlay: false,
    courses: [
      { label: "9th Standard", value: "9th Standard" },
      { label: "10th Standard", value: "10th Standard" },
      { label: "CA Foundation", value: "CA Foundation" },
      { label: "CA Inter", value: "CA Inter" },
      { label: "Ca Final", value: "Ca Final" },
    ],
    course: "",
    selectedType: false,
    dropDown: false,
    showNotif: false,
    notiMessage: "",
    btnLoading: false,
    firstName: "",
    email: "",
    mobileNo: "",
    password: "",
    confirmPassword: "",
    userDetails: {
      name: "",
      email: "",
      phone_number: 0,
    },
    showError: false,
    max: false,
    passError: false,
    emailLogin: "",
    passwordLogin: "",
    openEye: false,
    closeEye: true,
    message: "",
    courseCategory: [],
    testCat: [],
    facetofaceCat: [],
    selectCat: false,
    headerType: "",
    categoryId: "",
    categoryType: "",
    levels: [],
    streams: [],
    selectedCourseId: "",
    categoryname: "",
    catName: "",
    attempts: [],
    userInfo: {},
    SideNavBar: false,
    firstScreen: true,
    secondScreen: false,
  };

  componentDidMount() {
    this.getUserInfo();
  }
  getUserInfo = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.user).then((res) => {
        this.setState({ userInfo: res.data }, () => {
        });
      });
    }
  };

  getCategory = (type) => {
    this.setState(
      {
        headerType: type,
      },
      () => {
      }
    );

    if (type === "course") {
      axios.get(Constants.getUrls.productsBycatLevels).then((resp) => {
        if (resp.data.length > 0) {
          this.setState(
            {
              courseCategory: resp.data,
              categoryId: resp.data[0].Id,
            },
            () => {
              this.getLevels(this.state.categoryId);
            }
          );
        }
      });
    } else if (type === "facetoface") {
      axios.get(Constants.getUrls.faceCat).then((resFaceCat) => {
        if (resFaceCat.data.data.length > 0) {
          this.setState(
            {
              courseCategory: resFaceCat.data.data,
              categoryId: resFaceCat.data.data[0].id,
            },
            () => {
              this.getFacetoFaceLevels(this.state.categoryId);
            }
          );
        }
      });
    } else if (type === "testseries") {
      axios.get(Constants.getUrls.productsTestseries).then((resTest) => {
        if (resTest.data.length > 0) {
          this.setState({
            courseCategory: resTest.data,
          });
        }
      });
    }
  };

  getLevels = (id) => {
    const newId = this.state.courseCategory.find(
      (courseId) => courseId.ID || courseId.id === id
    );
    this.setState(
      {
        levels: newId.level,
        streams: newId.level ? newId.level[0].stream : [],
        selectedCourseId: newId,
      },
      () => {
          
      }
    );
  };
  getFacetoFaceLevels = (id) => {
    axios
      .get(
        Constants.getUrls.getLevelsFacetoFace +
          "?category_id=" +
          id +
          "&type=COURSE"
      )
      .then((res) => {
        this.setState(
          {
            levels: res.data,
            // levelsFacetoFace: res.data,
            // showStreamsF: true,
            // hideLevels: false,
          },
          () => {
            this.getFacetoFaceStreams(this.state.levels[0].id);
          }
        );
      });
  };
  getFacetoFaceStreams = (id) => {
    axios
      .get(
        Constants.getUrls.getStreamsFacetoFace +
          "?level_id=" +
          id +
          "&type=COURSE"
      )
      .then((res) => {
        this.setState({
          streams: res.data,
        });
      });
  };

  showRegister = () => {
    this.setState({
      showRegister: true,
      overlay: true,
    });
  };

  hideRegister = () => {
    this.setState({
      showRegister: false,
      overlay: false,
    });
  };

  showLogin = () => {
    this.setState({
      showLogin: true,
      overlay: true,
    });
  };

  hideLogin = () => {
    this.setState({
      showLogin: false,
      overlay: false,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleRegister = (e) => {
    e.preventDefault();
    this.setState({
      showNotif: true,
      notiMessage: "Creating account...",
      btnLoading: true,
    });
    const payLoad = {
      FIRST_NAME: this.state.firstName,
      EMAIL: this.state.email,
      mobile: this.state.mobileNo,
      PASSWORD: this.state.password,
      password_confirmation: this.state.confirmPassword,
      course: this.state.course,
    };
    axios.post(Constants.postUrls.register, payLoad).then((res) => {
      if (res.data.status === "success") {
        this.setState({
          showRegister: false,
          showLogin: true,
          userDetails: res.data.data,
          btnLoading: false,
          message: res.data.message,
        });
      } else {
        this.setState({
          showError: true,
          message: res.data.message,
          btnLoading: false,
          showNotif: false,
        });
      }
    });
  };

  handleLogin = (e) => {
    e.preventDefault();
    this.setState({
      showNotif: true,
      notiMessage: "Signing in...",
      btnLoading: true,
      showError: false,
    });
    const payload = {
      email: this.state.emailLogin,
      password: this.state.passwordLogin,
      status: "main",
    };
    axios.post(Constants.postUrls.login, payload).then((res) => {
      if (res.data.status === "success") {
        localStorage.setItem("token", res.data.token);
        if (init() === "success") {
          this.setState({
            userDetails: res.data.data,
            message: res.data.credential,
            showLogin: false,
          });
          window.location.href = "/";
        } else {
          alert("Something went wrong");
        }
      } else {
        this.setState({
          showError: true,
          message: res.data.credential,
          btnLoading: false,
          showNotif: false,
        });
      }
    });
  };

  logOut = (e) => {
    localStorage.clear();
    window.location.href = "/";
  };

  render() {
    return (
      <>
        {!this.props.From === "SubjectsResp" && (
          <div className="contact">To Know more please Call 09356624677</div>
        )}

        <div className="contact1">
          To Know more about the courses call us at - 9356624677 | For Any kind
          of Technical Support Please call us on - 8766578580 <br />
        </div>
        <div>
          {this.state.showError ? (
            <SnackbarContent message={this.state.message} color="success" />
          ) : null}

          <section>
            <div className="sideBar">
              <div>
                <img
                  src={removeIcon}
                  alt=""
                  className="removeIcon"
                  onClick={this.hideRegister}
                />
              </div>
              <form onSubmit={this.handleRegister}>
                <section className="sectionReg">
                  <div className="bottomSpace">
                    <h2 className="signUp">SIGN UP</h2>
                  </div>
                  <div className="bottomSpace">
                    <TextField
                      id="outlined-basic"
                      required
                      label="Name"
                      variant="outlined"
                      name="firstName"
                      className="name"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="bottomSpace">
                    <TextField
                      id="outlined-basic"
                      required
                      label="Email"
                      variant="outlined"
                      name="email"
                      className="name"
                      type="email"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="bottomSpace">
                    <TextField
                      id="outlined-basic"
                      required
                      label="Mobile No."
                      variant="outlined"
                      name="mobileNo"
                      className="name"
                      type="number"
                      onChange={(e) =>
                        this.setState({ mobileNo: e.target.value }, () => {
                          if (this.state.mobileNo.length > 10) {
                            this.setState({ max: true });
                          } else {
                            this.setState({ max: false });
                          }
                        })
                      }
                    />
                    {this.state.max ? (
                      <div>
                        <span style={{ color: "red" }}>
                          Max Allowed Characters : 10
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="bottomSpace">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Course
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.course}
                        label="Course"
                        name="course"
                        required
                        onChange={this.handleChange}
                      >
                        {this.state.courses.map((course) => (
                          <MenuItem value={course.value}>
                            {course.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="bottomSpace">
                    <TextField
                      id="outlined-basic"
                      required
                      label="Password"
                      variant="outlined"
                      name="password"
                      className="name"
                      type="password"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="bottomSpaceBtn">
                    <TextField
                      id="outlined-basic"
                      required
                      label="Confirm Password"
                      variant="outlined"
                      type={this.state.openEye ? "text" : "password"}
                      name="confirmPassword"
                      className="name"
                      onChange={(e) =>
                        this.setState(
                          {
                            confirmPassword: e.target.value,
                          },
                          () => {
                            if (
                              this.state.password === this.state.confirmPassword
                            ) {
                              this.setState({
                                passError: false,
                              });
                            } else {
                              this.setState({
                                passError: true,
                              });
                            }
                          }
                        )
                      }
                    />
                    {this.state.passError ? (
                      <div>
                        <span style={{ color: "red" }}>
                          Password and confirm password should be same.
                        </span>
                      </div>
                    ) : null}
                    {this.state.openEye === true ? (
                      <img
                        src={eye}
                        alt=""
                        className="eyeIcon"
                        onClick={(e) =>
                          this.setState({
                            openEye: false,
                            closeEye: true,
                          })
                        }
                      />
                    ) : (
                      <img
                        src={closeEyeImg}
                        alt=""
                        className="closeEyeIcon"
                        onClick={(e) =>
                          this.setState({
                            openEye: true,
                            closeEye: false,
                          })
                        }
                      />
                    )}
                  </div>
                  <div className="">
                    <Button
                      type="submit"
                      variant="contained"
                      className="signinBtn"
                      style={{ fontSize: "17px" }}
                    >
                      SIGN UP
                    </Button>
                  </div>
                  <div className="">
                    <h5 className="alreadyUser" style={{ color: "#2367EB" }}>
                      Already A User? <span>SIGN IN</span>{" "}
                    </h5>
                  </div>
                </section>
                {this.state.showError ? (
                  <div className="col-md-12">
                    <p style={{ color: "red" }}>
                      This Email is already registered with us. Try{" "}
                      <Link to="/forget-password">Forget Password</Link>
                    </p>
                  </div>
                ) : null}
              </form>
            </div>
            <div className="overlay"></div>
          </section>

          {this.state.showLogin === true ? (
            <section>
              <div className="sideBar">
                <div>
                  <img
                    src={removeIcon}
                    alt=""
                    className="removeIcon"
                    onClick={this.hideLogin}
                  />
                </div>
                <form onSubmit={this.handleLogin}>
                  <section className="sectionLog">
                    <div className="bottomSpace2">
                      <h2 className="signUp">SIGN IN</h2>
                    </div>
                    <div className="bottomSpace2">
                      <TextField
                        id="outlined-basic"
                        required
                        label="Email"
                        variant="outlined"
                        name="emailLogin"
                        className="name"
                        type="email"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="bottomSpaceBtnLogin">
                      <TextField
                        id="outlined-basic"
                        required
                        label="Password"
                        variant="outlined"
                        name="passwordLogin"
                        type={this.state.openEye ? "text" : "password"}
                        className="name"
                        style={{ position: "relative" }}
                        onChange={this.handleChange}
                      />
                      {this.state.openEye === true ? (
                        <img
                          src={eye}
                          alt=""
                          className="eyeIconLogin"
                          onClick={(e) =>
                            this.setState({
                              openEye: false,
                              closeEye: true,
                            })
                          }
                        />
                      ) : (
                        <img
                          src={closeEyeImg}
                          alt=""
                          className="closeEyeIcon"
                          onClick={(e) =>
                            this.setState({
                              openEye: true,
                              closeEye: false,
                            })
                          }
                        />
                      )}
                    </div>
                    {/* <div className="bottomSpaceBtn">
                      <h5 className="alreadyUser" style={{ color: "#2367EB" }}>
                        Forgot Password?
                      </h5>
                    </div> */}

                    <div className="">
                      <Button
                        type="submit"
                        variant="contained"
                        className="signinBtn"
                        style={{ fontSize: "17px" }}
                      >
                        SIGN IN
                      </Button>
                    </div>
                    <div className="">
                      <h5 className="alreadyUser" style={{ color: "#2367EB" }}>
                        SIGN UP or <span>Create Account</span>{" "}
                      </h5>
                    </div>
                  </section>
                </form>
              </div>
              <div className="overlay"></div>
            </section>
          ) : null}
          {window.innerWidth <= 360 ? (
            <div className="navHeader row">
              {" "}
              <div className="col-md-6 col-6">
                <Link to="/">
                  <img src={akLogo} alt="" className="logo" />
                </Link>
              </div>
              {this.props.From === "SubjectsResp" ? (
                <div
                  className="col-md-6  col-6"
                  onClick={() => {
                    this.setState({
                      SideNavBar: !this.state.SideNavBar,
                      firstScreen: true,
                      secondScreen: false,
                    });
                  }}
                >
                  <img
                    src={menu}
                    alt="img"
                    className="menuImg"
                    height={20}
                    width={20}
                  />
                </div>
              ) : (
                <div
                  className="col-md-6 menu col-6"
                  onClick={() => {
                    this.setState({
                      SideNavBar: !this.state.SideNavBar,
                      firstScreen: true,
                      secondScreen: false,
                    });
                  }}
                >
                  Menu{" "}
                  <img
                    src={menu}
                    alt="img"
                    className="menuImg"
                    height={20}
                    width={20}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="headerBack">
              <div className="col-md-12 mainDiv">
                <div
                  className="col-md-8 mainHeadDisplay"
                  onMouseLeave={(e) => {
                    this.setState({
                      dropDown: false,
                    });
                  }}
                >
                  <Link to="/">
                    <div className="col-md-2">
                      <img src={akLogo} alt="" className="logo" />
                    </div>
                  </Link>
                  <div
                  // className="col-md-1"
                  // style={{ position: "relative", top: "7px" }}
                  >
                    <button
                      className={
                        this.state.selectedType === "course"
                          ? "selectedTypes"
                          : "types"
                      }
                      style={{
                        width: "130px",
                        marginLeft: "-20px",
                      }}
                      onMouseOver={(e) => {
                        this.setState(
                          {
                            selectedType: "course",
                            dropDown: true,
                          },
                          () => {
                            this.getCategory(this.state.selectedType);
                          }
                        );
                      }}
                    >
                      Video Lectures
                      <span className="arrowHexHeader">&#129171;</span>
                    </button>
                  </div>
                  <div
                  // className="col-md-2"
                  // style={{ position: "relative", top: "7px", left: "20px" }}
                  >
                    <button
                      className={
                        this.state.selectedType === "facetoface"
                          ? "selectedTypes"
                          : "types"
                      }
                      onMouseOver={(e) => {
                        this.setState(
                          {
                            selectedType: "facetoface",
                            dropDown: true,
                          },
                          () => {
                            this.getCategory(this.state.selectedType);
                          }
                        );
                      }}
                    >
                      Face To Face & Live Stream
                      <span className="arrowHexHeader">&#129171;</span>
                    </button>
                  </div>
                  <div
                  // className="col-md-2"
                  // style={{ position: "relative", top: "7px" }}
                  >
                    <button
                      onClick={() => {
                        // alert();
                        window.location.href = "/testseries";
                      }}
                      className={
                        this.state.selectedType === "testseries"
                          ? "selectedTypes"
                          : "types"
                      }
                      onMouseOver={(e) => {
                        this.setState(
                          {
                            selectedType: "testseries",
                            dropDown: false,
                          },
                          () => {
                            // this.getCategory(this.state.selectedType);
                          }
                        );
                      }}
                    >
                      Test Series
                      <span className="arrowHexHeader">&#129171;</span>
                    </button>
                  </div>
                  <div
                  // className="col-md-3"
                  // style={{ position: "relative", top: "7px", right: "40px" }}
                  >
                    <button
                      onClick={() => {
                        // alert();
                        window.location.href = "/books";
                      }}
                      className={
                        this.state.selectedType === "studymaterial"
                          ? "selectedTypes"
                          : "types"
                      }
                      onMouseOver={(e) => {
                        this.setState({
                          selectedType: "studymaterial",
                          dropDown: true,
                        });
                      }}
                    >
                      Books
                      <span className="arrowHexHeader">&#129171;</span>
                    </button>
                  </div>
                  {/* {/* <div className={"col-md-1 franchisee1536"}>
                    <button
                      className={
                        this.state.selectedType === "franchisee"
                          ? "selectedTypes"
                          : "types"
                      }
                      onMouseOver={(e) => {
                        this.setState({
                          selectedType: "franchisee",
                          dropDown: true,
                        });
                      }}
                    >
                      Franchisee
                      <span className="arrowHexHeader">&#129171;</span>
                    </button>
                  </div> */}
                  {this.state.dropDown ? (
                    <div
                      className={
                        window.innerWidth === 1396
                          ? "row dropdownall1396"
                          : "row dropdownall"
                      }
                      style={{
                        marginLeft:
                          this.state.selectedType === "course"
                            ? "-20px"
                            : this.state.selectedType === "facetoface"
                            ? "270px"
                            : this.state.selectedType === "testseries"
                            ? "720px"
                            : this.state.selectedType === "studymaterial"
                            ? "955px"
                            : null,
                      }}
                    >
                      <div
                        className="col-md-12"
                        style={{
                          fontFamily: "'Raleway', sans-serif",
                          fontSize: "16px",
                        }}
                      >
                        <div className="col-md-4 categoryArea">
                          {this.state.streams.map((cat) => (
                            <div
                              className={
                                this.state.selectCat ? "cat1396" : "cat"
                                // ? this.state.categoryId === cat.ID
                                //   ? window.innerWidth === 1396
                                //     ? "catSelected1396"
                                //     : "catSelected"
                                //   : window.innerWidth === 1396
                                //   ? "cat1396"
                                //   : "cat"
                                // : window.innerWidth === 1396
                                // ? "cat1396"
                                // : "cat"
                              }
                              onMouseOver={(e) => {
                                this.setState({});
                                // this.setState(
                                //   {
                                //     selectCat: true,
                                //     categoryId: cat.ID || cat.id,
                                //     categoryType: cat.TYPE,
                                //     catName: e.target.value,
                                //     categoryname:
                                //       this.state.headerType === "facetoface"
                                //         ? cat.category
                                //         : cat.CATEGORY,
                                //   },
                                //   () => {
                                //     // if (
                                //     //   this.state.headerType === "facetoface"
                                //     // ) {
                                //     //   this.getFacetoFaceLevels(
                                //     //     this.state.categoryId
                                //     //   );
                                //     // } else {
                                //     //   this.getLevels(this.state.categoryId);
                                //     // }
                                //   }
                                // );
                              }}
                            >
                              {this.state.headerType === "facetoface" ? (
                                <Link
                                  style={{ textDecoretion: "none" }}
                                  to={
                                    "/facetoface_details/" +
                                    this.state.categoryId +
                                    "/" +
                                    this.state.levels[0].id +
                                    "/" +
                                    cat.id
                                  }
                                >
                                  {" "}
                                  <div
                                    className="col-md-12 colorDiv"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="col-md-6">
                                      <h5
                                        style={{
                                          position: "absolute",
                                          top: "6px",
                                        }}
                                      >
                                        {cat.name}
                                      </h5>
                                    </div>
                                  </div>
                                </Link>
                              ) : (
                                <Link to={"/subjects/" + cat.id}>
                                  <div
                                    className="col-md-12 colorDiv"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="col-md-6">
                                      <h5
                                        style={{
                                          position: "absolute",
                                          top: "6px",
                                        }}
                                      >
                                        {cat.name}
                                      </h5>
                                    </div>
                                  </div>
                                </Link>
                              )}
                              {/* <div className="col-md-6">
                                  <span className="arrowHexCode">
                                    &#129170;
                                  </span>
                                </div> */}
                            </div>
                          ))}
                        </div>
                        {/* <div className="col-md-8">
                          <div className="catName">
                            <h5>{this.state.categoryname}</h5>
                          </div>
                          <div
                            className={
                              window.innerWidth === 1396
                                ? "streamsArea1396"
                                : "streamsArea"
                            }
                          >
                            {this.state.headerType === "facetoface" ? (
                              <div className="col-md-12">
                                {this.state.streams.map((stream) => (
                                  <div className="col-md-4 streams">
                                    <Link
                                      to={
                                        "/facetoface_details/" +
                                        this.state.categoryId +
                                        "/" +
                                        this.state.levels[0].id +
                                        "/" +
                                        stream.id
                                      }
                                    >
                                      <h5 style={{ color: "#333" }}>
                                        {stream.name}
                                      </h5>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="col-md-12">
                                {this.state.streams.map((stream) => (
                                  <div className="col-md-4 streams">
                                    <Link to={"/subjects/" + stream.id}>
                                      <h5 style={{ color: "#333" }}>
                                        {stream.name}
                                      </h5>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-3">
                  {init() === "success" ? (
                    <>
                      <div
                        className="col-md-6 login"
                        onClick={this.showLogin}
                      ></div>
                      <Link to="/dashboard/*">
                        <div className="col-md-6">
                          <div
                            className="register"
                            style={{ textAlign: "center" }}
                          >
                            <img
                              src={profile}
                              alt="profile"
                              width="16px"
                              height="16px"
                              style={{ marginTop: "-4px" }}
                            />
                            <span style={{ marginRight: "10px" }}>
                              {" "}
                              Hi {this.state.userInfo?.FIRST_NAME}
                            </span>
                          </div>
                          {/* <button className='register' onClick={this.logOut}>
                                    Logout
                                </button> */}
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <div className="col-md-6 login" onClick={this.showLogin}>
                        Log In
                      </div>
                      <div className="col-md-6">
                        <button
                          className="register"
                          onClick={this.showRegister}
                        >
                          Register Now
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {this.state.SideNavBar ? (
            <div className="RespHeader slideFromRight">
              <img
                src={cancel}
                alt=""
                className="cancelIcon slidefromLeft"
                onClick={() => {
                  this.setState({ SideNavBar: false });
                }}
              />
              <h4
                className="backBtn"
                onClick={() => {
                  this.setState({ firstScreen: true, secondScreen: false });
                }}
              >
                {/* Back */}
              </h4>
              {this.state.firstScreen && (
                <div className="secondDivNavbar">
                  <p
                    onClick={() => {
                      this.setState({ SideNavBar: false });
                    }}
                  >
                    <a
                      href="/"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      Home
                    </a>
                  </p>
                  <p onClick={this.showLogin}>
                    Login{" "}
                    <img src={next} alt="" className="nextIcon slidefromLeft" />
                  </p>
                  <p
                    onClick={() => {
                      this.setState(
                        {
                          secondScreen: true,
                          firstScreen: false,
                          showVideoLectures: true,
                          selectedType: "course",
                        },
                        () => {
                          this.getCategory(this.state.selectedType);
                        }
                      );
                    }}
                  >
                    Video Lectures{" "}
                    <img
                      src={next}
                      alt=""
                      className="nextIcon slidefromLeft"
                      onClick={() => {
                        this.setState({ SideNavBar: false });
                      }}
                    />
                  </p>
                  <p
                    onClick={() => {
                      this.setState(
                        {
                          secondScreen: true,
                          firstScreen: false,
                          showVideoLectures: true,
                          selectedType: "facetoface",
                        },
                        () => {
                          this.getCategory(this.state.selectedType);
                        }
                      );
                    }}
                  >
                    Face to Face & Live Stream{" "}
                    <img
                      src={next}
                      alt=""
                      className="nextIcon slidefromLeft"
                      onClick={() => {
                        this.setState({ SideNavBar: false });
                      }}
                    />
                  </p>
                  <Link to="/testseries">
                    <p
                      onClick={() => {
                        this.setState({ SideNavBar: false });
                      }}
                    >
                      Test Series{" "}
                      <img
                        src={next}
                        alt=""
                        className="nextIcon slidefromLeft"
                      />
                    </p>
                  </Link>
                  <Link to="/books">
                    <p
                      onClick={() => {
                        this.setState(
                          {
                            firstScreen: false,

                            selectedType: "books",
                            SideNavBar: false,
                          },
                          () => {
                            this.getCategory(this.state.selectedType);
                          }
                        );
                      }}
                    >
                      Books{" "}
                      <img
                        src={next}
                        alt=""
                        className="nextIcon slidefromLeft"
                      />
                    </p>
                  </Link>

                  <p>
                    Register{" "}
                    <img src={next} alt="" className="nextIcon slidefromLeft" />
                  </p>
                </div>
              )}
              {this.state.secondScreen ? (
                <div>
                  {this.state.showVideoLectures ? (
                    <div>
                      {this.state.streams.map((cat) => (
                        <div
                          className="secondDivNavbar"
                          onClick={() => {
                            this.setState({
                              SideNavBar: false,
                            });
                          }}
                        >
                          {this.state.headerType === "facetoface" ? (
                            <Link
                              style={{ textDecoretion: "none" }}
                              to={
                                "/facetoface_details/" +
                                this.state.categoryId +
                                "/" +
                                this.state.levels[0].id +
                                "/" +
                                cat.id
                              }
                            >
                              <div style={{ cursor: "pointer" }}>
                                <p style={{ color: "#000" }}>{cat.name}</p>
                              </div>
                            </Link>
                          ) : (
                            <Link
                              to={"/subjects/" + cat.id}
                              style={{ textDecoretion: "none" }}
                            >
                              <div style={{ cursor: "pointer" }}>
                                <p style={{ color: "#000" }}>{cat.name}</p>
                              </div>
                            </Link>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
// export default Header;
