import React, { Component } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";

export default class ReturnPolicy extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Header />
        <div className="container">
          <div className="privacy">
            <h1>REFUND & CANCELLATION POLICY</h1>
            <h5>
              Purchase once made on akclasses.in cannot be cancelled, refunded,
              assigned or re-assigned to anyone else by the purchaser.
              Cancellation can be made only and only on account of delivery
              failure by the seller within 30 days from the committed date of
              shipment. All cancellation requests are required to be made by
              mail to: bvlhelpdesk@gmail.com The cancellation request must
              contain the following: Order reference number and Reason for
              cancellation
            </h5>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
