import Axios from "axios";
import Lottie from "lottie-react-web";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { Helmet } from "react-helmet";
import { Link, Navigate } from "react-router-dom";
import loader from "../../images/loader.json";
// import reading from "../../assets/images/reading.png";
// import running from "../../assets/images/running.png";
// import CheckoutRegitrationNotification from "../CheckoutRegitrationNotification";
// import CustomBanner from "../CustomBanner";
import Constants from "../../components/common/Constants";
import init from "../../components/helper/WindowToken";
import webAkLogo from "../../images/AK-logo1.png";
import check from "../../images/check.png";
import closeEyeImg from "../../images/closeEyeImg.png";
import eye from "../../images/eyeImg.png";
window.telCode = "+91";
window.mobile = "";
window.fName = "";
window.name = "";
window.email = "";
window.social_token = "";
window.otpView = false;
window.otpVerified = false;
window.payLoad = {
  fname: "",
  lname: "",
  city: "",
  state: "",
  visible: false,
  warn1: false,
  warn2: false,
  mobile: "",
  address: 0,
  discount: 0,
  taxAmount: 0,
  coupon: 0,
  pincode: "",
  email: "",
  subTotal: 0,
  couponId: 0,
  payMode: "",
  DeliveryCharge: "",
  cartData: [],
  pincodeList: [],
  shippingAddress: [],
  cartId: 0,
  grand_total: 0,
  extendproductPrice: "",
  last_name: "",
  coupon: 0,
  attempt: 0,
  product_detail_for_order: {},
  streamId: 0,
  levelId: 0,
  categoryId: 0,
  facetofaceId: 0,
  subjectId: 0,
  openEye: false,
};
export default class FacetoFaceCheckOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // cartdetails: this.props.cartData,
      sub_total: 0,
      redirect: false,
      showButtons: true,
      media: [],
      os: [],
      view: [],
      sampleArray: [],
      login: false,
      courses: [
        { label: "CA Foundation", value: "CA Foundation" },
        { label: "CA Inter", value: "CA Inter" },
        { label: "Ca Final", value: "Ca Final" },
      ],
      course: "",
      regForm: true,
      userdetails: [],
      userDetails: this.props.userDetails,
      coupon: "",
      discount: 0,
      contact_no: "",
      cartId: 0,
      address_2: "",
      last_name: "",
      first_name: "",
      mobile: "",
      email: this.props.userDetails.email,
      address_1: "",
      city: "",
      pincode: "",
      state: "",
      product_details: [],
      add_1: "",
      add_2: "",
      quantity: [],
      orderId: [],
      shippingAddress: [],
      shipping: 0,
      name: "",
      total: 0,
      FIRST_NAME: this.props.userDetails.FIRST_NAME,
      EMAIL: "",
      PASSWORD: "",
      password_confirmation: "",
      redirectLogin: false,
      register: [],
      redirectToaddress: false,
      mediaArray: [],
      viewArray: [],
      showAddressForm: false,
      product: {},
      product_detail_for_order: {},
      base_total: 0,
      grand_total: 0,
      coupon_amount: 0,
      showLogin: true,
      btnLoading: false,
      showNotif: false,
      notiMessage: "",
      attempt: "",
      showError: false,
      extendproductPrice: "",
      extendProductName: "",
      showshippingForm: false,
      showLoginForm: false,
      editAdd: false,
      success: false,
      stateM: "",
      cId: 0,
      oData: {},
      ProdctName: "",
      batchId: 0,
      batchWingId: 0,
      ftfData: {},
      fromScreen: "",
      categoryId: 0,
      levelId: 0,
      streamId: 0,
      facetofaceId: 0,
    };
  }
  componentDidMount() {
    console.log(localStorage.token);
    console.log(this.props);
    const props = this.props;
    console.log(props);
    if (!props) {
      window.location.href = "/";
    }
    if (
      this.props.userDetails.ADDRESS_1 == null &&
      this.props.userDetails.ADDRESS_2 == null &&
      localStorage.getItem("added") === "false"
    ) {
      this.setState({ showButtons: false, showAddressForm: true });
    }
    this.setState(
      {
        fromScreen: props.from,
        ftfData: props.data,
        categoryId: props.data.category_id,
        levelId: props.data.level_id,
        streamId: props.data.stream_id,
        grand_total: parseInt(props.data.fees),
        facetofaceId: props.data.id,
        address_1: props.userDetails.ADDRESS_1,
        address_2: props.userDetails.ADDRESS_2,
        city: props.userDetails.CITY,
        stateM: props.userDetails.STATE,
        pincode: props.userDetails.PINCODE,
        mobile: props.userDetails.MOBILE,
        subjectId: props.subjectId,
        ProdctName: props.data.city,
      },
      () => {
        window.payLoad.grand_total = this.state.grand_total;
        window.payLoad.categoryId = this.state.categoryId;
        window.payLoad.levelId = this.state.levelId;
        window.payLoad.streamId = this.state.streamId;
        window.payLoad.facetofaceId = this.state.facetofaceId;
        window.payLoad.subjectId = this.state.subjectId;
        window.payLoad.city = this.state.city;
        window.payLoad.stateM = this.state.stateM;
        window.payLoad.pincode = this.state.pincode;
        console.log("ftf", window.payLoad.streamId);
      }
    );
    if (localStorage.token) {
      this.setState({
        login: true,
      });
    }
  }
  closeModal() {
    this.setState({
      paymentError: false,
    });
  }
  componentDidUpdate(nextProps) {
    if (this.props.userDetails !== nextProps.userDetails) {
      this.setState(
        {
          userDetails: nextProps.userDetails,
          FIRST_NAME: this.props.userDetails.FIRST_NAME,
          LAST_NAME: this.props.userDetails.LAST_NAME,
          EMAIL: this.props.userDetails.email,
        },
        () => {
          this.setState({
            name: this.props.userDetails.FIRST_NAME,
            email: this.props.userDetails.email,
            mobile: parseInt(this.props.userDetails.MOBILE),
            city: this.props.userDetails.CITY,
            stateM: this.props.userDetails.STATE,
            address_1: this.props.userDetails.ADDRESS_1,
            address_2: this.props.userDetails.ADDRESS_2,
            pincode: parseInt(this.props.userDetails.PINCODE),
          });
        }
      );
    }
  }

  registerUser = (e) => {
    if (
      this.state.showCorrectName ||
      this.state.showCorrectEmail ||
      this.state.showCorrectCity ||
      this.state.mobilevalidate ||
      this.state.showCorrectState ||
      this.state.showCorrectPincode
    ) {
      e.preventDefault();
      this.setState({
        btnLoading: true,
        showNotif: true,
        notiMessage: "Creating Account...",
        showError: false,
      });
      const payLoad = {
        FIRST_NAME: this.state.FIRST_NAME,
        EMAIL: this.state.EMAIL,
        course: this.state.course,
        PASSWORD: this.state.PASSWORD,
        password_confirmation: this.state.password_confirmation,
      };
      console.log(payLoad);
      Axios.post(Constants.postUrls.register, payLoad).then((res) => {
        if (res.data.status === "success") {
          this.setState({
            register: res.data.status,
          });
          e.preventDefault();
          this.setState({
            btnLoading: true,
            showNotif: true,
            notiMessage: "Signing in...",
            showError: false,
          });
          const payload = {
            email: this.state.EMAIL,
            password: this.state.PASSWORD,
            status: "main",
          };
          Axios.post(Constants.postUrls.login, payload).then((res) => {
            if (res.data.status === "success") {
              this.setState({
                logindata: res.data.data,
              });
              localStorage.setItem("token", res.data.token);

              if (init() === "success") {
                this.setState({
                  login: true,
                  btnLoading: false,
                });
                this.props.onUpdateUser();

                e.preventDefault();
                this.setState({
                  btnLoading: true,
                  showNotif: true,
                  notiMessage: "Adding address...",
                });
                const payload = {
                  name: this.state.FIRST_NAME,
                  first_name: this.state.FIRST_NAME,
                  last_name: "",
                  email: this.state.EMAIL,
                  address_1: this.state.address_1,
                  address_2: this.state.address_2,
                  city: this.state.city,
                  pincode: this.state.pincode,
                  state: this.state.state,
                  mobile: this.state.mobile,
                  shippingAddress: this.state.shippingAddress,
                };
                Axios.post(Constants.postUrls.updateAddress, payload).then(
                  (res) => {
                    console.log("newadd", res.data);
                    if (res.data.status === "success") {
                      this.setState(
                        {
                          shippingAddress: res.data,
                          newAdd: res.data.data,
                        },
                        () => {
                          window.payLoad.shippingAddress =
                            this.state.shippingAddress;
                        }
                      );
                      if (
                        this.state.newAdd.email &&
                        this.state.newAdd.ADDRESS_1
                      ) {
                        console.log(this.state.grand_total);
                        if (this.state.grand_total != 0) {
                          Axios.post(Constants.postUrls.createOrderId, {
                            amount: (this.state.grand_total * 100).toString(),
                          }).then((resp) => {
                            this.setState(
                              {
                                orderId: resp.data,
                              },
                              () => {
                                console.log(this.state.orderId);
                                let options = {
                                  key: "rzp_live_rCkJJMueXcoaZV",
                                  amount: this.state.grand_total,
                                  name: "Ak Classes",
                                  order_id: this.state.orderId,
                                  description: "Ak Classes",
                                  image: webAkLogo,
                                  handler: function (response) {
                                    console.log(response);
                                    let token = localStorage.getItem("token");
                                    console.log(token);
                                    Axios.get(Constants.getUrls.user, {
                                      headers: {
                                        Authorization: "Bearer " + token,
                                      },
                                    }).then((resp) => {
                                      let winLoad = {
                                        flag: "Website",
                                        order_no: response.razorpay_order_id,
                                        payment_id:
                                          response.razorpay_payment_id,
                                        signature: response.razorpay_signature,
                                        contact_no: parseInt(resp.data.MOBILE),
                                        address:
                                          window.payLoad.shippingAddress ===
                                          "selected"
                                            ? window.payLoad.shippingAddress
                                            : resp.data.ADDRESS_1,
                                        cart: window.payLoad.cartId,
                                        address_2: resp.data.ADDRESS_2,
                                        grand_total: window.payLoad.grand_total
                                          ? window.payLoad.grand_total === null
                                            ? 0
                                            : window.payLoad.grand_total
                                          : window.payLoad
                                              .extendproductPrice === null
                                          ? 0
                                          : window.payLoad.extendproductPrice,
                                        last_name: resp.data.last_name,
                                        first_name: resp.data.FIRST_NAME,
                                        mobile: parseInt(resp.data.MOBILE),
                                        email: resp.data.email,
                                        state: resp.data.STATE,

                                        city: resp.data.CITY,
                                        pincode: parseInt(resp.data.PINCODE),
                                        country: "INDIA",
                                        coupon: window.payLoad.coupon,
                                        attempt: window.payLoad.attempt,
                                        shipping: 0,
                                        base_total: window.payLoad.grand_total
                                          ? window.payLoad.grand_total === null
                                            ? 0
                                            : window.payLoad.grand_total
                                          : window.payLoad
                                              .extendproductPrice === null
                                          ? 0
                                          : window.payLoad.extendproductPrice,
                                        product_details: [
                                          window.payLoad
                                            .product_detail_for_order,
                                        ],
                                      };
                                      Axios.post(
                                        Constants.postUrls.storeOrder,
                                        winLoad
                                      ).then((res) => {
                                        console.log(res);
                                        if (res.data.status === "success") {
                                          window.location.href =
                                            "/payment-success";
                                          Axios.post(
                                            Constants.postUrls.clearCart
                                          ).then((resp) => {
                                            console.log(resp);
                                          });
                                        }
                                      });
                                    });
                                  },
                                  prefill: {
                                    email: window.payLoad.email,
                                    contact: "91" + window.payLoad.mobile,
                                  },
                                  theme: {
                                    color: "#0f66b9",
                                  },
                                };
                                let rzp = new window.Razorpay(options);
                                rzp.open();
                              }
                            );
                          });
                        } else {
                          window.location.href = "/order-placed";
                        }
                      } else {
                        alert("Address or Email missing");
                      }
                    }
                  }
                );
              }
            } else {
              this.setState({
                showError: true,
                btnLoading: false,
              });
            }
          });
        } else {
          this.setState({
            showError: true,
            btnLoading: false,
            showNotif: false,
          });
        }
      });
    } else {
      alert("Enter Valid Credentials");
    }
  };

  loginUser = (e, from) => {
    e.preventDefault();
    this.setState({
      btnLoading: true,
      showNotif: true,
      notiMessage: "Signing in...",
      showError: false,
    });
    const payload = {
      email: this.state.EMAIL,
      password: this.state.PASSWORD,
      status: "main",
    };
    Axios.post(Constants.postUrls.login, payload).then((res) => {
      if (res.data.status === "success") {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("login", res.data);

        if (init() === "success") {
          this.setState({
            login: true,
            btnLoading: false,
          });
          this.props.onUpdateUser();
          if (from === "register") {
            this.addUserAddress(e);
          } else {
            window.location.reload();
          }
        }
      } else {
        this.setState({
          showError: true,
          btnLoading: false,
          showNotif: from !== "register" ? false : true,
        });
      }
    });
  };
  addUserAddress = (e) => {
    e.preventDefault();
    this.setState({
      btnLoading: true,
      showNotif: true,
      notiMessage: "Adding address...",
    });
    console.log(this.props.userDetails);
    const payload = {
      name: this.state.FIRST_NAME,
      first_name: this.state.FIRST_NAME,
      last_name: "",
      email: this.props.userDetails.email,
      address_1: this.state.address_1,
      address_2: this.state.address_2,
      city: this.state.city,
      pincode: this.state.pincode,
      state: this.state.state,
      mobile: this.state.mobile,
      shippingAddress: this.state.shippingAddress,
    };
    console.log(payload);
    Axios.post(Constants.postUrls.updateAddress, payload).then((res) => {
      console.log(res);
      localStorage.setItem("added", "true");
      window.location.reload();
      this.setState(
        {
          shippingAddress: res.data,
          showButtons: true,
        },
        () => {
          window.payLoad.shippingAddress = this.state.shippingAddress;
        }
      );
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  postCoupon = () => {
    const payload = {
      coupon: parseInt(this.state.coupon),
    };
    Axios.post(Constants.postUrls.coupon, payload).then((res) => {
      this.setState(
        {
          discount: res.data.Discount,
          grand_total: parseInt(this.state.sub_total - this.state.discount),
        },
        () => {
          window.payLoad.coupon = parseInt(this.state.coupon);
        }
      );
    });
  };

  placeOrder = (e) => {
    console.log(window.payLoad.grand_total);
    if (init() === "success") {
      if (this.state.grand_total != 0) {
        Axios.post(Constants.postUrls.createOrderId, {
          amount: (this.state.grand_total * 100).toString(),
        }).then((resp) => {
          console.log("orderId", resp);
          this.setState(
            {
              orderId: resp.data,
            },
            () => {
              console.log(this.state.orderId);
              let options = {
                //   key:'rzp_test_ZxSPxDjStZOlPm',
                key: "rzp_live_rCkJJMueXcoaZV",
                amount: this.state.grand_total,
                name: "Ak Classes",
                order_id: this.state.orderId,
                description: "Ak Classes",
                image: webAkLogo,
                handler: function (response) {
                  console.log(response);
                  let token = localStorage.getItem("token");
                  console.log(token);
                  Axios.get(Constants.getUrls.user, {
                    headers: {
                      Authorization: "Bearer " + token,
                    },
                  }).then((resp) => {
                    console.log("userData", resp);
                    let winLoad = {
                      order_no: response.razorpay_order_id,
                      payment_id: response.razorpay_payment_id,
                      signature: response.razorpay_signature,

                      address:
                        window.payLoad.shippingAddress === "selected"
                          ? window.payLoad.shippingAddress
                          : resp.data.ADDRESS_1,

                      address_2: resp.data.ADDRESS_2,
                      category_id: window.payLoad.categoryId,
                      level_id: window.payLoad.levelId,
                      stream_id: window.payLoad.streamId,
                      facetoface_id: window.payLoad.facetofaceId,
                      subject_id: window.payLoad.subjectId,
                      grand_total: window.payLoad.grand_total,
                      pincode: parseInt(resp.data.PINCODE),
                      city: resp.data.CITY,
                      state: resp.data.STATE,
                      country: "INDIA",
                    };
                    console.log(winLoad);
                    Axios.post(Constants.postUrls.faceTofaceOrderPlace, winLoad)
                      .then((res) => {
                        console.log("orderPlace", res);
                        if (res.data.status === "success") {
                          window.location.href = "/payment-success";
                          this.setState({
                            redirect: true,
                          });
                        } else {
                          console.log("failed");
                          this.setState({
                            paymentError: true,
                          });
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        if (err) {
                          console.log(err.response);
                        }
                      });
                  });
                },
                prefill: {
                  email: window.payLoad.email,
                  contact: "91" + window.payLoad.mobile,
                },
                theme: {
                  color: "#0f66b9",
                },
              };
              let rzp = new window.Razorpay(options);
              rzp.open();
            }
          );
        });
      } else {
        window.location.href = "/order-placed";
      }
    }
  };

  showshippingForm() {
    this.setState({
      showshippingForm: !this.state.showshippingForm,
    });
  }
  showLogin() {
    this.setState({
      showLoginForm: !this.state.showLoginForm,
    });
  }
  EditAddress() {
    this.setState({
      editAdd: !this.state.editAdd,
    });
  }
  onUpdate = () => {
    this.setState({
      btnLoading: true,
    });
    let userToken = localStorage.getItem("token");
    const payload = {
      name: this.props.userDetails.FIRST_NAME,
      email: this.props.userDetails.email,
      mobile: this.state.mobile,
      city: this.state.city,
      state: this.state.stateM,
      address_1: this.state.address_1,
      address_2: this.state.address_2,
      pincode: this.state.pincode,
    };
    console.log(payload);
    Axios.post(Constants.postUrls.updateAddress, payload, {
      headers: { Authorization: "Bearer " + userToken },
    }).then((res) => {
      console.log(res);
      if (res.data.status === "success") {
        this.setState({
          btnLoading: false,
        });
        window.location.reload();
      }
    });
  };
  validateName = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        name: text,
        showCorrectName: false,
        showNameError: true,
      });
      return false;
    } else {
      this.setState({
        name: text,
        showCorrectName: true,
        showNameError: false,
      });
      console.log("Name is Correct");
    }
  };
  validateEmail = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      this.setState({
        email: text,
        showCorrectEmail: false,
        showEmailError: true,
      });
      return false;
    } else {
      this.setState({
        email: text,
        showCorrectEmail: true,
        showEmailError: false,
      });
      console.log("Email is Correct");
    }
  };

  validateMobile(text) {
    const reg = /^[0]?[789]\d{9}$/;
    if (reg.test(text) === false) {
      this.setState({
        mobilevalidate: false,
        mobileNumber: text,
        showmobileError: true,
      });
      return false;
    } else {
      this.setState({
        mobilevalidate: true,
        mobileNumber: text,
        message: "",
        showmobileError: false,
      });
      return true;
    }
  }
  validateCity = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        city: text,
        showCorrectCity: false,
        showCityError: true,
      });
      return false;
    } else {
      this.setState({
        city: text,
        showCorrectCity: true,
        showCityError: false,
      });
      console.log("Name is Correct");
    }
  };
  validateState = (text) => {
    console.log(text);
    var reg = /^[A-Za-z ]+$/;
    if (reg.test(text) === false) {
      console.log("Name is Not Correct");
      this.setState({
        stateM: text,
        showCorrectState: false,
        showStateError: true,
      });
      return false;
    } else {
      this.setState({
        stateM: text,
        showCorrectState: true,
        showStateError: false,
      });
      console.log("Name is Correct");
    }
  };
  validatePincode(text) {
    const reg = /^[0]?[789]\d{5}$/;
    if (reg.test(text) === false) {
      this.setState(
        {
          showCorrectPincode: false,
          pincode: text,
          showPincodeError: true,
        },
        () => {
          console.log(this.state.showCorrectPincode);
        }
      );
      return false;
    } else {
      this.setState(
        {
          showCorrectPincode: true,
          pincode: text,
          showPincodeError: false,
        },
        () => {
          console.log(this.state.showCorrectPincode);
        }
      );
      return true;
    }
  }
  render() {
    if (this.state.redirect) {
      return (
        <Navigate
          to={{
            pathname: "/dashboard",
            orderId: this.state.orderId,
          }}
        />
      );
    } else if (this.state.redirectToaddress) {
      return <Navigate to={{ pathname: "/userdetails" }} />;
    } else if (this.state.success) {
      return (
        <Navigate
          to={{
            pathname: "/order-detail/" + this.state.cId,
            state: { data: this.state.oData },
          }}
        />
      );
    } else {
      return (
        <div>
          <section className="section1">
            <h1 className="fonts2">Checkout</h1>
            {/* <h1 className="fonts">fill your details and place your order</h1> */}
          </section>
          {/* <Helmet>
            <title>AK Classes | Checkout</title>
            <meta name="description" content="" />
          </Helmet> */}
          {/* <CustomBanner
            title="Checkout"
            leftImage={reading}
            rightImage={running}
          /> */}

          <section className="section-b-space" style={{ paddingTop: "16px" }}>
            <div className="container padding-cls">
              <div className="checkout-page">
                <div className="checkout-form">
                  <div className="checkout">
                    {this.state.login ? (
                      <div className="row">
                        <div className="col-lg-6 col-sm-12 col-xs-12">
                          <div className="checkout-title">
                            <div className="stripe-section">
                              <div className="checkout-title">
                                <h3>Address</h3>
                              </div>
                              {this.props.userDetails.ADDRESS_1 ? (
                                <div className="row">
                                  <div className="col-md-10">
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "#000",
                                      }}
                                    >
                                      {this.props.userDetails
                                        ? this.props.userDetails.FIRST_NAME
                                          ? this.props.userDetails.FIRST_NAME
                                          : "Address Not Found"
                                        : null}
                                    </p>
                                    {this.props.userDetails
                                      ? this.props.userDetails.ADDRESS_1
                                        ? this.props.userDetails.ADDRESS_1
                                        : "Address Not Found"
                                      : null}
                                    <br />
                                    {this.props.userDetails
                                      ? this.props.userDetails.ADDRESS_2
                                        ? this.props.userDetails.ADDRESS_2
                                        : "Address Not Found"
                                      : null}
                                    <br />
                                    {this.props.userDetails
                                      ? this.props.userDetails.CITY
                                      : null}
                                    ,
                                    {this.props.userDetails
                                      ? this.props.userDetails.STATE
                                      : null}
                                    <br />
                                    Mobile:
                                    {this.props.userDetails
                                      ? this.props.userDetails.MOBILE
                                      : null}
                                    <br />
                                    Pincode:
                                    {this.props.userDetails
                                      ? this.props.userDetails.PINCODE
                                      : null}
                                  </div>
                                  <div className="col-md-2">
                                    <img alt="check" src={check} width="64px" />
                                  </div>
                                </div>
                              ) : null}
                              {this.state.showAddressForm ? (
                                <div className="">
                                  <form onSubmit={this.addUserAddress}>
                                    <div className="row check-out">
                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <label className="field-label">
                                          {" "}
                                          Street Address 1
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="address_1"
                                          value={this.state.address_1}
                                          required
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                        />
                                      </div>
                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <label className="field-label">
                                          {" "}
                                          Street Address 2
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="address_2"
                                          value={this.state.address_2}
                                          required
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                        />
                                      </div>
                                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label className="field-label">
                                          Town / City{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="city"
                                          value={this.state.city}
                                          required
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                        />
                                      </div>
                                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label className="field-label">
                                          Pincode{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="pincode"
                                          value={this.state.pincode}
                                          required
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                        />
                                      </div>
                                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label className="field-label">
                                          State{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="state"
                                          value={this.state.state}
                                          required
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                        />
                                      </div>
                                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                        <label className="field-label">
                                          Phone{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="mobile"
                                          value={this.state.mobile}
                                          required={true}
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                        />
                                      </div>

                                      <button
                                        className="btn btn-solid"
                                        type="submit"
                                        style={{ marginLeft: "16px" }}
                                      >
                                        {this.state.btnLoading ? (
                                          <Lottie
                                            options={{
                                              animationData: loader,
                                            }}
                                            style={{
                                              width: "50px",
                                              height: "30px",
                                            }}
                                          />
                                        ) : (
                                          "Add Address"
                                        )}
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              ) : null}
                            </div>
                            {this.props.userDetails.ADDRESS_2 ? null : null}
                            {this.state.showButtons ? (
                              <div>
                                <button
                                  onClick={() => {
                                    this.setState({ showshippingForm: false });
                                    this.EditAddress();
                                  }}
                                  className="btn btn-solid"
                                  style={{ marginRight: "10px" }}
                                >
                                  {" "}
                                  Edit Address{" "}
                                </button>

                                <button
                                  className="btn btn-solid"
                                  onClick={() => {
                                    this.setState({ editAdd: false });
                                    this.showshippingForm();
                                  }}
                                >
                                  Add Another Address{" "}
                                </button>
                              </div>
                            ) : null}

                            {this.state.showshippingForm ? (
                              <form onSubmit={this.addUserAddress}>
                                <div className="row check-out checkoutpageLogin">
                                  <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                    <label className="field-label">
                                      {" "}
                                      Street Address 1
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="address_1"
                                      value={this.state.address_1}
                                      required
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>
                                  <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                    <label className="field-label">
                                      {" "}
                                      Street Address 2
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="address_2"
                                      value={this.state.address_2}
                                      required
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>
                                  <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <label className="field-label">
                                      Town / City{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="city"
                                      value={this.state.city}
                                      required
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>
                                  <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <label className="field-label">
                                      Pincode{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="pincode"
                                      value={this.state.pincode}
                                      required
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>
                                  <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <label className="field-label">
                                      State{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="stateM"
                                      value={this.state.stateM}
                                      required
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>
                                  <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <label className="field-label">
                                      Phone{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="mobile"
                                      value={this.state.mobile}
                                      required={true}
                                      onChange={(e) => {
                                        this.handleInput(e);
                                      }}
                                    />
                                  </div>

                                  <button
                                    className="btn btn-solid deliverButton"
                                    type="submit"
                                    style={{ marginLeft: "16px" }}
                                  >
                                    {this.state.btnLoading ? (
                                      <Lottie
                                        options={{
                                          animationData: loader,
                                        }}
                                        style={{
                                          width: "50px",
                                          height: "30px",
                                        }}
                                      />
                                    ) : (
                                      "Deliver To This Address"
                                    )}
                                  </button>
                                </div>
                              </form>
                            ) : null}
                            {this.state.editAdd ? (
                              <div>
                                <div className="form-group">
                                  <label htmlFor="review">Street Address</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="address_1"
                                    defaultValue={this.state.address_1}
                                    required="true"
                                    onChange={(e) => {
                                      this.handleInput(e);
                                    }}
                                    style={{ borderRadius: "8px" }}
                                  />
                                </div>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="address_2"
                                  defaultValue={this.state.address_2}
                                  required=""
                                  onChange={(e) => {
                                    this.handleInput(e);
                                  }}
                                  style={{ borderRadius: "8px" }}
                                />
                                <div className="form-group">
                                  <label htmlFor="review">Town / City </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="city"
                                    defaultValue={this.state.city}
                                    required=""
                                    onChange={(e) => {
                                      this.handleInput(e);
                                    }}
                                    style={{ borderRadius: "8px" }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="review">Pincode </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pincode"
                                    defaultValue={this.state.pincode}
                                    required=""
                                    onChange={(e) => {
                                      this.handleInput(e);
                                    }}
                                    style={{ borderRadius: "8px" }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="review">State </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="stateM"
                                    defaultValue={this.state.stateM}
                                    required=""
                                    onChange={(e) => {
                                      this.handleInput(e);
                                    }}
                                    style={{ borderRadius: "8px" }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="review">Phone </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="mobile"
                                    defaultValue={this.state.mobile}
                                    required=""
                                    onChange={(e) => {
                                      this.handleInput(e);
                                    }}
                                    style={{ borderRadius: "8px" }}
                                  />
                                </div>
                                <button
                                  className="btn btn-solid"
                                  onClick={() => {
                                    this.onUpdate();
                                  }}
                                >
                                  {this.state.btnLoading ? (
                                    <Lottie
                                      options={{
                                        animationData: loader,
                                      }}
                                      style={{
                                        width: "50px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    " Update"
                                  )}
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 col-xs-12">
                          <div className="checkout-details">
                            <div className="order-box">
                              <div className="title-box">
                                <div
                                  className="row"
                                  style={{ marginBottom: "24px" }}
                                >
                                  <div className="col-md-7 col-xs-7">
                                    Product
                                  </div>

                                  <div className="col-md-5 col-xs-5">
                                    <span>Total</span>
                                  </div>
                                </div>
                                <ul className="sub-total row">
                                  <div className="col-md-7 col-xs-7">
                                    <li>
                                      <span>{this.state.ProdctName}</span>
                                    </li>
                                  </div>
                                  <div className="col-md-5 col-xs-5">
                                    <li>
                                      <span className="count">
                                        ₹ {this.state.grand_total}
                                      </span>
                                    </li>
                                  </div>
                                </ul>
                              </div>
                              <ul className="sub-total row">
                                <div className="col-md-7 col-xs-7">
                                  <li>Subtotal </li>
                                </div>
                                <div className="col-md-5 col-xs-5">
                                  <li>
                                    <span className="count">
                                      {" "}
                                      ₹ {this.state.grand_total}
                                    </span>
                                  </li>
                                </div>
                                <div className="col-md-7 col-xs-7">
                                  <li>Shipping Charges</li>
                                </div>
                                <div className="col-md-5 col-xs-5">
                                  <li>
                                    <span className="count">
                                      {" "}
                                      ₹ {this.state.shipping}
                                    </span>
                                  </li>
                                </div>
                                <div className="col-md-7 col-xs-7">
                                  <li>Discount Amount </li>
                                </div>
                                <div className="col-md-5 col-xs-5">
                                  <li>
                                    <span className="count">
                                      {" "}
                                      ₹ {this.state.coupon_amount}
                                    </span>
                                  </li>
                                </div>
                              </ul>
                              <ul className="total row">
                                <div className="col-md-7 col-xs-7">
                                  <li>Total </li>
                                </div>
                                <div className="col-md-5 col-xs-5">
                                  <li>
                                    <span className="count">
                                      {" "}
                                      ₹ {this.state.grand_total}
                                    </span>
                                  </li>
                                </div>
                              </ul>
                              <button
                                onClick={() => {
                                  this.placeOrder();
                                }}
                                className="btn btn-solid"
                              >
                                {"Place Order "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="row">
                          {this.state.showLogin ? (
                            <div className="col-sm-6 col-md-6">
                              <div className="">
                                <h4
                                  style={{
                                    color: "white",
                                    background: "#00abed",
                                    padding: "10px",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    this.showLogin();
                                  }}
                                >
                                  Already Registered ? Click here to login
                                </h4>
                                <div
                                  ref={(el) => {
                                    this.mainContent = el;
                                  }}
                                ></div>
                                {this.state.showLoginForm ? (
                                  <div
                                    style={{
                                      background: "#fff",

                                      padding: "25px",
                                      boxShadow:
                                        "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                                      marginBottom: "20px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div className="checkout-title">
                                      <div className="row">
                                        <p
                                          className="h3Login"
                                          style={{
                                            fontSize: "13px",
                                            marginLeft: "25px",
                                          }}
                                        >
                                          If you have shopped with us before,
                                          please enter your details below. If
                                          you are a new customer, please proceed
                                          to the Billing section.
                                        </p>
                                      </div>
                                    </div>
                                    <form
                                      onSubmit={(e) => {
                                        this.loginUser(e, "login");
                                      }}
                                    >
                                      <div className="check-out checkoutpageLogin">
                                        <div className="">
                                          <div className="row">
                                            <div className="form-group inputWidth col-sm-6 col-md-12">
                                              <label className="field-label">
                                                Email or Username{" "}
                                                <span style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                type="email"
                                                className="form-control"
                                                name="EMAIL"
                                                placeholder="Email"
                                                required
                                                value={this.state.EMAIL}
                                                onChange={(e) => {
                                                  this.handleInput(e);
                                                }}
                                                style={{ borderRadius: "7px" }}
                                              />
                                            </div>
                                            <div className="form-group inputWidth col-sm-6 col-md-12">
                                              <label className="field-label">
                                                Password{" "}
                                                <span style={{ color: "red" }}>
                                                  {" "}
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                type="password"
                                                className="form-control"
                                                name="PASSWORD"
                                                placeholder="Enter your password"
                                                required
                                                onChange={(e) => {
                                                  this.handleInput(e);
                                                }}
                                                style={{ borderRadius: "7px" }}
                                              />
                                            </div>
                                          </div>
                                          {this.state.showError ? (
                                            <div>
                                              <p style={{ color: "red" }}>
                                                Your credentials are invalid.
                                              </p>
                                            </div>
                                          ) : null}
                                          <div className="loginButton">
                                            <button
                                              className="btn btn-solid "
                                              type="submit"
                                            >
                                              {this.state.btnLoading ? (
                                                <Lottie
                                                  options={{
                                                    animationData: loader,
                                                  }}
                                                  style={{
                                                    width: "50px",
                                                    height: "30px",
                                                  }}
                                                />
                                              ) : (
                                                "Login"
                                              )}
                                            </button>
                                            <p style={{ marginTop: "10px" }}>
                                              Forgot password?{" "}
                                              <Link to={"/forget-password"}>
                                                {" "}
                                                Click here
                                              </Link>
                                            </p>
                                            <p style={{ marginTop: "10px" }}>
                                              Want To Register?{" "}
                                              <span
                                                onClick={() => {
                                                  this.setState({
                                                    showLoginForm: false,
                                                    regForm: true,
                                                  });
                                                }}
                                                style={{
                                                  color: "blue",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {" "}
                                                Click here
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : null}
                              </div>
                              {this.state.regForm ? (
                                <div
                                  className=""
                                  style={{
                                    background: "#fff",

                                    padding: "25px",
                                    boxShadow:
                                      "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                                  }}
                                >
                                  <div className="checkout-title">
                                    <div className="row">
                                      <div className="col-md-7">
                                        <h3>Billing and Shipping</h3>
                                      </div>
                                    </div>
                                  </div>
                                  <form
                                    onSubmit={(e) => {
                                      this.registerUser(e);
                                    }}
                                  >
                                    <div className="">
                                      <div className="row check-out">
                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                          <div className="field-label">
                                            Name{" "}
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            name="FIRST_NAME"
                                            value={this.state.FIRST_NAME}
                                            required
                                            onChange={(e) => {
                                              this.setState(
                                                { FIRST_NAME: e.target.value },
                                                () => {
                                                  this.validateName(
                                                    this.state.FIRST_NAME
                                                  );
                                                }
                                              );
                                            }}
                                            style={{ borderRadius: "5px" }}
                                          />
                                          {this.state.showNameError &&
                                          this.state.FIRST_NAME.length > 0 ? (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: 10,
                                              }}
                                            >
                                              Enter valid name
                                            </p>
                                          ) : null}
                                        </div>
                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                          <label className="field-label">
                                            {" "}
                                            Street address{" "}
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="address_1"
                                            value={this.state.address_1}
                                            required
                                            onChange={(e) => {
                                              this.handleInput(e);
                                            }}
                                            placeholder="House number and street name"
                                            style={{ borderRadius: "5px" }}
                                          />
                                        </div>
                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="address_2"
                                            required={true}
                                            placeholder="Apartment ,suite ,unit etc.(optional)"
                                            value={this.state.address_2}
                                            onChange={(e) => {
                                              this.handleInput(e);
                                            }}
                                            style={{ borderRadius: "5px" }}
                                          />
                                        </div>

                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                          <label className="field-label">
                                            Town / City{" "}
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="city"
                                            value={this.state.city}
                                            required
                                            onChange={(e) => {
                                              this.setState(
                                                { city: e.target.value },
                                                () => {
                                                  this.validateCity(
                                                    this.state.city
                                                  );
                                                }
                                              );
                                            }}
                                            style={{ borderRadius: "5px" }}
                                          />
                                          {this.state.showCityError &&
                                          this.state.city.length > 0 ? (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: 10,
                                              }}
                                            >
                                              Enter valid City Name
                                            </p>
                                          ) : null}
                                        </div>
                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                          <label className="field-label">
                                            Pincode{" "}
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="pincode"
                                            value={this.state.pincode}
                                            required
                                            onChange={(e) => {
                                              this.setState(
                                                { pincode: e.target.value },
                                                () => {
                                                  this.validatePincode(
                                                    this.state.pincode
                                                  );
                                                }
                                              );
                                            }}
                                            style={{ borderRadius: "5px" }}
                                          />
                                          {this.state.showPincodeError ? (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: 10,
                                              }}
                                            >
                                              Enter valid Pincode
                                            </p>
                                          ) : null}
                                        </div>
                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                          <label className="field-label">
                                            State{" "}
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="state"
                                            value={this.state.state}
                                            required
                                            onChange={(e) => {
                                              this.setState(
                                                { state: e.target.value },
                                                () => {
                                                  this.validateState(
                                                    this.state.state
                                                  );
                                                }
                                              );
                                            }}
                                            style={{ borderRadius: "5px" }}
                                          />
                                          {this.state.showStateError &&
                                          this.state.state.length > 0 ? (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: 10,
                                              }}
                                            >
                                              Enter valid State
                                            </p>
                                          ) : null}
                                        </div>
                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                          <label className="field-label">
                                            Phone{" "}
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            name="mobile"
                                            value={this.state.mobile}
                                            required
                                            onChange={(e) => {
                                              this.setState(
                                                { mobile: e.target.value },
                                                () => {
                                                  this.validateMobile(
                                                    this.state.mobile
                                                  );
                                                }
                                              );
                                            }}
                                            style={{ borderRadius: "5px" }}
                                          />
                                          {this.state.showmobileError &&
                                          this.state.mobile.length > 0 ? (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: 10,
                                              }}
                                            >
                                              Enter valid mobile
                                            </p>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row check-out">
                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div className="field-label">
                                          Email address{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </div>
                                        <input
                                          type="email"
                                          name="EMAIL"
                                          value={this.state.EMAIL}
                                          required
                                          onChange={(e) => {
                                            this.setState(
                                              { EMAIL: e.target.value },
                                              () => {
                                                this.validateEmail(
                                                  this.state.EMAIL
                                                );
                                              }
                                            );
                                          }}
                                          style={{ borderRadius: "5px" }}
                                        />
                                        {this.state.showEmailError &&
                                        this.state.EMAIL.length > 0 ? (
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: 10,
                                            }}
                                          >
                                            Enter valid e-mail address
                                          </p>
                                        ) : null}
                                      </div>
                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div className="field-label">
                                          Course{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </div>
                                        <select
                                          style={{
                                            padding: "10px 15px",
                                            fontSize: "12px",
                                            width: "100%",
                                            borderColor: "#ddd",
                                            marginBottom: "20px",
                                            color: "#495057",
                                          }}
                                          name="course"
                                          required={true}
                                          value={this.state.course}
                                          onChange={this.handleInput}
                                        >
                                          <option value="">
                                            Select Course
                                          </option>
                                          {this.state.courses.map((v, i) => (
                                            <option
                                              style={{
                                                padding: "20px 10px",
                                                fontSize: "14px",
                                              }}
                                              key={i}
                                              value={v.value}
                                            >
                                              {v.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div className="field-label">
                                          Password{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </div>
                                        <input
                                          type={
                                            this.state.openEye
                                              ? "text"
                                              : "password"
                                          }
                                          name="PASSWORD"
                                          value={this.state.PASSWORD}
                                          onChange={(e) => {
                                            this.handleInput(e);
                                          }}
                                          style={{ borderRadius: "5px" }}
                                          required
                                        />
                                      </div>
                                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div className="field-label">
                                          Confirm password{" "}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </div>
                                        <input
                                          type="password"
                                          name="password_confirmation"
                                          value={
                                            this.state.password_confirmation
                                          }
                                          onChange={(e) =>
                                            this.setState(
                                              {
                                                password_confirmation:
                                                  e.target.value,
                                              },
                                              () => {
                                                if (
                                                  this.state.PASSWORD ===
                                                  this.state
                                                    .password_confirmation
                                                ) {
                                                  this.setState({
                                                    passError: false,
                                                  });
                                                } else {
                                                  this.setState({
                                                    passError: true,
                                                  });
                                                }
                                              }
                                            )
                                          }
                                          style={{ borderRadius: "5px" }}
                                          required
                                        />
                                        {this.state.passError ? (
                                          <div>
                                            <span style={{ color: "red" }}>
                                              Password and confirm password
                                              should be same.
                                            </span>
                                          </div>
                                        ) : null}
                                        {this.state.openEye === true ? (
                                          <img
                                            src={eye}
                                            alt=""
                                            className="eyeIcon"
                                            style={{
                                              top: "-55px",
                                              right: "23px",
                                            }}
                                            onClick={(e) =>
                                              this.setState({
                                                openEye: false,
                                                closeEye: true,
                                              })
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={closeEyeImg}
                                            alt=""
                                            className="closeEyeIcon"
                                            style={{
                                              top: "-55px",
                                              right: "23px",
                                            }}
                                            onClick={(e) =>
                                              this.setState({
                                                openEye: true,
                                                closeEye: false,
                                              })
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>

                                    {this.state.showError ? (
                                      <div>
                                        <p style={{ color: "red" }}>
                                          This Email is already registered with
                                          us.
                                          <span
                                            onClick={() => {
                                              this.setState({
                                                regForm: false,
                                                showLoginForm: true,
                                              });
                                              this.mainContent.scrollIntoView();
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              color: "blue",
                                            }}
                                          >
                                            Click Here
                                          </span>{" "}
                                          to Login.
                                        </p>
                                      </div>
                                    ) : null}
                                    <button
                                      to=""
                                      type="submit"
                                      className="btn btn-solid"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {this.state.btnLoading ? (
                                        <Lottie
                                          options={{
                                            animationData: loader,
                                          }}
                                          style={{
                                            width: "50px",
                                            height: "30px",
                                          }}
                                        />
                                      ) : (
                                        "create Account & Place Order"
                                      )}
                                    </button>
                                  </form>
                                </div>
                              ) : null}
                            </div>
                          ) : null}

                          <div
                            className={
                              window.innerWidth > 400
                                ? "col-lg-6 col-md-6 col-sm-6 col-xs-6"
                                : "col-lg-6 col-md-6 col-sm-12 col-xs-12"
                            }
                          >
                            <div className="checkoutOverlay" />
                            <div className="checkout-details">
                              <div className="order-box">
                                <div className="title-box">
                                  <div
                                    className="row"
                                    style={{ marginBottom: "24px" }}
                                  >
                                    <div className="col-md-7 col-xs-6">
                                      Product
                                    </div>
                                    <div className="col-md-5 col-xs-6">
                                      <span>Total</span>
                                    </div>
                                  </div>
                                  <ul className="sub-total row">
                                    <div className="col-md-7 col-xs-7">
                                      <li>
                                        <span>{this.state.ProdctName}</span>
                                      </li>
                                    </div>
                                    <div className="col-md-5 col-xs-5">
                                      <li>
                                        <span className="count">
                                          ₹ {this.state.grand_total}
                                        </span>
                                      </li>
                                    </div>
                                  </ul>
                                </div>
                                <ul className="sub-total row">
                                  <div className="col-md-7 col-xs-7">
                                    <li>Subtotal </li>
                                  </div>
                                  <div className="col-md-5 col-xs-5">
                                    <li>
                                      <span className="count">
                                        ₹{this.state.grand_total}
                                      </span>
                                    </li>
                                  </div>
                                  <div className="col-md-7 col-xs-7">
                                    <li>Shipping Charges</li>
                                  </div>
                                  <div className="col-md-5 col-xs-5">
                                    <li>
                                      <span className="count">
                                        {" "}
                                        ₹ {this.state.shipping}
                                      </span>
                                    </li>
                                  </div>
                                  <div className="col-md-7 col-xs-7">
                                    <li>Coupon Amount </li>
                                  </div>
                                  <div className="col-md-5 col-xs-5">
                                    <li>
                                      <span className="count">
                                        {" "}
                                        ₹ {this.state.coupon_amount}
                                      </span>
                                    </li>
                                  </div>
                                </ul>
                                <ul className="total row">
                                  <div className="col-md-7 col-xs-7">
                                    <li>Total </li>
                                  </div>
                                  <div className="col-md-5 col-xs-5">
                                    <li>
                                      <span className="count">
                                        ₹{this.state.grand_total}
                                      </span>
                                    </li>
                                  </div>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Modal
                  visible={this.state.paymentError}
                  width="350"
                  height="300"
                  effect="fadeInUp"
                >
                  <div>
                    <h4> Something went wrong .</h4>
                    <span onClick={this.placeOrder}>Try Again</span>
                    <span onClick={this.closeModal}>Cancel</span>
                  </div>
                </Modal>
              </div>
            </div>
          </section>
          {/* {this.state.showNotif ? (
            // <CheckoutRegitrationNotification message={this.state.notiMessage} />
          ) : null} */}
        </div>
      );
    }
  }
}
