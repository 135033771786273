import React, { Component } from "react";
import Axios from "axios";
import Constants from "../common/Constants";
import { Link } from "react-router-dom";
import NotAvailable from "./NotAvailable";

export default class TestSeries extends Component {
  state = {
    testSeries: [],
    visible: false,
    product: { subject: [], TITLE: "" },
    testId: 0,
    empty: false,
    emptyPaper: false,
    testIndex: 0,
    msg: "",
  };
  componentDidMount() {
    let userToken = localStorage.getItem("token");

    Axios.get(Constants.getUrls.UserTestSeries, {
      headers: { Authorization: "Bearer " + userToken },
    }).then((res) => {
      console.log(res.data);
      if (res.data.status === "success") {
        this.setState(
          {
            testSeries: res.data.data,
            empty: res.data.length === 0 ? true : false,
          },
          () => {
            console.log(this.state.testSeries);
          }
        );
      } else {
        this.setState({
          msg: res.data.message,
        });
      }
    });
  }

  showPaper = (id) => {
    const newId = this.state.testSeries.find(
      (courseId) => courseId.course.ID === id
    );
    console.log(this.state.newId);
    this.setState(
      {
        visible: !this.state.visible,
        showPaper: newId.subject,
        emptyPaper: newId.subject.length === 0 ? true : false,
      },
      () => {
        console.log(this.state.showPaper);
      }
    );
  };
  startExam() {
    alert("Do You Want To Start The Test ?");
  }

  render() {
    return (
      <div className="container">
        {!this.state.empty ? (
          <div>
            <div>
              {this.state.testSeries.length > 0 ? (
                this.state.testSeries.map((test, index) => (
                  <div className="showTestPage">
                    <div className="testSeriesTitle">
                      <p
                        onClick={() => {
                          this.showPaper(test.course.ID);
                          this.setState({ testIndex: index });
                        }}
                        style={{ display: "inline" }}
                      >
                        {test.course.TITLE}
                        {this.state.visible &&
                        this.state.testIndex === index ? (
                          <i
                            class="fa fa-chevron-up"
                            style={{ left: "95%", position: "absolute" }}
                          ></i>
                        ) : (
                          <i
                            class="fa fa-chevron-down"
                            style={{ left: "95%", position: "absolute" }}
                          ></i>
                        )}
                      </p>
                    </div>

                    {this.state.visible && this.state.testIndex === index ? (
                      <div className="testSeriesContents">
                        {!this.state.emptyPaper ? (
                          <div>
                            {this.state.showPaper.map((some) => (
                              <div
                                style={{
                                  width: "100%",
                                  boxShadow:
                                    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                                  marginBottom: "20px",
                                  padding: "20px",
                                  background: "rgb(250, 251, 252)",
                                  borderRadius: "8px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{
                                      marginRight: "",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {some.paper.TITLE}
                                  </p>

                                  <i class="fa fa-stopwatch"></i>
                                  <p
                                    style={{
                                      position: "absolute",
                                      left: "80%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <i class="fa fa-clock"></i>
                                    Time : {some.paper.NO_OF_MINUTES} min
                                  </p>
                                </div>
                                <div>
                                  <p>Total Marks : {some.paper.total_marks} </p>
                                  <p>
                                    Correct Answer : + {some.paper.currect_ans}{" "}
                                    Marks{" "}
                                  </p>
                                  <p>
                                    Wrong Answer : - {some.paper.wrong_ans}{" "}
                                    Marks{" "}
                                  </p>
                                </div>
                                {some.given_test === "yes" ? (
                                  <Link
                                    to={{
                                      pathname: "/result",

                                      state: { data: some },
                                    }}
                                  >
                                    <p className="takeAtest"> View Result </p>
                                  </Link>
                                ) : (
                                  <Link
                                    to={{
                                      pathname: "/exam/" + some.paper.ID,
                                      minutes: some.paper.NO_OF_MINUTES,
                                    }}
                                  >
                                    <p
                                      onClick={() => {
                                        this.startExam();
                                        localStorage.setItem(
                                          "examId",
                                          some.paper.ID
                                        );
                                        localStorage.setItem("testId", some.ID);
                                        localStorage.setItem(
                                          "courseId",
                                          some.REF_COURSE_ID
                                        );
                                        localStorage.setItem(
                                          "min",
                                          some.paper.NO_OF_MINUTES
                                        );
                                        localStorage.setItem(
                                          "name",
                                          some.paper.TITLE
                                        );
                                      }}
                                      className="takeAtest"
                                    >
                                      {" "}
                                      Take a Test{" "}
                                    </p>
                                  </Link>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <h5>Data not available</h5>
                        )}
                      </div>
                    ) : null}
                  </div>
                ))
              ) : (
                <p
                className="webDisplay"
                  style={{
                    textAlign: "center",
                    marginTop: "50px",
                    fontSize: "17px",
                    marginLeft:'-158px'
                  }}
                >
                  {" "}
                  {this.state.msg}{" "}
                </p>
              )}
            </div>

            <div className="showTestText">
              <h5 style={{ textAlign: "center", marginTop: "21px" }}>
                Test Series Is Not available For Mobile Version.Please visit the
                desktop Version.
              </h5>
            </div>
          </div>
        ) : (
          <NotAvailable />
        )}
      </div>
    );
  }
}
