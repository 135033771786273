import Axios from "axios";
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import Constants from "../components/common/Constants";
import ActivationProcess from "../components/Screens/ActivationProcess";
import ChangePassword from "../components/Screens/ChangePassword";
import LecturesLink from "../components/Screens/LecturesLink";
import LiveSessions from "../components/Screens/LiveSessions";
import Orders from "../components/Screens/Orders";
import Profile from "../components/Screens/Profile";
import TestSeries from "../components/Screens/TestSeries";
import FacetoFaceOrders from "./Screens/FacetoFaceOrders";
import Sidebar from "./Sidebar";
import "../scss/Dashboard.scss";
import more from "../images/menus.png";
import { Link } from "react-router-dom";
import Header from "./common/Header";
import Footer from "./common/Footer";
// import { Helmet } from "react-helmet";
export default class Dashboard extends Component {
  state = {
    userdetails: {},
    UserType: "",
    navClose: { right: "0px" },
    openNavScreen: false,
  };
  componentDidMount() {
    let userToken = localStorage.getItem("token");

    Axios.get(Constants.getUrls.user, {
      headers: { Authorization: "Bearer " + userToken },
    }).then((res) => {
      console.log(res);
      this.setState(
        {
          userdetails: res.data,
          UserType: res.data.TYPE,
        },
        () => {
          console.log(this.state.UserType);
        }
      );
    });
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { left: "-370px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { left: "-770px" } });
    }
  }
  openNav() {
    this.setState({ navClose: { left: "0px" }, openMainScreen: true });
  }
  closeNav() {
    this.setState({ navClose: { left: "-770px" } });
  }
  logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  render() {
    return (
      <div>
        <Header />
        <div className="container-fluid" style={{ paddingBottom: "10px" }}>
          <div className="row">
            <div className=" col-sm-3">
              <div className="webDisplay">
                <Sidebar />
              </div>
              <div className="responsiveDisplay">
                <div className="respNav" onClick={this.openNav.bind(this)}>
                  <img
                    src={more}
                    className="moreIcon"
                    alt=""
                    style={{
                      width: "13%",
                      marginTop: "-3px",
                      marginRight: "10px",
                    }}
                  />
                  <span style={{ color: "#fff" }}>My Account</span>
                </div>

                <div className="rightNames" style={this.state.navClose}>
                  <div className="block-content">
                    <p onClick={this.closeNav.bind(this)} className="backBtn">
                      Back
                    </p>
                    <Link
                      to="/dashboard/activation"
                      className="dashboradLeftSidebarContents"
                      onClick={this.closeNav.bind(this)}
                    >
                      Activation Process
                    </Link>
                    <Link
                      to="/dashboard/lecturesLink"
                      className="dashboradLeftSidebarContents"
                      onClick={this.closeNav.bind(this)}
                    >
                      Player Software File
                    </Link>

                    <Link
                      to="/dashboard/orders"
                      className="dashboradLeftSidebarContents"
                      onClick={this.closeNav.bind(this)}
                    >
                      Video Lecture (Smart Classroom)/Books
                    </Link>
                    <Link
                      to="/dashboard/facetofaceorders"
                      className="dashboradLeftSidebarContents"
                      onClick={this.closeNav.bind(this)}
                    >
                      {/* Face to Face Orders */}
                      Face to Face & Live Stream Batch
                    </Link>
                    <Link
                      to="/dashboard/profile"
                      className="dashboradLeftSidebarContents"
                      onClick={this.closeNav.bind(this)}
                    >
                      My Profile
                    </Link>
                    <Link
                      to="/dashboard/change-password"
                      className="dashboradLeftSidebarContents"
                      onClick={this.closeNav.bind(this)}
                    >
                      Change Password
                    </Link>
                    <Link
                      to=""
                      className="last dashboradLeftSidebarContents logoutDashboard"
                      onClick={this.logout}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-sm-9">
              <div className="webDisplay">
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "35px",
                    textAlign: "center",
                  }}
                >
                  Dashboard
                </h1>
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "17px",
                    color: "rgb(218, 106, 6)",
                  }}
                >
                  Welcome {this.state.userdetails.FIRST_NAME}
                </h3>
              </div>
              <div className="responsiveDisplay">
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "35px",
                    textAlign: "center",
                  }}
                >
                  Dashboard
                </h1>
                <h3
                  style={{
                    // textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "17px",
                    color: "rgb(218, 106, 6)",
                    textAlign: "center",
                  }}
                >
                  Welcome {this.state.userdetails.FIRST_NAME}
                </h3>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Routes>
                  <Route
                    exact
                    path="/activation"
                    element={<ActivationProcess />}
                  />
                  <Route path="/lecturesLink" element={<LecturesLink />} />
                  <Route path="/testseries" element={<TestSeries />} />
                  <Route path="/livesessions" element={<LiveSessions />} />
                  <Route path="/change-password" element={<ChangePassword />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route
                    path="/facetofaceorders"
                    element={<FacetoFaceOrders />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
