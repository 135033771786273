import React, { Component } from "react";
import Header from "./components/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Poster from "./components/Poster";
import Footer from "./components/Footer";
import Subjects from "./components/Subjects";
import SubjectFooter from "./components/SubjectFooter";
import CourseLanding from "./components/CourseLanding";
import CourseDetailFooter from "./components/CourseDetailFooter";
import CourseDetails from "./components/CourseDetails";
import FaceToFaceDetail from "./components/FaceToFaceDetail";
// import Checkout from "./components/checkout/Checkout";
import FunctionalCheckout from "./components/checkout/FunctionalCheckout";
import HomePage from "./components/Screens/HomePage";
// import Checkout from "./components/checkout";
import init from "./components/helper/WindowToken";
import axios from "axios";
import Constants from "./components/common/Constants";
import Dashboard from "./components/Dashboard";
import PaymentSuccess from "./PaymentSuccess";
import EditUserProfile from "./components/EditUserProfile";
// import OrderDetails from "./components/OrderDetails";
import OrderDetailsFunctional from "./components/OrderDetailsFunctional";
// import FacetoFaceCheckout from "./components/checkout/FacetoFaceCheckout";
import FunctionalFacetoFaceCheckOut from "./components/checkout/FunctionFacetoFaceCheckout";
import ComboSubjects from "./components/ComboSubjects";
import TestSeriesListing from "./components/TestSeriesListing";
import BooksListing from "./components/BooksListing";
import OrderDetails from "./components/Screens/OrderDetails";
import ForgetPassword from "./components/Screens/ForgetPassword";
import PrivacyPolicy from "components/PrivacyPolicy";
import TermsNconditions from "components/TermsNconditions";
import ReturnPolicy from "components/ReturnPolicy";
import AboutUs from "components/AboutUs";

// import TestSeries from "./components/TestSeries";
import AOS from "aos";
import "aos/dist/aos.css";
import BooksNew from "components/BooksNew";
import BooksNewResp from "components/BooksNewResp";
import BookCourseDetails from "components/BookCourseDetails";
import BookCourseDetailsResp from "components/Screens/BookCourseDetailsResp";

AOS.init();

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      show: false,
      imageSliderModal: [],
      imageSlider: "",
    };
  }
  componentDidMount() {
    // this.getCart();
    this.getUserInfo();
    // this.getSlideModal();
    // console.log(window.innerWidth);
    // if (window.innerWidth < 700) {
    //   this.setState({ show: true });
    // }
  }
  getUserInfo = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.user).then((res) => {
        // console.log(res.data);
        this.setState({ userInfo: res.data }, () => {
          // console.log(this.state.userInfo);
        });
      });
    }
  };

  // getSlideModal = () => {
  //   axios.get(Constants.getUrls.slider).then((res) => {
  //     console.log(res.data);
  //     this.setState(
  //       {
  //         imageSliderModal: res.data,
  //       },
  //       () => {
  //         console.log(this.state.imageSliderModal);
  //       }
  //     );
  //   });
  // };

  render() {
    return (
      <BrowserRouter>
        {this.state.show ? (
          <div>
            <h5 style={{ textAlign: "center", marginTop: "50%" }}>
              Please view this page in desktop mode.Mobile view underprocess
            </h5>
          </div>
        ) : (
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <HomePage />
                </>
              }
            />

            <Route
              exact
              path="/subjects/:id"
              element={
                <>
                  <Subjects />
                </>
              }
            />

            <Route
              exact
              path="/course_landing/*"
              element={
                <>
                  <CourseLanding />
                </>
              }
            />
            <Route
              exact
              path="/combo_subject_courses/*"
              element={
                <>
                  <ComboSubjects />
                </>
              }
            />
            <Route
              exact
              path="/course_details/*"
              element={
                <>
                  <CourseDetails />
                </>
              }
            />

            <Route
              exact
              path="/facetoface_details/:id/:id/:id"
              element={
                <>
                  <FaceToFaceDetail />
                </>
              }
            />
            <Route
              exact
              path="/testseries"
              element={
                <>
                  <TestSeriesListing />
                </>
              }
            />

            <Route
              exact
              path="/books"
              element={
                <>
                  <BooksListing />
                </>
              }
            />

            <Route
              exact
              path="/booksnew/*"
              element={
                <>
                  <BooksNew />
                </>
              }
            />

            <Route
              exact
              path="/booksnewresp/*"
              element={
                <>
                  <BooksNewResp />
                </>
              }
            />

            <Route
              exact
              path="/bookcoursedetails/*"
              element={
                <>
                  <BookCourseDetails />
                </>
              }
            />

            <Route
              exact
              path="/bookcoursedetailsresp/*"
              element={
                <>
                  <BookCourseDetailsResp />
                </>
              }
            />

            <Route
              exact
              path="/payment-success"
              element={
                <>
                  <PaymentSuccess />
                </>
              }
            />

            <Route
              exact
              path="/dashboard/*"
              element={
                <>
                  <Dashboard />
                </>
              }
            />
            <Route
              exact
              path="/userdetails"
              element={
                <>
                  <EditUserProfile />
                </>
              }
            />

            {/* <Route
              exact
              path="/order-detail/:id"
              element={
                <>
                  <OrderDetail />
                </>
              }
            >
              <Route
                path=":id"
                element={(props) => <OrderDetail {...props} />}
              ></Route>
            </Route> */}
            <Route
              exact
              path="/order-detail/:id"
              element={
                <>
                  <OrderDetails />
                </>
              }
            />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/termsandconditions" element={<TermsNconditions />} />
            <Route path="/returnpolicy" element={<ReturnPolicy />} />
            <Route
              exact
              path="/forget-password"
              element={
                <>
                  <ForgetPassword />
                </>
              }
            />

            <Route
              exact
              path="/facetofacecheckout"
              element={
                <>
                  <FunctionalFacetoFaceCheckOut />
                </>
              }
            >
              <Route
                path=":id"
                element={(props) => <FunctionalFacetoFaceCheckOut {...props} />}
              />
            </Route>

            <Route
              exact
              path="/checkout"
              element={
                <>
                  <FunctionalCheckout />
                </>
              }
            >
              <Route
                path=":id"
                element={(props) => <FunctionalCheckout {...props} />}
              ></Route>
            </Route>
          </Routes>
        )}
      </BrowserRouter>
    );
  }
}
