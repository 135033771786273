import React, { Component } from "react";
import "../scss/CourseDetail.scss";
import subject_bg from "../images/subject_bg.jpg";
import ak_classes_book from "../images/ak_classes_book.png";
import pdf from "../images/pdf.png";
import axios from "axios";
import moment from "moment";
import checked_radio from "../images/checked_radio.png";
import unchecked_radio from "../images/unchecked_radio.png";
import notFound from "../images/77703-no-data-found.json";
import masterbook2 from "../images/master_book_2.png";
import "../scss/CourseLanding.scss";
import "../scss/Books.scss";
import Constants from "./common/Constants";
import YoutubeEmbed from "./YoutubeEmbed";
import { Link } from "react-router-dom";
import init from "../components/helper/WindowToken";
import CourseDetailResp from "./Screens/CourseDetailResp";
import Header from "./common/Header";
import Footer from "./common/Footer";
// import renderHTML from "react-render-html";
import renderHTML from "react-render-html";
import loader from "../images/97739-loader.json";
import Lottie from "react-lottie";

let currentUrlParams = new URL(window.location);

export default class BooksNewResp extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    answer: false,
    answer1: false,
    answer2: false,
    answer3: false,
    streamName: "",
    subjectName: "",
    streamId: "",
    attemptId: "",
    subjectId: "",
    language: "",
    batchId: "",
    subject_filter_id: "",
    attempts: [],
    batchWings: [],
    courses: [],
    price: "",
    courseId: "",
    sameDate: "",
    getUpcomingDays: [],
    batchWingId: "",
    ischeck: false,
    array_batch_wings_id: [],
    subjectFilter: [],
    array_subject_filter_ids: [],
    getLastDays: [],
    isLoading: true,
    books: [],
  };

  showAnswer = (e) => {
    this.setState({
      answer: !this.state.answer,
    });
  };
  showAnswer1 = (e) => {
    this.setState({
      answer1: !this.state.answer1,
    });
  };
  showAnswer2 = (e) => {
    this.setState({
      answer2: !this.state.answer2,
    });
  };
  showAnswer3 = (e) => {
    this.setState({
      answer3: !this.state.answer3,
    });
  };
  LightenDarkenColor(col, amt) {
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
  hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;
    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;
    return s;
  }
  color(r, g, b) {
    return "#" + this.hex2(r) + this.hex2(g) + this.hex2(b);
  }
  shade(col, light) {
    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);
    if (light < 0) {
      r = (1 + light) * r;
      g = (1 + light) * g;
      b = (1 + light) * b;
    } else {
      r = (1 - light) * r + light * 255;
      g = (1 - light) * g + light * 255;
      b = (1 - light) * b + light * 255;
    }
    return this.color(r, g, b);
  }

  componentDidMount() {
    console.log(window.innerWidth);
    console.log(window.innerHeight);
    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var urls = window.location.href;
    //Get Params
    var categoryIdParams = params.get("categoryId");
    var levelIdParams = params.get("levelId");
    var streamIdParams = params.get("streamId");
    var subject_filter_idParams = params.get("subject_filter_id");

    var categoryId = atob(categoryIdParams);
    var levelId = atob(levelIdParams);
    var streamId = atob(streamIdParams);
    var subject_filter_id = atob(subject_filter_idParams);

    this.setState(
      {
        categoryId: categoryId,
        levelId: levelId,
        streamId: streamId,
        subject_filter_id: subject_filter_id,
      },
      () => {
        console.log(this.state.subject_filter_id);
        this.fetchData();
      }
    );
  }

  fetchData = () => {
    console.log(this.state.subject_filter_id);
    axios.get(Constants.getUrls.getCoursesByCatLevelStream + "?stream_id=" + this.state.streamId).then(resp => {
      console.log(resp);
      if(resp.data.status === "success")
      {
        this.setState({
          subjectFilter: resp.data.data,
          subject_filter_id: resp.data.data[0].id,
        },() => {
          console.log(this.state.subject_filter_id);
          var params = new URLSearchParams(window.location.search);
          var encryptedsubject_filter_id = btoa(this.state.subject_filter_id);
          params.set("subject_filter_id", encryptedsubject_filter_id);
          var urls = window.location.href;
          var newUrl = decodeURIComponent(
            window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              params.toString()
          );
          window.history.pushState({ path: newUrl }, "", newUrl);
          this.state.array_subject_filter_ids.push({
            id: this.state.subjectFilter[0].id,
          });
          this.setState({
            array_subject_filter_ids: this.state.array_subject_filter_ids,
          });

          axios.get(Constants.getUrls.getBooks + 
            "?category_id=" + this.state.categoryId 
            + "&level_id=" + this.state.levelId 
            + "&stream_id=" + this.state.streamId
            + "&subject_id=" + this.state.subject_filter_id
            ).then(res => {
              console.log(res);
              if(res.data.status === "success")
              {
                this.setState({
                  isLoading: false,
                  books: res.data.data,
                })
              }
          })

        })
      }
    })

    
  }

  getCoursesFilter = (id) => {
    console.log(id);
    // currentUrlParams.searchParams.set("subject_filter_id", id);
    // window.history.pushState({}, "", window.location.assign(currentUrlParams));

    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var encryptedsubject_filter_id = btoa(id);
    // Set a new parameter or update an existing one
    params.set("subject_filter_id", encryptedsubject_filter_id);
    // Get the current URL
    var urls = window.location.href;

    // Decode the URL
    // var decodedURL = decodeURIComponent(urls);
    // Create a new URL with the updated parameters
    var newUrl = decodeURIComponent(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.toString()
    );
    // var newUrl = decodedURL;

    // Modify the URL without reloading the page
    window.history.pushState({ path: newUrl }, "", newUrl);

    this.setState(
      {
        subject_filter_id: id,
        isLoading: true,
      },
      () => {
        axios
          .get(
            Constants.getUrls.booksAgainstSubject +
              "?category_id=" +
              this.state.categoryId +
              "&level_id=" +
              this.state.levelId +
              "&stream_id=" +
              this.state.streamId +
              "&subject_id=" +
              this.state.subject_filter_id
            ).then((resp) => {
              console.log(resp);
              if(resp.data.status === "success")
              {
                this.setState(
                {
                  books: resp.data.data,
                  isLoading: false,
                }
              )}
          })
          .catch((error) => {});
      }
    );
  };

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notFound,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
          <section classeName="testSeries" >
            <div className="testSeriesResp ">
              {/* <img src={subject_bg} alt="" width={1740} height={85} /> */}
              <h1 className="fonts">Books Details</h1>
              {/* <h5 classeName="fonts titleName"> </h5> */}
            </div>
          </section>

          <section className="section3 container">
            <div className="row">
              <div className="col-sm-3">
                <div className="chooseSubjectsResp">
                  <h3 className="typeNameResp">Choose Subject</h3>
                  {
                      this.state.subjectFilter.map((sub, index) => (
                        <div className="checkDiv1Resp" style={{marginLeft:"-6px"}}>
                          {
                            sub.title && (
                              <button
                                className="batch_buttonResp"
                                name="subject"
                                onClick={(e) => {
                                  this.getCoursesFilter(sub.id);
                                }}
                              >
                                {parseInt(this.state.subject_filter_id) ===
                                sub.id ? (
                                  <img className="button_radio" src={checked_radio} />
                                ) : (
                                  <img
                                    className="button_radio"
                                    src={unchecked_radio}
                                  />
                                )}{" "}
                                <span>{sub.title}</span>
                              </button>
                            )
                          }
                        </div>
                      ))}
                </div>
              </div>
              <div className="col-sm-9 rightArea">
                  <section className="section4">
                    <div className="col-md-12 course-card-container">
                    {this.state.isLoading ? (
                      <>
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            marginLeft: "32%",
                          }}
                        >
                          Please Wait
                        </span>
                        <Lottie
                          options={defaultLoader}
                          height={80}
                          width={80}
                          isStopped={this.state.isStopped}
                          isPaused={this.state.isPaused}
                        />
                      </>
                    ) : (
                      this.state.books.length > 0 ?
                        this.state.books.map((book, index) => (
                        <div className="col-md-4 margSecbook4">
                            <div className="bookimgdiv">
                                <img src={Constants.imgUrl + book.IMAGE_PATH} alt="" className="masterBook2"/>
                            </div>
                            <div>
                                <h4 className="cafoundation">
                                {book.TITLE}
                                </h4> 
                            </div>
                            <div className="col-md-12" style={{marginTop: "5px"}}>
                            <div className="row">
                                {/* <div className="col-md-8"> */}
                                <span className="byAmanSirResp">
                                    By - {book.faculties?.name}
                                </span>
                                {/* </div> */}
                                {/* <div className="col-md-4"> */}
                                <span className="rupeesResp">
                                    ₹{book.PRICE}
                                </span>
                                {/* </div> */}
                            </div>
                            </div>

                            <div className="col-md-12 borderResp"></div>

                            <div className="col-md-12">
                                <Link to={"/bookcoursedetailsresp?" + "categoryId=" + btoa(this.state.categoryId) + "&levelId=" + btoa(this.state.levelId) + "&streamId=" + btoa(this.state.streamId) + "&subject_filter_id=" + btoa(this.state.subject_filter_id) + "&courseId=" + btoa(this.state.books[0].ID)}
                                    state={{
                                      books: this.state.books[0],
                                    }}
                                >
                                <button type="button" className="bookbtn">
                                <h4 className="bookButton">
                                    Buy This Book
                                </h4> 
                                </button>
                              </Link>
                            </div>
                        </div>
                        ))
                        :
                        <div
                          className="col-md-4 regiBatches"
                          style={{
                            textAlign: "center",
                            marginTop: "0%",
                            marginLeft: "0%",
                            fontSize: "24px",
                            fontFamily: "raleway",
                          }}
                        >
                          <Lottie
                            options={defaultOptions}
                            height={200}
                            width={300}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                          />
                        </div>
                    )}
                    </div>
                  </section>
              </div>
            </div>
          </section>
      </div>
    );
  }
}
