import React, { Component } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";

export default class PrivacyPolicy extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Header />
        <div className="container">
          <div className="privacy">
            <h1>PRIVACY POLICY</h1>
            THIS PRIVACY POLICY APPLIES TO akclasses.in. Aman Khedia Classes (AK
            CLASSES) recognises the importance of maintaining your privacy. We
            value your privacy and appreciate your trust in us. This Policy
            describes how we treat user information we collect on akclasses.in
            and other offline sources. This Privacy Policy applies to current
            and former visitors to our website and to our online customers. By
            visiting and/or using our website, you agree to this Privacy Policy.
            akclasses.in is the property of M/s Aman Khedia Classes having
            registered office at 48/101, The Park Meadows, Kachimet, Amravati
            Road, Nagpur – 440033 (Maharashtra) India.
            <h4>Information We Collect</h4>
            <b>Contact Information</b>
            <p>
              {" "}
              We might collect your Name, Email, Phone, Address and IP Address
            </p>
            <b>Payment And Billing Information</b>
            <p>
              {" "}
              We might collect your billing name, billing address and payment
              method. We NEVER collect your credit card number or credit card
              expiry date or other details pertaining to your credit card on our
              website. Credit card information will be obtained and processed by
              our online payment partner.
            </p>
            <b> Information You Post</b>
            <p>
              {" "}
              We collect information you post in a public space on our website
              or on a third-party social media site belonging to akclasses.in or
              Aman Khedia Classes{" "}
            </p>
            <b> Demographic Information</b>
            <p>
              {" "}
              We may collect demographic information about you, or any other
              information provided by your during the use of our website. We
              might collect this as a part of a survey also.
            </p>
            <b>Other Information</b>
            <p>
              If you use our website, we may collect information about your IP
              address and the browser you are using. We might look at what site
              you came from, duration of time spent on our website, pages
              accessed or what site you visit when you leave us. We might also
              collect the type of mobile device you are using, or the version of
              the operating system your computer or device is running.
            </p>
            <b>We Collect Information In Different Ways</b>
            <p>
              We collect information directly from you. We collect information
              directly from you when you contact us. We also collect information
              if you post a comment on our websites or ask us a question through
              phone or email. We collect information from you passively. We use
              tracking tools like Google Analytics, Google Webmaster, browser
              cookies and web beacons for collecting information about your
              usage of our website. We get information about you from third
              parties. For example, if you use an integrated social media
              feature on our websites. The third-party social media site will
              give us certain information about you. This could include your
              name and email address.
            </p>
            <b>Use Of Your Personal Information</b>
            <b>We Use Information To Contact You</b>
            <p>
              We might use the information you provide to contact you for
              confirmation of a purchase on our website or for other promotional
              purposes. We use information to respond to your requests or
              questions. We might use your information to confirm your
              registration for an event or contest. We use information to
              improve our products and services. We might use your information
              to customize your experience with us. This could include
              displaying content based upon your preferences. We use information
              to look at site trends and customer interests. We may use your
              information to make our website and products better. We may
              combine information we get from you with information about you we
              get from third parties. We use information for security purposes.
              We may use information to protect our company, our customers, or
              our websites. We use information for marketing purposes. We might
              send you information about special promotions or offers. We might
              also tell you about new features or products. These might be our
              own offers or products, or third-party offers or products we think
              you might find interesting. Or, for example, if you buy product(s)
              from us we will enroll you in our newsletter. We use information
              to send you transactional communications. We might send you emails
              or SMS about your account or a purchase. We use information as
              otherwise permitted by law.
            </p>
            <b>Sharing Of Information With Third-Parties</b>
            <p>
              We will share information with third parties who perform services
              on our behalf. We share information with vendors who help us
              manage our online registration process or payment processors or
              transactional message processors. Some vendors may be located
              outside of India. We may share information if we think we have to
              in order to comply with the law or to protect ourselves. We will
              share information to respond to a court order or subpoena. We may
              also share it if a government agency or investigatory body
              requests. Or, we might also share information when we are
              investigating potential fraud. We may share information with any
              successor to all or part of our business. For example, if part of
              our business is sold we may give our customer list as part of that
              transaction. We may share your information for reasons not
              described in this policy. We will tell you before we do this.
            </p>
            <b>Email Opt-Out</b>
            <p>
              You can opt out of receiving our marketing emails. To stop
              receiving our promotional emails, please email
              bvlhelpdesk@gmail.com. It may take us sometime to process your
              request. Even if you opt out of getting marketing messages, we
              will still be sending you transactional messages through email and
              SMS about your purchases.
            </p>
            <b>Third Party Sites</b>
            <p>
              If you click on one of the links to third party websites, you may
              be taken to websites we do not control. This policy does not apply
              to the privacy practices of those websites. Read the privacy
              policy of other websites carefully. We are not responsible for
              these third party sites. Grievance Officer If you have any
              questions about this Policy or other privacy concerns, you can
              also email us at bvlhelpdesk@gmail.com.
            </p>
            <b>Updates To This Policy</b>
            <p>
              This Privacy Policy was last updated on 25th January 2020. From
              time to time we may change our privacy practices. We will notify
              you of any material changes to this policy as required by law. We
              will also post an updated copy on our website. Please check our
              site periodically for updates.
            </p>
            <b>Jurisdiction</b>
            <p>
              If you choose to visit the website, your visit and any dispute
              over privacy is subject to this Policy and the website’s terms of
              use. In addition to the foregoing, any disputes arising under this
              Policy shall be governed by the laws of India and courts of
              Maharashtra only.
            </p>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
