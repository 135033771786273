import React, { Component } from "react";
import "../scss/JoinNow.scss";
import batchImg from "../images/ak-batch-system.png";
import Footer from "./Footer";
import YouTubeTestimonials from "./YouTubeTestimonials";

export default class JoinNow extends Component {
  render() {
    return (
      <div className="batch-sys-main-container">
        <div className="batch-system-container">
          <h3 data-aos="fade-up" data-aos-duration="500">
            Features of Smart Classroom for 360&deg; Preparation for your
            Foundation Exam
          </h3>
          <div
            className="batch-img"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="300"
          >
            <img src={batchImg} alt="batch" />
          </div>
        </div>
        {/* <div className="joinNow ">
          <div className="col-md-12  joinNowDisplay">
            <div
              className="col-sm-6"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <div>
                <h2 className="ongoing">
                  Ongoing Online <br />
                  Smart Classroom Batch
                </h2>
              </div>
              <div>
                <h4 className="methodology">
                  AK Classes with its unique and simple learning methodologies,
                  thousands of students gain benefit in their CA Examinations.
                  We keep always updating our courses with the syllabus and
                  provide best training to our aspiring CAs.
                </h4>
              </div>
              <div className="daysDivJoin">
                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>
                  <span className="totalDays"> 75 Days</span>{" "}
                  <span>Target Strategy</span>
                </h4>

                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>
                  <span className="totalDays"> 22 Mock Test</span>{" "}
                  <span>Papers with Evalution</span>
                </h4>

                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>
                  <span className="totalDays"> 15 Live</span>{" "}
                  <span>Revision Sessions</span>
                </h4>

                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>
                  <span className="totalDays"> 10 Live</span>{" "}
                  <span>Quiz Sessions</span>
                </h4>

                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i> Doubt Sessions
                  <span className="totalDays"> as per 75 Days</span>
                </h4>

                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>
                  <span className="totalDays"> 10 Live</span>{" "}
                  <span>Quiz Sessions</span>
                </h4>
              </div>
              <div className="daysDivJoinMobail">
                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>
                  <span className="totalDays"> 75 Days</span>{" "}
                  <span>Course Study Planner</span>
                </h4>

                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>
                  <span className="totalDays"> 24 x 7</span>{" "}
                  <span>Doubt Solving Facility</span>
                </h4>

                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>
                  <span className="totalDays"> 25 </span>{" "}
                  <span>Mock Test Papers</span>
                </h4>

                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>
                  <span className="totalDays"> 20 Live</span>{" "}
                  <span>Revision Sessions</span>
                </h4>

                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>140 Hrs{" "}
                  <span className="totalDays"> Recorded Lectures</span>
                </h4>

                <h4 className="daysJoin">
                  <i class="fa-solid fa-check hexCodeRight"></i>
                  <span className="totalDays"> Printed Notes</span>{" "}
                  <span></span>
                </h4>
              </div>
            </div>
            <div
              className="col-sm-6"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="300"
              style={{ textAlign: "left" }}
            >
              <div>
                <h2 className="batchname">Param Batch Details</h2>
              </div>
              <div className="videoArea">
                <YouTubeTestimonials embedId={"U-rEsYZXi_k"} height={"500"} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
