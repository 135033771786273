import axios from "axios";
import React, { Component } from "react";
import Constants from "../common/Constants";
import init from "../helper/WindowToken";
export default class LecturesLink extends Component {
  state = {
    orders: [],
    LecturesLink: [],
  };
  componentDidMount() {
    this.getLecturesLink();
  }
  getLecturesLink = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.getLectures).then((res) => {
        this.setState({ LecturesLink: res.data.data });
      });
    }
  };

  download = (filename, textInput) => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8, " + encodeURIComponent(textInput)
    );
    element.setAttribute("download", filename);
    document.body.appendChild(element);
    element.click();
  };

  render() {
    return (
      <div>
        {this.state.LecturesLink.length > 0 ? (
          <div>
            <div className="row">
              {this.state.LecturesLink.map((lecture, index) => (
                <div
                  className={
                    window.innerWidth > 800 ? "col-xs-4" : "col-xs-6 col-sm-6"
                  }
                >
                  <div
                    style={{
                      backgroundColor: "rgb(248, 248, 248)",
                      padding: "20px",
                      margin: "10px",
                      borderRadius: "10px",
                      textAlign: "center",
                      boxShadow:
                        "0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%)",
                    }}
                  >
                    <p> Lecture {index + 1} </p>
                    <input
                      type="button"
                      id="btn"
                      className="downloadBtn"
                      value="Download"
                      onClick={() => {
                        this.download(Constants.imgUrls + lecture.file);
                      }}
                      style={{
                        border: "none",
                        background: "#da6a06",
                        color: "#fff",
                        padding: "5px",
                        width: window.innerWidth < 800 ? "90px" : "100px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="error-section">
            <h2>Data Not Available</h2>{" "}
          </div>
        )}
      </div>
    );
  }
}
