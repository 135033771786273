import React, { Component } from "react";

export default class NotAvailable extends Component {
  render() {
    return (
      <div>
        <section className="p-0">
          <div className="container" style={{ marginLeft: "-74px" }}>
            <div className="error-section">
              {/* <h1>404</h1> */}
              <h2>Data Not Available.</h2>
              {/* <a href="/" className="btn btn-solid">
                    back to home
                  </a> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
