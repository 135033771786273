import React, { Component } from "react";
import Lottie from "react-lottie";
import {Navigate } from "react-router-dom";
import Modal from "react-awesome-modal";
import animationData from "./images/40762-check-mark-yes.json";
// import { VscSmiley } from "react-icons/vsc";
// import { CgSmileSad } from "react-icons/cg";
// import { CgSmileNeutral } from "react-icons/cg";
import Axios from "axios";
// import{Link} from "react-router-dom";
import Constants from "./components/common/Constants";
import tick from "./images/tick.png";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
// import loader from "../src/assets/images/lf30_editor_eubgjifi.json";
// const 
class PaymentSuccess extends Component {
  state = {
    redirect: false,
    visible: true,
    experience: "",
    comment: "",
    email: "",
    loadingLoader: false,
    checkedSmiley:'',
    errorMsg:false
  };
  // constructor() {
  //   super();
  //   this.state = { isStopped: false, isPaused: false };
  // }
  componentDidMount() {

    window.scrollTo(0, 0);
    this.setState({ visible: true });
    // setInterval(() => {
    //   this.setState({
    //     visible: true,
    //   });
    // }, 3000);
    Axios.get(Constants.getUrls.user).then((res) => {
      console.log(res);
      this.setState({
        user: res.data,
      });
    });
  }

  feedback = () => {
    if(this.state.experience===""){
      this.setState({errorMsg:true})
    }else{
      const payload = {
        experience: this.state.experience,
        comment: this.state.comment,
        email: this.state.user.email,
        name: this.state.user.name,
        checked:true
  
      };
      console.log(payload);
      this.setState({
        loadingLoader: true,
        checked:true,
        errorMsg:false
      
      });
  
      Axios.post(Constants.postUrl.feedback, payload).then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          window.location.href = "/profile";
          this.setState({
            loadingLoader: false,
          });
        }
      });
    }
   
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      
    });
  };
  opeModal() {
    this.setState({ visible: true });
  }
  closeModal() {
    this.setState({ visible: false });
  }
  render() {
    if (this.state.redirect) {
      return <Navigate to="/" />;
    } else {
      const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      return (
        <div className="paymentConfirmed">
          <Header/>
          <Lottie
            options={defaultOptions}
            height={200}
            width={200}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused}
          />
          <h1
            style={{textAlign:"center"}}
            onClick={() => {
              this.opeModal();
            }}
          >
            {/* {" "}
            Payment Successful{" "} */}
          </h1>
          {/* <a href="/">
          <h5>Go To Home Page </h5>
        </a> */}
          <Modal
            visible={this.state.visible}
            width="400"
            height="315"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div className="feedback">
              <h3 style={{textAlign:"center",marginTop:"0px",backgroundColor:"#0f66b9",marginBottom:"10px",color:"#fff",padding:"15px 10px"}}>Congratulations</h3>
              <img src={tick} alt="" style={{width:"100px",height:"100px",marginLeft:"142px"}}/>
              <p style={{textAlign:"center",marginTop:"15px",marginBottom:"10px",color:"#000",padding:"10px 10px",fontSize:"20px"}}>Check your email for order confirmation. We'll see you soon!</p>
              <button
                className="btn btn-solid"
                style={{
                  marginTop: "0px",
                  marginLeft: "0px",
                  width: "402px",
                  position: "relative",
                  left: "-1px",
                  top: "-9px"
                }}
                onClick={() => {
                  window.location.href = "/dashboard";
                }}
              >
                Go to dashboard
            </button>
            </div>
          </Modal>
          <Footer/>
        </div>
      );
    }
  }
}

export default PaymentSuccess;
