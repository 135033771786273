import React, { Component } from "react";
// import React, { Component } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import "../scss/aboutUs.scss";

import img1 from "../images/home_ak.png";

export default class AboutUs extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Header />
        <div className="container">
          <div className="row AboutUs">
            <h1>About Us</h1>
            <div className="col-md-6">
              <img src={img1} alt="" style={{ marginTop: "20px" }} />
            </div>
            <div className="col-md-6">
              <div className="privacy">
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#626262",
                  }}
                >
                  AK Classes Provides best online video lectures for CA Course.
                  We aim to completely change the trend of conventional studies
                  in the most interesting and useful way and to become the first
                  choice of students all over India for their success. Our
                  Vision is to offer a platform for hassle-free education to the
                  students.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#626262",
                  }}
                >
                  At AK Classes the Books & Lectures provided to the students
                  are being run and managed by{" "}
                  <span style={{ fontWeight: "900" }}>2B Publications</span> .
                  We believe that learning should not be restricted within the
                  boundaries of a classroom and that learning can be imparted
                  through any medium, and hence our video lectures aim at
                  attaining Students suitability and accessibility.
                </p>
                {/* <p
                  style={{
                    textAlign: "justify",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#626262",
                  }}
                >
                  AT Ak Classes the Books & Lectures provided to the students
                  are being run and managed by{" "}
                  <span style={{ fontWeight: "900" }}>
                    {" "}
                    Mrs. Megha Agrawal (Proprietor of 2B Publications.)
                  </span>
                </p>
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#626262",
                  }}
                >
                  The upward journey of AK Classes would not have been a reality
                  without the dedication, vision and continuous contribution of
                  Mrs. Megha Agrawal
                </p> */}
                {/* <p
                  style={{
                    textAlign: "justify",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#626262",
                    marginBottom: "40px",
                  }}
                >
                  At AK Classes we believe that learning should not be
                  restricted within the boundaries of a classroom and that
                  learning can be imparted through any medium, and hence our
                  video lectures aim at attaining Students suitability and
                  accessibility.
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
