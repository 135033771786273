import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import checked_radio from "../images/checked_radio.png";
import unchecked_radio from "../images/unchecked_radio.png";
import "../scss/CourseLanding.scss";
import Constants from "./common/Constants";
import Footer from "./common/Footer";
import Header from "./common/Header";
import CourseLandingResp from "./Screens/CourseLandingResp";
import notFound from "../images/77703-no-data-found.json";
import loader from "../images/97739-loader.json";
import Lottie from "react-lottie";
import queryString from "query-string";
import { getNativeSelectUtilityClasses } from "@mui/material";
import renderHTML from "react-render-html";

let currentUrlParams = new URL(window.location);
// Create a new Date object
const currentDate = new Date();

// Get the year, month, and day from the Date object
const year = currentDate.getFullYear();
const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to the month because it's zero-based, and pad with '0' if necessary
const day = currentDate.getDate().toString().padStart(2, "0"); // Pad with '0' if necessary

// Format the date as "Y-m-d"
const today = `${year}-${month}-${day}`;
console.log(today);

export default class CourseLanding extends Component {
  state = {
    answer: false,
    answer1: false,
    answer2: false,
    answer3: false,
    streamName: "",
    subjectName: "",
    streamId: "",
    attemptId: "",
    subjectId: "",
    language: "",
    batchId: "",
    subject_filter_id: "",
    attempts: [],
    batchWings: [],
    courses: [],
    price: "",
    courseId: "",
    sameDate: "",
    getUpcomingDays: [],
    batchWingId: "",
    ischeck: false,
    array_batch_wings_id: [],
    subjectFilter: [],
    array_subject_filter_ids: [],
    getLastDays: [],
    isLoading: true,
  };

  showAnswer = (e) => {
    this.setState({
      answer: !this.state.answer,
    });
  };
  showAnswer1 = (e) => {
    this.setState({
      answer1: !this.state.answer1,
    });
  };
  showAnswer2 = (e) => {
    this.setState({
      answer2: !this.state.answer2,
    });
  };
  showAnswer3 = (e) => {
    this.setState({
      answer3: !this.state.answer3,
    });
  };
  LightenDarkenColor(col, amt) {
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
  hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;
    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;
    return s;
  }
  color(r, g, b) {
    return "#" + this.hex2(r) + this.hex2(g) + this.hex2(b);
  }
  shade(col, light) {
    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);
    if (light < 0) {
      r = (1 + light) * r;
      g = (1 + light) * g;
      b = (1 + light) * b;
    } else {
      r = (1 - light) * r + light * 255;
      g = (1 - light) * g + light * 255;
      b = (1 - light) * b + light * 255;
    }
    return this.color(r, g, b);
  }

  componentDidMount() {
    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);

    // Set a new parameter or update an existing one
    // params.set('subject_filter_id', '5654665465');
    // Get the current URL
    var urls = window.location.href;

    // Decode the URL
    // var decodedURL = decodeURIComponent(urls);

    // // Print the decoded URL
    // console.log(decodedURL);

    // // Create a new URL with the updated parameters
    // // var newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + params.toString();
    // var newUrl = decodedURL;

    // Modify the URL without reloading the page
    // window.history.pushState({ path: newUrl }, '', newUrl);

    //Get Params
    var streamIdParams = params.get("streamId");
    var attemptIdParams = params.get("attemptId");
    var subjectIdParams = params.get("subjectId");
    var languageParams = params.get("language");
    var batchIdParams = params.get("batchId");
    var subject_filter_idParams = params.get("subject_filter_id");
    console.log(batchIdParams);
    var streamId = atob(streamIdParams);
    var attemptId = atob(attemptIdParams);
    var subjectId = atob(subjectIdParams);
    var language = languageParams;
    var batchId = atob(batchIdParams);
    var subject_filter_id = atob(subject_filter_idParams);
    console.log(batchId);
    this.setState(
      {
        streamId: streamId,
        attemptId: attemptId,
        subjectId: subjectId,
        language: language,
        batchId: batchId,
        subject_filter_id: subject_filter_id,
      },
      () => {
        this.fetchData();
        console.log(batchIdParams);
        console.log(this.state.batchId);
      }
    );
  }

  async fetchData(id) {
    try {
      const resp = await axios.get(
        Constants.getUrls.courseLandingAllData +
          "?subjectMasterId=" +
          this.state.subjectId +
          "&subjectWingId=" +
          this.state.subjectId +
          "&subjectId=" +
          this.state.subjectId
      );
      if (currentUrlParams.searchParams.get("language") !== null) {
        this.setState({
          language: currentUrlParams.searchParams.get("language"),
        });
      }
      console.log(resp);
      console.log(resp.data.wings[0].batch_wings_id);

      let subject_array = resp.data.filter;
      if (this.state.batchId === "null" || this.state.batchId == "") {
        this.setState(
          {
            batchId: resp.data.wings[0].batch_wings_id,
            subject_filter_id: subject_array.reverse()[0].id,
          },
          () => {
            console.log(this.state.batchId);
          }
        );
      }

      // let filtWings = [];
      // resp.data.attemptDetails.forEach(detail => {
      //     console.log(detail.id);
      //     console.log(this.state.attemptId);
      //     if (detail.id == this.state.attemptId) {
      //         resp.data.wings.forEach(item => {
      //             filtWings.push(item);
      //         });
      //     }
      // });
      let filtWings = [];
      resp.data.wings.map((bwings) => {
        console.log(bwings.batch_wing.attempt_id);
        console.log(this.state.attemptId);
        if (bwings.batch_wing.attempt_id.includes(this.state.attemptId)) {
          filtWings.push(bwings);
        }
      });

      console.log(filtWings);

      this.setState(
        {
          subjectName: resp.data.subject_master.title,
          attempts: resp.data.attempts.data,
          // batchWings: resp.data.wings,
          batchWings: filtWings,
          // batchId: this.state.batchId,
          // batchId: resp.data.wings[0].batch_wings_id,
          subjectFilter: subject_array.reverse(),
        },
        async () => {
          var params = new URLSearchParams(window.location.search);
          var encryptedbatchId = btoa(this.state.batchId);
          console.log(encryptedbatchId);
          var decryptedBatchId = atob(encryptedbatchId);
          console.log(decryptedBatchId);
          // params.set("batchId", encryptedbatchId);
          // var urls = window.location.href;
          // var newUrl = decodeURIComponent(
          //   window.location.protocol +
          //     "//" +
          //     window.location.host +
          //     window.location.pathname +
          //     "?" +
          //     params.toString()
          // );
          // window.history.pushState({ path: newUrl }, "", newUrl);

          // await new Promise((resolve) => setTimeout(resolve, 5000)); // Introduce a delay of 5 seconds

          //attempt
          // this.getSingleAttempt(this.state.attemptId);

          //subject filters
          // this.setState(
          //   {
          // subject_filter_id: this.state.subject_filter_id,
          // },
          // () => {
          var params = new URLSearchParams(window.location.search);
          var encryptedsubject_filter_id = btoa(this.state.subject_filter_id);
          params.set("subject_filter_id", encryptedsubject_filter_id);
          var urls = window.location.href;
          var newUrl = decodeURIComponent(
            window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              params.toString()
          );
          window.history.pushState({ path: newUrl }, "", newUrl);
          this.state.array_subject_filter_ids.push({
            id: this.state.subjectFilter[0].id,
          });
          this.setState({
            array_subject_filter_ids: this.state.array_subject_filter_ids,
          });
          //end

          if (id) {
            this.getSingleAttempt(id);
          }

          //Courses all
          this.state.array_batch_wings_id.push({ id: this.state.batchId });
          this.setState({
            array_batch_wings: this.state.array_batch_wings_id,
            batchId: atob(encryptedbatchId),
            subject_filter_id: atob(encryptedsubject_filter_id),
          });

          // await new Promise((resolve) => setTimeout(resolve, 5000)); // Introduce another delay of 5 seconds

          const respAll = await axios
            .get(
              Constants.getUrls.coursesAll +
                this.state.subjectId +
                "?batch_wings_id=" +
                this.state.batchId +
                "&language=" +
                this.state.language +
                "&attempt=" +
                this.state.attemptId +
                "&subject_filter_id=" +
                this.state.subject_filter_id
            )
            .then((respAll) => {
              console.log(respAll);
              console.log(respAll.data.data.wingsCourses);
              // console.log(respAll.data.data.wingsCourses[0].batch.batch_name);
              // console.log(
              //   respAll.data.data.wingsCourses[0].batch.batch_name.length
              // );
              let allCourses = [];
              respAll.data.data.wingsCourses.forEach((item) => {
                if (
                  (item.course.project_course_status === "both" ||
                    item.course.project_course_status === "ak1") &&
                  item.course.IS_DELETED == 0
                ) {
                  allCourses.push(item);
                }
              });
              let activeCourses = [];
              allCourses.forEach((item) => {
                if (item.course.STATUS === "ACTIVE") {
                  activeCourses.push(item);
                }
              });

              console.log("All Data:", respAll.data.data.wingsCourses);
              console.log("Filtered Courses:", allCourses);
              console.log("Active Courses:", activeCourses);

              this.setState(
                {
                  courses: activeCourses,
                  loadingWing: false,
                  price: respAll.data
                    ? respAll.data.course
                      ? respAll.data.course.variant
                        ? respAll.data.course.variant[0]
                          ? respAll.data.course.variant[0].price
                          : null
                        : null
                      : null
                    : null,
                  courseId: respAll.data
                    ? respAll.data._id
                      ? respAll.data._id
                      : 0
                    : 0,
                  isLoading: false,
                },
                () => {
                  console.log(this.state.courses);
                  const GetDays = (d, Mention_today = false) => {
                    var DateArray = [];
                    var days = d;
                    for (var i = 0; i < days; i++) {
                      if (!Mention_today && i == 0) {
                        i = 1;
                        days += 1;
                      }
                      var date = new Date();
                      var last = new Date(
                        date.getTime() + i * 24 * 60 * 60 * 1000
                      );
                      var day = last.getDate().toString();
                      var month = (last.getMonth() + 1).toString();
                      var year = last.getFullYear();
                      var monthSplit = month.split("");
                      var daySplit = day.split("");
                      const fulld =
                        Number(year) +
                        "-" +
                        (monthSplit[1] ? Number(month) : "0" + monthSplit[0]) +
                        "-" +
                        (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                      DateArray.push(fulld);
                    }
                    return DateArray;
                  };
                  const currentDate = new Date();
                  const currentDateTime = currentDate.getTime();
                  const last30DaysDate = new Date(
                    currentDate.setDate(currentDate.getDate() - 30)
                  );
                  const last30DaysDateTime = last30DaysDate.getTime();
                  const last30DaysList = this.state.courses.filter((x) => {
                    const elementDateTime = new Date(
                      x.batch.registration_end_date
                    ).getTime();
                    if (
                      elementDateTime <= currentDateTime &&
                      elementDateTime > last30DaysDateTime
                    ) {
                      return true;
                    }
                    return false;
                  });
                  this.setState(
                    {
                      getUpcomingDays: GetDays(100),
                      getLastDays: last30DaysList,
                    },
                    () => {}
                  );
                  let arr1 = this.state.courses;
                  let arr2 = this.state.getUpcomingDays;
                  let arr3 =
                    this.state.getUpcomingDays !== undefined &&
                    arr1.filter((arr1Item) =>
                      arr2.includes(
                        arr1Item
                          ? arr1Item.batch
                            ? arr1Item.batch.registration_start_date
                              ? arr1Item.batch.registration_start_date
                              : ""
                            : ""
                          : ""
                      )
                    );
                  this.setState({
                    sameDate: arr3,
                    same: true,
                  });
                }
              );
            })
            .catch((error) => {
              console.log(error);
              if (error.response && error.response.status === 429) {
                // Handle rate limit exceeded error
                console.log("Too many requests. Please try again later.");
              } else {
                // Handle other errors
                console.log("An error occurred.");
              }
            });
        },
        1000
      );
      console.log(this.state.courses);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle rate limit exceeded error
        console.log("Too many requests. Please try again later.");
        // await new Promise((resolve) => setTimeout(resolve, 5000)); // Introduce a delay of 5 seconds before retrying
        this.fetchData(); // Retry the API request
      } else {
        // Handle other errors
        console.log("An error occurred.");
      }
    }
    console.log(this.state.batchWings);
  }

  componentDidUpdate(prevProps, prevState) {
    // Log the state here to see the updated value
    console.log("Updated state:", this.state.courses);
  }

  getSingleAttempt = (id) => {
    this.setState({ isLoading: true });
    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var encryptedattemptId = btoa(this.state.attemptId);
    // Set a new parameter or update an existing one
    params.set("attemptId", encryptedattemptId);
    // Get the current URL
    var urls = window.location.href;
    // Create a new URL with the updated parameters
    var newUrl = decodeURIComponent(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.toString()
    );
    // Modify the URL without reloading the page
    window.history.pushState({ path: newUrl }, "", newUrl);

    axios.get(Constants.getUrls.attempts + "/" + id).then((resp) => {
      console.log(resp);
      if (resp.data.status === "suceess") {
        let onchangeWings = [];
        this.state.batchWings.map((bwings) => {
          console.log(bwings);
          console.log(resp.data.data.id);
          if (bwings.batch_wing.attempt_id.includes(resp.data.data.id)) {
            onchangeWings.push(bwings);
          }
        });
        console.log(onchangeWings);
        var params = new URLSearchParams(window.location.search);
        // var encryptedbatchId = btoa(this.state.batchWings[0].batch_wings_id);
        var encryptedbatchId = btoa(onchangeWings[0]);
        console.log(encryptedbatchId);
        // Set a new parameter or update an existing one
        // params.set("batchId", encryptedbatchId);
        var newUrl = decodeURIComponent(
          window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            "?" +
            params.toString()
        );
        // Modify the URL without reloading the page
        window.history.pushState({ path: newUrl }, "", newUrl);

        this.setState(
          {
            attemptName: resp.data.data.title,
            attemptId: resp.data.data.id,
            // batchWings: resp.data.batch_wing,
            // batchId: this.state.batchWings[0].batch_wings_id
            batchId: onchangeWings[0]?.batch_wings_id,
            batchWings: onchangeWings,
          },
          () => {
            console.log(this.state.batchWings);
            console.log(this.state.batchId);

            const retryRequest = () => {
              axios
                .get(
                  Constants.getUrls.coursesAll +
                    this.state.subjectId +
                    "?batch_wings_id=" +
                    this.state.batchId +
                    "&language=" +
                    this.state.language +
                    "&attempt=" +
                    this.state.attemptId +
                    "&subject_filter_id=" +
                    this.state.subject_filter_id
                )
                .then((respAll) => {
                  console.log(respAll);
                  let allCourses = [];
                  respAll.data.data.wingsCourses.forEach((item) => {
                    if (
                      (item.course.project_course_status === "both" ||
                        item.course.project_course_status === "ak1") &&
                      item.course.IS_DELETED === 0
                    ) {
                      allCourses.push(item);
                    }
                  });
                  let activeCourses = [];
                  allCourses.forEach((item) => {
                    if (item.course.STATUS === "ACTIVE") {
                      activeCourses.push(item);
                    }
                    // if(
                    //   (item.course.STATUS === "ACTIVE") &&
                    //   item.batch.is_validity === "Yes"
                    // ) {
                    //     activeCourses.push(item);
                    //   }
                  });
                  this.setState(
                    {
                      courses: activeCourses,
                      loadingWing: false,
                      price: respAll.data
                        ? respAll.data.course
                          ? respAll.data.course.variant
                            ? respAll.data.course.variant[0]
                              ? respAll.data.course.variant[0].price
                              : null
                            : null
                          : null
                        : null,
                      courseId: respAll.data
                        ? respAll.data._id
                          ? respAll.data._id
                          : 0
                        : 0,
                      isLoading: false,
                    },
                    () => {
                      const GetDays = (d, Mention_today = false) => {
                        var DateArray = [];
                        var days = d;
                        for (var i = 0; i < days; i++) {
                          if (!Mention_today && i === 0) {
                            i = 1;
                            days += 1;
                          }
                          var date = new Date();
                          var last = new Date(
                            date.getTime() + i * 24 * 60 * 60 * 1000
                          );
                          var day = last.getDate().toString();
                          var month = (last.getMonth() + 1).toString();
                          var year = last.getFullYear();
                          var monthSplit = month.split("");
                          var daySplit = day.split("");
                          const fulld =
                            Number(year) +
                            "-" +
                            (monthSplit[1]
                              ? Number(month)
                              : "0" + monthSplit[0]) +
                            "-" +
                            (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                          DateArray.push(fulld);
                        }
                        return DateArray;
                      };
                      this.setState(
                        {
                          getUpcomingDays: GetDays(100),
                        },
                        () => {
                          // console.log(this.state.getUpcomingDays);
                        }
                      );
                      let arr1 = this.state.courses;
                      let arr2 = this.state.getUpcomingDays;
                      let arr3 =
                        this.state.getUpcomingDays !== undefined &&
                        arr1.filter((arr1Item) =>
                          arr2.includes(
                            arr1Item
                              ? arr1Item.batch
                                ? arr1Item.batch.registration_start_date
                                  ? arr1Item.batch.registration_start_date
                                  : ""
                                : ""
                              : ""
                          )
                        );
                      this.setState({
                        sameDate: arr3,
                        same: true,
                      });
                    }
                  );
                })
                .catch((error) => {
                  if (error.response && error.response.status === 429) {
                    // Retry the request after 5 seconds
                    // setTimeout(retryRequest, 5000);
                  } else {
                    // Handle other errors
                    console.error(error);
                    this.setState({ isLoading: false });
                  }
                });
            };

            retryRequest(); // Call the retryRequest function to initiate the first request
          }
        );
      }
      // window.location.reload();
    });
  };

  dateFormat = (cdate) => {
    let date = moment(cdate).format("Do MMM YYYY");
    return date;
  };

  changeLanguage = async (language) => {
    const retryAttempts = 3; // Number of retry attempts
    const retryDelay = 1000; // Delay between retry attempts in milliseconds

    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var encryptedlanguage = language;
    // Set a new parameter or update an existing one
    params.set("language", encryptedlanguage);
    // Get the current URL
    var urls = window.location.href;
    // Create a new URL with the updated parameters
    var newUrl = decodeURIComponent(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.toString()
    );
    // var newUrl = decodedURL;

    // Modify the URL without reloading the page
    window.history.pushState({ path: newUrl }, "", newUrl);

    this.setState({ isLoading: true });
    if (language === "Hindi") {
      this.setState({
        backHindiColor: true,
        backEnglishColor: false,
      });
    } else {
      this.setState({
        backHindiColor: false,
        backEnglishColor: true,
      });
    }
    this.setState({
      loadingWing: true,
    });

    this.setState(
      {
        language: language,
      },
      async () => {
        // await new Promise((resolve) => setTimeout(resolve, 5000)); // Introduce a delay of 5 seconds

        const payload = {
          course_id: this.state.courseId,
          batch_wings_id: this.state.batchId,
          language: this.state.language,
        };

        let retryCount = 0;

        while (retryCount < retryAttempts) {
          try {
            const respAll = await axios.get(
              Constants.getUrls.coursesAll +
                this.state.subjectId +
                "?batch_wings_id=" +
                this.state.batchId +
                "&language=" +
                this.state.language +
                "&attempt=" +
                this.state.attemptId +
                "&subject_filter_id=" +
                this.state.subject_filter_id
            );

            let allCourses = [];
            respAll.data.data.wingsCourses.forEach((item) => {
              if (
                (item.course.project_course_status === "both" ||
                  item.course.project_course_status === "ak1") &&
                item.course.IS_DELETED === 0
              ) {
                allCourses.push(item);
              }
            });
            let activeCourses = [];
            allCourses.forEach((item) => {
              if (item.course.STATUS === "ACTIVE") {
                activeCourses.push(item);
              }
              // if(
              //   (item.course.STATUS === "ACTIVE") &&
              //   item.batch.is_validity === "Yes"
              // ) {
              //     activeCourses.push(item);
              //   }
            });

            this.setState(
              {
                courses: activeCourses,
                loadingWing: false,
                price: respAll.data
                  ? respAll.data.course
                    ? respAll.data.course.variant
                      ? respAll.data.course.variant[0]
                        ? respAll.data.course.variant[0].price
                        : null
                      : null
                    : null
                  : null,
                courseId: respAll.data
                  ? respAll.data._id
                    ? respAll.data._id
                    : 0
                  : 0,
                isLoading: false,
              },
              () => {
                const GetDays = (d, Mention_today = false) => {
                  // ...
                };

                this.setState(
                  {
                    getUpcomingDays: GetDays(100),
                  },
                  () => {
                    console.log(this.state.getUpcomingDays);
                  }
                );

                let arr1 = this.state.courses;
                let arr2 = this.state.getUpcomingDays;
                let arr3 =
                  this.state.getUpcomingDays !== undefined &&
                  arr1.filter((arr1Item) =>
                    arr2.includes(
                      arr1Item
                        ? arr1Item.batch
                          ? arr1Item.batch.registration_start_date
                            ? arr1Item.batch.registration_start_date
                            : ""
                          : ""
                        : ""
                    )
                  );

                this.setState({
                  sameDate: arr3,
                  same: true,
                });
              }
            );

            break; // Break out of the retry loop if request is successful
          } catch (error) {
            if (error.response && error.response.status === 429) {
              // Handle rate limit exceeded error
              console.log(
                "Too many requests. Retrying in " + retryDelay + "ms..."
              );
              await new Promise((resolve) => setTimeout(resolve, retryDelay));
              retryCount++;
            } else {
              // Handle other errors
              console.log("An error occurred.");
              break; // Break out of the retry loop for other errors
            }
          }
        }

        if (retryCount === retryAttempts) {
          console.log(
            "Exceeded maximum retry attempts. Please try again later."
          );
        }
      }
    );
  };

  getCoursesWing = (batchWingId, e, index) => {
    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var encryptedbatchId = btoa(batchWingId);
    // Set a new parameter or update an existing one
    // params.set("batchId", encryptedbatchId);
    // Get the current URL
    var urls = window.location.href;

    // Decode the URL
    // var decodedURL = decodeURIComponent(urls);
    // Create a new URL with the updated parameters
    var newUrl = decodeURIComponent(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.toString()
    );
    // var newUrl = decodedURL;

    // Modify the URL without reloading the page
    window.history.pushState({ path: newUrl }, "", newUrl);

    this.setState({ isLoading: true, batchId: batchWingId });
    axios
      .get(
        Constants.getUrls.coursesAll +
          this.state.subjectId +
          "?batch_wings_id=" +
          batchWingId +
          "&language=" +
          this.state.language +
          "&attempt=" +
          this.state.attemptId +
          "&subject_filter_id=" +
          this.state.subject_filter_id
      )
      .then((resp) => {
        let allCourses = [];
        resp.data.data.wingsCourses.forEach((item) => {
          if (
            (item.course.project_course_status === "both" ||
              item.course.project_course_status === "ak1") &&
            item.course.IS_DELETED === 0
          ) {
            allCourses.push(item);
          }
        });
        let activeCourses = [];
        allCourses.forEach((item) => {
          if (item.course.STATUS === "ACTIVE") {
            activeCourses.push(item);
          }
          // if(
          //   (item.course.STATUS === "ACTIVE") &&
          //   item.batch.is_validity === "Yes"
          // ) {
          //     activeCourses.push(item);
          //   }
        });
        this.setState(
          {
            courses: activeCourses,

            loadingWing: false,
            batchId: batchWingId,
            price: resp.data
              ? resp.data.course
                ? resp.data.course.variant
                  ? resp.data.course.variant[0]
                    ? resp.data.course.variant[0].price
                    : null
                  : null
                : null
              : null,
            isLoading: false,
          },
          () => {
            const GetDays = (d, Mention_today = false) => {
              var DateArray = [];
              var days = d;
              for (var i = 0; i < days; i++) {
                if (!Mention_today && i == 0) {
                  i = 1;
                  days += 1;
                }
                var date = new Date();
                var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);
                var day = last.getDate().toString();
                var month = (last.getMonth() + 1).toString();
                var year = last.getFullYear();
                var monthSplit = month.split("");
                var daySplit = day.split("");
                const fulld =
                  Number(year) +
                  "-" +
                  (monthSplit[1] ? Number(month) : "0" + monthSplit[0]) +
                  "-" +
                  (daySplit[1] ? Number(day) : "0" + daySplit[0]);
                DateArray.push(fulld);
              }
              return DateArray;
            };
            this.setState(
              {
                getUpcomingDays: GetDays(100),
              },
              () => {
                console.log(this.state.getUpcomingDays);
              }
            );
            let arr1 = this.state.courses;
            let arr2 = this.state.getUpcomingDays;
            let arr3 =
              this.state.getUpcomingDays !== undefined && arr1
                ? arr1.filter((arr1Item) =>
                    arr2.includes(
                      arr1Item
                        ? arr1Item.batch
                          ? arr1Item.batch.registration_start_date
                            ? arr1Item.batch.registration_start_date
                            : ""
                          : ""
                        : ""
                    )
                  )
                : null;
            this.setState({
              sameDate: arr3,
              same: true,
            });
          }
        );
      });
    //   }
    // );
    // }
  };

  checkedBatch = (id) => {
    let wingsId = id;
    let istrue = false;
    if (this.state.array_batch_wings_id.length > 0) {
      let batch = this.state.array_batch_wings_id.find((v) => v.id === id);
      if (batch) {
        istrue = true;
      } else {
        istrue = false;
      }
    } else {
      istrue = false;
    }
    return istrue;
  };
  checkedSubjects = (id) => {
    let istrue = false;
    if (this.state.array_subject_filter_ids.length > 0) {
      let subject = this.state.array_subject_filter_ids.find(
        (v) => v.id === id
      );
      if (subject) {
        istrue = true;
      } else {
        istrue = false;
      }
    } else {
      istrue = false;
    }
    return istrue;
  };
  // changeActive = (e) => {
  //   const value = e.target.value;
  //   let arrayId =[this.state.array_batch_wings_id]
  //   if(!arrayId.includes(value)){
  //     arrayId.push({"id":value});
  //   }
  //   this.getCoursesWing(value)

  // let ischeck = false;
  // this.state.array_batch_wings_id.forEach((v) => {
  //   if (v.id !== value) {
  //     this.state.array_batch_wings_id.push({ id: value });
  //   }
  // });
  // };

  // changeActive=(e)=>{
  // }

  getCoursesFilter = (id) => {
    // currentUrlParams.searchParams.set("subject_filter_id", id);
    // window.history.pushState({}, "", window.location.assign(currentUrlParams));

    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var encryptedsubject_filter_id = btoa(id);
    // Set a new parameter or update an existing one
    params.set("subject_filter_id", encryptedsubject_filter_id);
    // Get the current URL
    var urls = window.location.href;

    // Decode the URL
    // var decodedURL = decodeURIComponent(urls);
    // Create a new URL with the updated parameters
    var newUrl = decodeURIComponent(
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        params.toString()
    );
    // var newUrl = decodedURL;

    // Modify the URL without reloading the page
    window.history.pushState({ path: newUrl }, "", newUrl);

    this.setState(
      {
        subject_filter_id: id,
        isLoading: true,
      },
      () => {
        axios
          .get(
            Constants.getUrls.coursesAll +
              this.state.subjectId +
              "?batch_wings_id=" +
              this.state.batchId +
              "&language=" +
              this.state.language +
              "&attempt=" +
              this.state.attemptId +
              "&subject_filter_id=" +
              id
          )
          .then((resp) => {
            let allCourses = [];
            resp.data.data.wingsCourses.forEach((item) => {
              if (
                (item.course.project_course_status === "both" ||
                  item.course.project_course_status === "ak1") &&
                item.course.IS_DELETED === 0
              ) {
                allCourses.push(item);
              }
            });
            let activeCourses = [];
            allCourses.forEach((item) => {
              if (item.course.STATUS === "ACTIVE") {
                activeCourses.push(item);
              }
              // if(
              //   (item.course.STATUS === "ACTIVE") &&
              //   item.batch.is_validity === "Yes"
              // ) {
              //     activeCourses.push(item);
              //   }
            });
            this.setState(
              {
                courses: activeCourses,
                isLoading: false,

                // loadingWing: false,
                // price: resp.data
                //   ? resp.data.course
                //     ? resp.data.course.variant
                //       ? resp.data.course.variant[0]
                //         ? resp.data.course.variant[0].price
                //         : null
                //       : null
                //     : null
                //   : null,
              }
              // () => {
              //   const GetDays = (d, Mention_today = false) => {
              //     var DateArray = [];
              //     var days = d;
              //     for (var i = 0; i < days; i++) {
              //       if (!Mention_today && i == 0) {
              //         i = 1;
              //         days += 1;
              //       }
              //       var date = new Date();
              //       var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);
              //       var day = last.getDate().toString();
              //       var month = (last.getMonth() + 1).toString();
              //       var year = last.getFullYear();
              //       var monthSplit = month.split("");
              //       var daySplit = day.split("");
              //       const fulld =
              //         Number(year) +
              //         "-" +
              //         (monthSplit[1] ? Number(month) : "0" + monthSplit[0]) +
              //         "-" +
              //         (daySplit[1] ? Number(day) : "0" + daySplit[0]);
              //       DateArray.push(fulld);
              //     }
              //     return DateArray;
              //   };
              //   this.setState({
              //     getUpcomingDays: GetDays(100),
              //   });
              //   let arr1 = this.state.courses;
              //   let arr2 = this.state.getUpcomingDays;
              //   let arr3 = arr1
              //     ? arr1.filter((arr1Item) =>
              //         arr2.includes(
              //           arr1Item
              //             ? arr1Item.batch
              //               ? arr1Item.batch.registration_start_date
              //                 ? arr1Item.batch.registration_start_date
              //                 : ""
              //               : ""
              //             : ""
              //         )
              //       )
              //     : null;
              //   this.setState({
              //     sameDate: arr3,
              //     same: true,
              //   });
              // }
            );
          })
          .catch((error) => {});
      }
    );
  };
  selectBatch = (subject_id, batch_id, course_id, batch_wings_id) => {
    window.location.href =
      "/course_details/" +
      this.state.streamId +
      "/" +
      this.state.attemptId +
      "/" +
      subject_id +
      "/" +
      batch_id +
      "/" +
      course_id +
      "/" +
      batch_wings_id;
  };
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notFound,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div className="CoursesPage">
        <Header />
        <div className="Responsive">
          <CourseLandingResp />
        </div>
        <div className="WebLanding">
          <section className="section1">
            <h1 className="fonts"> {this.state.subjectName}</h1>
          </section>
          <section className="section2">
            <span className="showing">
              Showing Courses for{" "}
              <span className="jun">{this.state.attemptName} Attempt</span>{" "}
              <select
                name="attempt"
                id=""
                value={this.state.attemptId}
                className="selectAttCourse"
                onChange={(e) => {
                  this.setState(
                    {
                      attemptShow: true,
                      attemptId: e.target.value,
                    },
                    () => {
                      this.fetchData(this.state.attemptId);
                      // window.location.reload();
                    }
                  );
                }}
              >
                {this.state.attempts.map((attempt) => (
                  <option value={attempt.id}>{attempt.title}</option>
                ))}
              </select>
            </span>
          </section>
          <section className="section3 container">
            <div className="row">
              <div className="col-sm-3">
                <span>
                  <button
                    className={
                      this.state.language === "Hindi"
                        ? "blueBtn"
                        : "grayBtnSelect"
                    }
                    onClick={() => this.changeLanguage("Hindi")}
                  >
                    Hindi
                  </button>
                  <button
                    className={
                      this.state.language === "English"
                        ? "blueBtnSelect"
                        : "grayBtn"
                    }
                    onClick={() => {
                      this.changeLanguage("English");
                    }}
                  >
                    English
                  </button>
                </span>
                <div className="lectureNote">
                  <h5 className="lectures">
                    Lectures are available in English for South Indian Students.
                  </h5>
                </div>
                <div className="batchType">
                  <h3 className="typeName">Batch Type</h3>
                  {this.state.batchWings.map((wing, i) => (
                    <div className="checkDiv">
                      {wing.batch_wing?.title && (
                        <button
                          className="batch_button"
                          name="batch"
                          onClick={(e) => {
                            this.getCoursesWing(wing.batch_wing.id, e, i);
                          }}
                        >
                          {/* {this.state.batchId === wing.batch_wing?.id ? ( */}
                          {parseInt(this.state.batchId) ===
                          parseInt(wing.batch_wings_id) ? (
                            <img className="button_radio" src={checked_radio} />
                          ) : (
                            <img
                              className="button_radio"
                              src={unchecked_radio}
                            />
                          )}{" "}
                          <span>
                            {wing.batch_wing?.title
                              ? wing.batch_wing?.title
                              : ""}
                          </span>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                <div className="chooseSubjects">
                  <h3 className="typeName">Choose Subject</h3>
                  {this.state.subjectFilter.map((course) => (
                    <div className="checkDiv1">
                      {course.title && (
                        <button
                          className="batch_button"
                          // type="radio"
                          // id={course.id}
                          // value={course.id}
                          name="subject"
                          // checked={this.checkedSubjects(course.id)}
                          onClick={(e) => {
                            this.getCoursesFilter(course.id);
                          }}
                        >
                          {/* {this.state.subject_filter_id === course.id ? ( */}
                          {parseInt(this.state.subject_filter_id) ===
                          course.id ? (
                            <img className="button_radio" src={checked_radio} />
                          ) : (
                            <img
                              className="button_radio"
                              src={unchecked_radio}
                            />
                          )}{" "}
                          <span>{course.title}</span>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-sm-9">
                {this.state.isLoading ? (
                  <>
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        marginLeft: "44%",
                      }}
                    >
                      Please Wait
                    </span>
                    <Lottie
                      options={defaultLoader}
                      height={80}
                      width={80}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  </>
                ) : (
                  <section className="section4">
                    <div className="container" style={{ width: "129%" }}>
                      {this.state.courses.length > 0 ? (
                        this.state.courses.map((batch) =>
                          batch.course.stock_status === 0 ? (
                            <div className="col-md-4 margSec4">
                              <div className="row">
                                <div
                                  className="col-md-7 newRes"
                                  style={{ marginLeft: "-12px" }}
                                >
                                  {batch?.batch?.registration_end_date ==
                                  null ? (
                                    ""
                                  ) : batch?.batch?.registration_end_date ==
                                    "0000-00-00" ? (
                                    ""
                                  ) : (
                                    <h5
                                      className="registration"
                                      style={{
                                        marginLeft: "8px",
                                        width: "160px",
                                      }}
                                    >
                                      Registration Ends on &nbsp;
                                      {this.dateFormat(
                                        batch?.batch?.registration_end_date
                                      )}
                                    </h5>
                                  )}
                                </div>
                                <div className="col-md-5 newRes">
                                  <button
                                    className="batchButton"
                                    style={{
                                      backgroundColor: batch?.batch.color,
                                      fontSize:
                                        batch?.batch?.batch_name.length >= 14
                                          ? "13px"
                                          : "15px",
                                    }}
                                  >
                                    {batch?.batch?.batch_name}
                                  </button>
                                </div>
                              </div>
                              <div className="row">
                                <div className="courseDivArea">
                                  {/* {this.state.getUpcomingDays.includes(
                                  batch?.batch?.registration_end_date
                                ) ? (
                                  ""
                                ) : (
                                  <div className="overLayWrap"></div>
                                )} */}
                                  {moment().format("YYYY-MM-DD") ===
                                    moment(batch?.batch?.registration_end_date)
                                      .add(1, "month")
                                      .startOf("month")
                                      .format("YYYY-MM-DD") && (
                                    <div className="overLayWrap"></div>
                                  )}
                                  <div className="batchBackColor">
                                    <img
                                      src={
                                        Constants.imgUrl +
                                        "/" +
                                        batch.course.IMAGE_PATH
                                      }
                                      alt=""
                                      width={290}
                                      height={165}
                                    />

                                    <div className="row">
                                      <div className="daysAreaCourse">
                                        <div style={{ textAlign: "center" }}>
                                          <h5 className="subnaame">
                                            {batch.course.TITLE}
                                          </h5>
                                        </div>
                                        <div className="daysDivCourse">
                                          {batch?.course?.amenity_details?.map(
                                            (amenity) => {
                                              let item = amenity.descriptions
                                                .replace(/<br>/g, "")
                                                .replace(/<div>/g, "");
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    margin: "0.5rem 0rem",
                                                  }}
                                                >
                                                  {/* <h5
                                                  className="daysCourse"
                                                  key={amenity.id}
                                                > */}
                                                  <i class="fa-solid fa-check hexCode"></i>
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: item,
                                                    }}
                                                  />
                                                  {/* <span
                                                    className="totalDays"
                                                    // dangerouslySetInnerHTML={{
                                                    //   __html: item,
                                                    // }}
                                                  >
                                                    {renderHTML(item)}
                                                  </span> */}
                                                  {/* </h5> */}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="enrollAreaCourse">
                                      <div
                                        className="row"
                                        style={{
                                          // opacity:
                                          //   !this.state.getUpcomingDays.includes(
                                          //     batch.batch?.registration_end_date
                                          //   ) &&
                                          //   !this.state.getLastDays.find(
                                          //     (item) =>
                                          //       item.batch
                                          //         .registration_end_date ===
                                          //       batch.batch.registration_end_date
                                          //   )
                                          //     ? 0.5
                                          //     : 1,
                                          opacity:
                                            moment().format("YYYY-MM-DD") ===
                                            moment(
                                              batch?.batch
                                                ?.registration_end_date
                                            )
                                              .add(1, "month")
                                              .startOf("month")
                                              .format("YYYY-MM-DD")
                                              ? 0.5
                                              : 1,
                                        }}
                                      >
                                        <div className="col-md-4">
                                          <h5
                                            className="hexTime"
                                            style={{
                                              color: batch?.batch.color,
                                            }}
                                          >
                                            {/* <span
                                            style={{
                                              marginRight: "4px",
                                              color: batch?.batch.color,
                                            }}
                                          >
                                            
                                            &#9201;
                                          </span> */}
                                            <i
                                              class="fa-regular fa-clock"
                                              style={{
                                                marginRight: "4px",
                                                color: batch?.batch.color,
                                              }}
                                            ></i>
                                            {batch.course
                                              ? batch.course.DURATION
                                              : // + " Hrs."
                                                ""}
                                          </h5>
                                        </div>
                                        <div className="col-md-5">
                                          {this.state.getUpcomingDays !==
                                            undefined &&
                                          !this.state.getUpcomingDays?.includes(
                                            batch.batch?.registration_end_date
                                          ) &&
                                          !this.state.getLastDays.find(
                                            (item) =>
                                              item.batch
                                                .registration_end_date ===
                                              batch.batch.registration_end_date
                                          ) ? (
                                            batch.batch
                                              .registration_end_date ===
                                              "0000-00-00" ||
                                            batch.batch
                                              .registration_end_date ===
                                              null ? (
                                              <Link
                                                to={
                                                  "/course_details/" +
                                                  this.state.streamId +
                                                  "/" +
                                                  this.state.attemptId +
                                                  "/" +
                                                  this.state.subjectId +
                                                  "/" +
                                                  batch.batch_id +
                                                  "/" +
                                                  batch.course_id +
                                                  "/" +
                                                  batch.batch_wings_id
                                                }
                                              >
                                                <button
                                                  className="enrollBtnCourse"
                                                  style={{
                                                    backgroundColor:
                                                      batch?.batch.color,
                                                  }}
                                                >
                                                  Enroll Now
                                                </button>
                                              </Link>
                                            ) : batch.batch
                                                .registration_end_date <
                                              today ? (
                                              <Link
                                                to={
                                                  "/course_details/" +
                                                  this.state.streamId +
                                                  "/" +
                                                  this.state.attemptId +
                                                  "/" +
                                                  this.state.subjectId +
                                                  "/" +
                                                  batch.batch_id +
                                                  "/" +
                                                  batch.course_id +
                                                  "/" +
                                                  batch.batch_wings_id
                                                }
                                              >
                                                <button
                                                  className="enrollBtnCourseRegEnd"
                                                  style={{
                                                    backgroundColor:
                                                      batch?.batch.color,
                                                  }}
                                                  disabled
                                                >
                                                  Registration Ends
                                                </button>
                                              </Link>
                                            ) : (
                                              <Link
                                                to={
                                                  "/course_details/" +
                                                  this.state.streamId +
                                                  "/" +
                                                  this.state.attemptId +
                                                  "/" +
                                                  this.state.subjectId +
                                                  "/" +
                                                  batch.batch_id +
                                                  "/" +
                                                  batch.course_id +
                                                  "/" +
                                                  batch.batch_wings_id
                                                }
                                              >
                                                <button
                                                  className="enrollBtnCourse"
                                                  style={{
                                                    backgroundColor:
                                                      batch?.batch.color,
                                                  }}
                                                >
                                                  Enroll Now
                                                </button>
                                              </Link>
                                            )
                                          ) : batch.batch
                                              .registration_end_date ===
                                              "0000-00-00" ||
                                            batch.batch
                                              .registration_end_date ===
                                              null ? (
                                            <Link
                                              to={
                                                "/course_details/" +
                                                this.state.streamId +
                                                "/" +
                                                this.state.attemptId +
                                                "/" +
                                                this.state.subjectId +
                                                "/" +
                                                batch.batch_id +
                                                "/" +
                                                batch.course_id +
                                                "/" +
                                                batch.batch_wings_id
                                              }
                                            >
                                              <button
                                                className="enrollBtnCourse"
                                                style={{
                                                  backgroundColor:
                                                    batch?.batch.color,
                                                }}
                                              >
                                                Enroll Now
                                              </button>
                                            </Link>
                                          ) : batch.batch
                                              .registration_end_date < today ? (
                                            <Link
                                              to={
                                                "/course_details/" +
                                                this.state.streamId +
                                                "/" +
                                                this.state.attemptId +
                                                "/" +
                                                this.state.subjectId +
                                                "/" +
                                                batch.batch_id +
                                                "/" +
                                                batch.course_id +
                                                "/" +
                                                batch.batch_wings_id
                                              }
                                            >
                                              <button
                                                className="enrollBtnCourseRegEnd"
                                                style={{
                                                  backgroundColor:
                                                    batch?.batch.color,
                                                }}
                                                disabled
                                              >
                                                Registration Ends
                                              </button>
                                            </Link>
                                          ) : (
                                            <Link
                                              to={
                                                "/course_details/" +
                                                this.state.streamId +
                                                "/" +
                                                this.state.attemptId +
                                                "/" +
                                                this.state.subjectId +
                                                "/" +
                                                batch.batch_id +
                                                "/" +
                                                batch.course_id +
                                                "/" +
                                                batch.batch_wings_id
                                              }
                                            >
                                              <button
                                                className="enrollBtnCourse"
                                                style={{
                                                  backgroundColor:
                                                    batch?.batch.color,
                                                }}
                                              >
                                                Enroll Now
                                              </button>
                                            </Link>
                                          )}
                                        </div>
                                        <div className="col-md-3">
                                          <h5
                                            className="courseAmt"
                                            style={{
                                              color: batch?.batch.color,
                                            }}
                                          >
                                            {batch.course
                                              ? batch.course.variant
                                                ? batch.course.variant[0]
                                                  ? batch.course.variant[0]
                                                      .price
                                                  : batch.course.PRICE
                                                : batch.course.PRICE
                                              : batch.course.PRICE}
                                            /-
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <span
                                style={{
                                  fontSize: "40px",
                                  position: "absolute",
                                  zIndex: "999",
                                  top: "24%",
                                  left: "5%",
                                  color: "#000",
                                  fontWeight: "bold",
                                }}
                              >
                                Out of Stock
                              </span>
                              <div
                                className="col-md-4 margSec4"
                                style={{
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "18px",
                                  filter: "blur(1.5px)",
                                  pointerEvents: "none",
                                }}
                              >
                                <div className="row">
                                  <div
                                    className="col-md-7 newRes"
                                    style={{ marginLeft: "-12px" }}
                                  >
                                    <h5
                                      className="registration"
                                      style={{ marginLeft: "8px" }}
                                    >
                                      Registration Ends on &nbsp;
                                      {this.dateFormat(
                                        batch?.batch?.registration_end_date
                                      )}
                                    </h5>
                                  </div>
                                  <div className="col-md-5 newRes">
                                    <button
                                      className="batchButton"
                                      style={{
                                        backgroundColor: batch?.batch.color,
                                      }}
                                    >
                                      {batch?.batch?.batch_name}
                                    </button>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="courseDivArea">
                                    {/* {this.state.getUpcomingDays.includes(
                                    batch?.batch?.registration_end_date
                                  ) ? (
                                    ""
                                  ) : (
                                    <div className="overLayWrap"></div>
                                  )} */}
                                    {moment().format("YYYY-MM-DD") ===
                                      moment(
                                        batch?.batch?.registration_end_date
                                      )
                                        .add(1, "month")
                                        .startOf("month")
                                        .format("YYYY-MM-DD") && (
                                      <div className="overLayWrap"></div>
                                    )}
                                    <div className="batchBackColor">
                                      <img
                                        src={
                                          Constants.imgUrl +
                                          "/" +
                                          batch.course.IMAGE_PATH
                                        }
                                        alt=""
                                        width={290}
                                        height={165}
                                      />

                                      <div className="row">
                                        <div className="daysAreaCourse">
                                          <div style={{ textAlign: "center" }}>
                                            <h5 className="subnaame">
                                              {batch.course.TITLE}
                                            </h5>
                                          </div>
                                          <div className="daysDivCourse">
                                            {batch?.course?.amenity_details?.map(
                                              (amenity) => {
                                                let item = amenity.descriptions
                                                  .replace(/<br>/g, "")
                                                  .replace(/<div>/g, "");
                                                return (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      margin: "0.5rem 0rem",
                                                    }}
                                                  >
                                                    {/* <h5
                                                    className="daysCourse"
                                                    key={amenity.id}
                                                  > */}
                                                    <i class="fa-solid fa-check hexCode"></i>
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html: item,
                                                      }}
                                                    />
                                                    {/* <span
                                                      className="totalDays"
                                                      // dangerouslySetInnerHTML={{
                                                      //   __html: item,
                                                      // }}
                                                    >
                                                      {renderHTML(item)}
                                                    </span> */}
                                                    {/* </h5> */}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="enrollAreaCourse">
                                        <div
                                          className="row"
                                          style={{
                                            // opacity:
                                            //   !this.state.getUpcomingDays.includes(
                                            //     batch.batch?.registration_end_date
                                            //   ) &&
                                            //   !this.state.getLastDays.find(
                                            //     (item) =>
                                            //       item.batch
                                            //         .registration_end_date ===
                                            //       batch.batch.registration_end_date
                                            //   )
                                            //     ? 0.5
                                            //     : 1,
                                            opacity:
                                              moment().format("YYYY-MM-DD") ===
                                              moment(
                                                batch?.batch
                                                  ?.registration_end_date
                                              )
                                                .add(1, "month")
                                                .startOf("month")
                                                .format("YYYY-MM-DD")
                                                ? 0.5
                                                : 1,
                                          }}
                                        >
                                          <div className="col-md-4">
                                            <h5
                                              className="hexTime"
                                              style={{
                                                color: batch?.batch.color,
                                              }}
                                            >
                                              {/* <span
                                              style={{
                                                marginRight: "4px",
                                                color: batch?.batch.color,
                                              }}
                                            >
                                              
                                              &#9201;
                                            </span> */}
                                              <i
                                                class="fa-regular fa-clock"
                                                style={{
                                                  marginRight: "4px",
                                                  color: batch?.batch.color,
                                                }}
                                              ></i>
                                              {batch.course
                                                ? batch.course.DURATION
                                                : // + " Hrs."
                                                  ""}
                                            </h5>
                                          </div>
                                          <div className="col-md-5">
                                            {!this.state.getUpcomingDays.includes(
                                              batch.batch?.registration_end_date
                                            ) &&
                                            !this.state.getLastDays.find(
                                              (item) =>
                                                item.batch
                                                  .registration_end_date ===
                                                batch.batch
                                                  .registration_end_date
                                            ) ? (
                                              <Link
                                                to={
                                                  "/course_details/" +
                                                  this.state.streamId +
                                                  "/" +
                                                  this.state.attemptId +
                                                  "/" +
                                                  this.state.subjectId +
                                                  "/" +
                                                  batch.batch_id +
                                                  "/" +
                                                  batch.course_id +
                                                  "/" +
                                                  batch.batch_wings_id
                                                }
                                              >
                                                <button
                                                  className="enrollBtnCourse"
                                                  style={{
                                                    backgroundColor:
                                                      batch?.batch.color,
                                                  }}
                                                >
                                                  Enroll Now
                                                </button>
                                              </Link>
                                            ) : (
                                              <Link
                                                to={
                                                  "/course_details/" +
                                                  this.state.streamId +
                                                  "/" +
                                                  this.state.attemptId +
                                                  "/" +
                                                  this.state.subjectId +
                                                  "/" +
                                                  batch.batch_id +
                                                  "/" +
                                                  batch.course_id +
                                                  "/" +
                                                  batch.batch_wings_id
                                                }
                                              >
                                                <button
                                                  className="enrollBtnCourse"
                                                  style={{
                                                    backgroundColor:
                                                      batch?.batch.color,
                                                  }}
                                                >
                                                  Enroll Now
                                                </button>
                                              </Link>
                                            )}
                                          </div>
                                          <div className="col-md-3">
                                            <h5
                                              className="courseAmt"
                                              style={{
                                                color: batch?.batch.color,
                                              }}
                                            >
                                              {batch.course
                                                ? batch.course.variant
                                                  ? batch.course.variant[0]
                                                    ? batch.course.variant[0]
                                                        .price
                                                    : batch.course.PRICE
                                                  : batch.course.PRICE
                                                : batch.course.PRICE}
                                              /-
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        )
                      ) : (
                        <div
                          className="col-md-4 regiBatches"
                          style={{
                            textAlign: "center",
                            marginTop: "11%",
                            marginLeft: "39%",
                            fontSize: "24px",
                            fontFamily: "raleway",
                          }}
                        >
                          <Lottie
                            options={defaultOptions}
                            height={200}
                            width={300}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                          />
                        </div>
                      )}
                    </div>
                  </section>
                )}
                <div
                  className="row"
                  style={{
                    marginTop: this.state.courses.length > 0 ? "55px" : "27%",
                  }}
                  onClick={this.showAnswer}
                >
                  <div className="batchSystem">
                    <div className="row">
                      <div className="col-md-10">
                        <h4 className="questions">
                          What is Batch System and its Benefits?
                        </h4>
                      </div>
                      {this.state.answer ? (
                        <div className="col-md-2 arrowHexRight">
                          {/* <span
                            style={{ fontSize: "30px" }}
                            onClick={this.showAnswer}
                          >
                            &#129171;
                          </span> */}
                          <i
                            class="fa-solid fa-caret-down"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer}
                          ></i>
                        </div>
                      ) : (
                        <div className="col-md-2 arrowHexRight">
                          {/* <span
                            style={{ fontSize: "30px" }}
                            onClick={this.showAnswer}
                          >
                            &#129170;
                          </span> */}
                          <i
                            class="fa-solid fa-caret-right"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer}
                          ></i>
                        </div>
                      )}
                    </div>
                    {this.state.answer ? (
                      <h5 className="answers">
                        We provide automatic lorem Ipsum is simply dummy text of
                        the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </h5>
                    ) : null}
                  </div>
                </div>

                <div
                  className="row"
                  style={{ marginTop: "20px" }}
                  onClick={this.showAnswer1}
                >
                  <div className="batchSystem">
                    <div className="row">
                      <div className="col-md-10">
                        <h4 className="questions">What is Target Strategy?</h4>
                      </div>
                      {this.state.answer1 ? (
                        <div className="col-md-2 arrowHexRight">
                          <i
                            class="fa-solid fa-caret-down"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer1}
                          ></i>
                        </div>
                      ) : (
                        <div className="col-md-2 arrowHexRight">
                          <i
                            class="fa-solid fa-caret-right"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer1}
                          ></i>
                        </div>
                      )}
                    </div>
                    {this.state.answer1 ? (
                      <h5 className="answers">
                        We provide automatic lorem Ipsum is simply dummy text of
                        the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </h5>
                    ) : null}
                  </div>
                </div>

                <div
                  className="row"
                  style={{ marginTop: "20px" }}
                  onClick={this.showAnswer2}
                >
                  <div className="batchSystem">
                    <div className="row">
                      <div className="col-md-10">
                        <h4 className="questions">
                          Importance of Live Session
                        </h4>
                      </div>
                      {this.state.answer2 ? (
                        <div className="col-md-2 arrowHexRight">
                          {/* <span
                            style={{ fontSize: "30px" }}
                            onClick={this.showAnswer2}
                          >
                            &#129171;
                          </span> */}
                          <i
                            class="fa-solid fa-caret-down"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer2}
                          ></i>
                        </div>
                      ) : (
                        <div className="col-md-2 arrowHexRight">
                          <i
                            class="fa-solid fa-caret-right"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer2}
                          ></i>
                        </div>
                      )}
                    </div>
                    {this.state.answer2 ? (
                      <h5 className="answers">
                        We provide automatic lorem Ipsum is simply dummy text of
                        the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </h5>
                    ) : null}
                  </div>
                </div>

                <div
                  className="row"
                  style={{ marginTop: "20px" }}
                  onClick={this.showAnswer3}
                >
                  <div className="batchSystem">
                    <div className="row">
                      <div className="col-md-10">
                        <h4 className="questions">
                          Know more Benefits of Doubt Portal
                        </h4>
                      </div>
                      {this.state.answer3 ? (
                        <div className="col-md-2 arrowHexRight">
                          {/* <span
                            style={{ fontSize: "30px" }}
                            onClick={this.showAnswer3}
                          >
                            &#129171;
                          </span> */}
                          <i
                            class="fa-solid fa-caret-down"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer3}
                          ></i>
                        </div>
                      ) : (
                        <div className="col-md-2 arrowHexRight">
                          {/* <span
                            style={{ fontSize: "30px" }}
                            onClick={this.showAnswer3}
                          >
                            &#129170;
                          </span> */}
                          <i
                            class="fa-solid fa-caret-right"
                            style={{ fontSize: "18px", marginTop: "10px" }}
                            onClick={this.showAnswer3}
                          ></i>
                        </div>
                      )}
                    </div>
                    {this.state.answer3 ? (
                      <h5 className="answers">
                        We provide automatic lorem Ipsum is simply dummy text of
                        the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </h5>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}
