import React, { Component } from "react";
import CarouselSlider from "react-carousel-slider";
import { Animated } from "react-animated-css";
import Modal from "react-awesome-modal";
import axios from "axios";
import "animate.css/animate.min.css";
import Constants from "./common/Constants";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import { AiFillCloseCircle } from "react-icons/ai";

export default class Popupmodel extends Component {
  state = {
    popup: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getSlideModal();
  }

  getSlideModal = () => {
    axios.get(Constants.getUrls.slider).then((res) => {
      console.log(res.data);
      this.setState(
        {
          popup: res.data,
        },
        () => {
          console.log(this.state.imageSliderModal);
        }
      );
    });
  };

  handleModal = () => {
    console.log("clicked");
    this.setState(
      {
        imageModal: true,
      },
      () => {
        console.log("modal open");
      }
    );
  };

  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  render() {
    return (
      <>
        {this.state.popup?.length > 0 ? (
          <Modal
            visible={this.state.visible}
            width="900"
            // height="400"
            style={{ marginTop: "-80px" }}
            backgroundColor="white"
            onClickAway={() => this.closeModal()}
            className="modal_pop"
          >
            <AiFillCloseCircle
              size={30}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
                color: "black",
                zIndex: "1000000000",
              }}
              onClick={() => this.closeModal()}
            ></AiFillCloseCircle>

            <div>
              <CarouselProvider
                naturalSlideWidth={200}
                naturalSlideHeight={112}
                totalSlides={this.state.popup ? this.state.popup.length : null}
                isPlaying={true}
                interval={3000}
              >
                <Slider>
                  {this.state.popup
                    ? this.state.popup.map((singleimage, index) => (
                        <Slide index={index.length}>
                          <img
                            alt={"singleimage"}
                            src={Constants.imgUrl + singleimage.IMAGE_PATH}
                            width="100%"
                            className="modal_popup"
                          />
                        </Slide>
                      ))
                    : null}
                  {/* <Slide index={1}>
                    <img
                      src="https://images.unsplash.com/photo-1506377872008-6645d9d29ef7?ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
                      width="100%"
                    />
                  </Slide> */}
                </Slider>
              </CarouselProvider>
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
}
