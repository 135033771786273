import React, { Component } from "react";
import akLogo from "../../images/akLogo.png";
import Fb from "../../images/facebook-circular-logo.png";
import twitter from "../../images/twitter.png";
import instagram from "../../images/instagram.png";
import linkedin from "../../images/linkedin.png";
import sendIcon from "../../images/paper-plane.png";
import headphones from "../../images/headphones.png";
import googlePlayImg from "../../images/google-play.png";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <>
        <div className="newFooter">
          <div className="row">
            <div className="col-sm-3">
              <div className="footer-ak-logo">
                <img src={akLogo} alt="AK Logo" />

                <div className="footer-logo-title">
                  <h1>AK CLASSES</h1>
                  <h5>Educator at Home</h5>
                </div>
              </div>
              <div
                className={
                  window.innerWidth === 1396 ? "details1396" : "Footerdetails"
                }
              >
                <h5 className="forDetail">
                  For Details: <br />
                  48/101, The Park Meadows, Kachimet, <br />
                  Amravati Road, Nagpur, 440033 <br />
                  (Maharashtra) India <br />
                  <br />
                  Email Us: bvlhelpdesk@gmail.com
                </h5>
                <br />
                <br />
                <div className=" iconFlexDiv">
                  <img src={Fb} alt="" className="fbLogo" />
                  <img src={twitter} alt="" className="fbLogo" />{" "}
                  <img src={instagram} alt="" className="fbLogo" />
                  <img src={linkedin} alt="" className="fbLogo" />
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-xs-6 fonts">
              <div>
                <h3
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.8rem",
                    marginLeft: window.innerWidth < 768 ? "0rem" : "5rem",
                  }}
                >
                  Quick Links
                </h3>
              </div>
              <div className="instructor">
                <h4>Become Instructor</h4>
                <Link
                  to="/aboutus"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <h4>About Us</h4>
                </Link>
                <Link
                  to="/privacypolicy"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <h4>Privacy Policy</h4>
                </Link>
                <Link
                  to="/termsandconditions"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <h4>Terms & Conditions</h4>
                </Link>
                <Link
                  to="/returnpolicy"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <h4>Return Policy</h4>
                </Link>
              </div>
            </div>
            <div className="col-sm-3 col-xs-6 fonts">
              <div>
                <h3 style={{ fontWeight: "bold", fontSize: "1.8rem" }}>
                  Explore
                </h3>
              </div>
              <div className="explore">
                <h4>CA Courses</h4>
                <h4>Study Material</h4>
                <h4>Franchise Centers</h4>
                <h4>Franchise Login</h4>
                <h4>Quick Enquiry</h4>
              </div>
            </div>
            <div className="col-sm-3 col-xs-12  fonts">
              {/* <div>
                <h3 className="subscribe" style={{ fontWeight: "bold" }}>
                  Subscribe Free Newsletter
                </h3>
              </div>
              <div className="mailbox">
                <span className="emailHexFooter">&#9993;</span>
                <i class="fa-regular fa-envelope emailHexFooter"></i>
                <input
                  type="text"
                  className={
                    window.innerWidth === 1396
                      ? "inputMailFooter1396"
                      : "inputMailFooter"
                  }
                  placeholder="Enter Email"
                />
                <span className="sendEmailFooter">
                  <img src={sendIcon} alt="" className="sendIcon" />
                </span>
              </div> */}
              <div className="downloadAppText">
                <h2
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Download AK Classes App
                </h2>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ak_classes_app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googlePlayImg} alt="download" />
                </a>
              </div>
            </div>
          </div>
          {window.innerWidth > 768 && (
            <div className="row footerBottom">
              <div className="col-md-4 borderRight">
                <h4 className="akName">
                  Ak Classes. <br />
                  Designed and developed by <br />
                  The
                  <span>
                    {" "}
                    <a
                      href="https://thespaceelement.com"
                      target="_blank"
                      style={{ color: "red" }}
                    >
                      Space
                    </a>{" "}
                  </span>
                  Element
                </h4>
              </div>

              <div className="col-md-4 borderRight haveQuestionMain">
                <span>
                  <img src={headphones} alt="" className="headphone" />
                  <span className="haveQuestion">
                    Have a question? Call us <br />
                    <span className="mobileNumber">+91 9356624677</span> <br />
                    <br />
                  </span>
                </span>
              </div>
              <div className="col-md-4">
                <h4 className="akNamePara">
                  In case of Pen Drive issues/ Video Lecture issues: <br />
                  Please Call: +91 8766578508 <br />
                  Call Timings: 10am to 1pm & 2pm to 5pm
                  <br />
                  In case phone is busy, please Whatsapp on +91 9356624677
                </h4>
              </div>

              <div className="col-md-4 borderRight akName1">
                <h4 className="akName1">
                  Ak Classes. <br />
                  Designed and developed by The
                  <span>
                    {" "}
                    <a
                      href="https://thespaceelement.com"
                      target="_blank"
                      style={{ color: "red" }}
                    >
                      Space
                    </a>{" "}
                  </span>
                  Element
                </h4>
              </div>
            </div>
          )}
        </div>
        {/* Mobile Footer Bottom */}
        {window.innerWidth < 768 && (
          <div className="row footerBottom mob-footer-bottom">
            <div className="col-md-4 borderRight">
              <h4 className="akName">
                Ak Classes. <br />
                Designed and developed by <br />
                The Space Element
              </h4>
            </div>

            <div className="col-md-4 borderRight haveQuestionMain">
              <span>
                <img src={headphones} alt="" className="headphone" />
                <span className="haveQuestion">
                  Have a question? Call us <br />
                  <span className="mobileNumber">
                    +91 9356624677 | 08766578508
                  </span>{" "}
                  <br />
                  <br />
                </span>
              </span>
            </div>
            <div className="col-md-4">
              <h4 className="akNamePara">
                In case of Pen Drive issues/ Video Lecture issues: <br />
                Please Call: +91 8766578508 <br />
                Call Timings: 10am to 1pm & 2pm to 5pm
                <br />
                In case phone is busy, please Whatsapp on +91 9356624677
              </h4>
            </div>

            <div className="col-md-4 borderRight akName1">
              <h4 className="akName1">
                Ak Classes. <br />
                Designed and developed by The
                <span>
                  {" "}
                  <a
                    href="https://thespaceelement.com"
                    target="_blank"
                    style={{ color: "red" }}
                  >
                    Space
                  </a>{" "}
                </span>
                Element
              </h4>
            </div>
          </div>
        )}
      </>
    );
  }
}
