import React, { Component } from "react";
import Axios from "axios";
import Constants from "../../components/common/Constants";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import NotAvailable from "./NotAvailable";
import notFound from "../../images/77703-no-data-found.json";
import loader from "../../images/97739-loader.json";
import Lottie from "react-lottie";

export default class Orders extends Component {
  state = {
    orders: [],
    empty: false,
    isLoading: true,
    // orderarray: []
  };
  componentDidMount() {
    let userToken = localStorage.getItem("token");
    Axios.get(Constants.getUrls.AllOrders, {
      headers: { Authorization: "Bearer " + userToken },
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        console.log(res);
        let orderarray = [];
        if (res.data.length > 0) {
          res.data.forEach((element) => {
            if (element.course) {
              orderarray.push(element);
            }
          });
          // console.log("orderarray", orderarray);
          this.setState(
            {
              orders: orderarray.filter(
                (v) =>
                  v.PAYMENT_STATUS === "Fail" ||
                  v.PAYMENT_STATUS === "Collected"
              ),
              isLoading: false,
              empty: res.data.length === 0 ? true : false,
            },
            () => {
              console.log(this.state.orders);
            }
          );
        }
      } else {
        this.setState({
          empty: true,
          isLoading: false,
        });
      }
    });
  }

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notFound,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        <Helmet>
          <title>
            Ak Classes CA CS CMA video lectures pendrive classes | Orders
          </title>
          <meta
            name="description"
            content="AK CLASSES. Educator at Home. Menu img. Discover your learning journey. CA ... akclasses.in."
          />
        </Helmet>
        {this.state.isLoading ? (
          <Lottie
            options={defaultLoader}
            height={80}
            width={80}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused}
          />
        ) : (
          <>
            {!this.state.empty ? (
              <div
                className="container"
                style={{ width: "88%", marginTop: "62px" }}
              >
                {this.state.orders.map((order, index) => (
                  <div>
                    <div
                      style={{
                        backgroundColor: "rgb(248, 248, 248)",
                        padding: "20px",
                        margin: "10px",
                      }}
                      key={index}
                    >
                      <div className="row">
                        <div
                          className={
                            window.innerWidth === 820
                              ? "col-xs-4"
                              : window.innerWidth === 768
                              ? "col-xs-4"
                              : "col-md-4"
                          }
                        >
                          <img
                            alt={order.course.TITLE}
                            src={
                              Constants.serverDomain + order.course.IMAGE_PATH
                            }
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="col-md-5 ">
                          <span>Product Name : </span>
                          <span>{order.course.TITLE} </span>
                          <br />

                          <h5> Order ID</h5>
                          <p>{order.ORDER_ID}</p>
                          {order.PAYMENT_STATUS === "Collected" ? (
                            <div>
                              <span
                                style={{ color: "#da6a06", fontWeight: "bold" }}
                              >
                                Product Key :
                              </span>
                              <span
                                style={{ color: "#da6a06", fontWeight: "bold" }}
                              >
                                {order.PRODUCT_KEY}{" "}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-3 ">
                          <h5>Ordered Date</h5>
                          <p> {order.ORDER_DATE} </p>
                          <h5>Total Amount</h5>
                          <p>{order.GRAND_TOTAL} </p>
                          <h5>Payment Status</h5>
                          <p>{order.PAYMENT_STATUS}</p>
                          <div style={{ display: "inline" }}>
                            <Link
                              to={{
                                pathname: "/order-detail/" + order.course.ID,
                                via: "orders",
                                state: { data: order },
                              }}
                            >
                              <button
                                className="btn btn-solid"
                                style={{ display: "inline" }}
                              >
                                View Order
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                className="col-md-4 regiBatches"
                style={{
                  textAlign: "center",
                  marginTop: "11%",
                  marginLeft: "39%",
                  fontSize: "24px",
                  fontFamily: "raleway",
                }}
              >
                <Lottie
                  options={defaultOptions}
                  height={200}
                  width={300}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
