import React, { Component } from "react";
import "../scss/CourseDetail.scss";
import subject_bg from "../images/subject_bg.jpg";
import ak_classes_book from "../images/ak_classes_book.png";
import pdf from "../images/newpdfimg.png";
import facImg from "../images/amansirimg.png";
import axios from "axios";
import Constants from "./common/Constants";
import YoutubeEmbed from "./YoutubeEmbed";
import { Link } from "react-router-dom";
import init from "../components/helper/WindowToken";
import BookCourseDetailsResp from "./Screens/BookCourseDetailsResp";
import Header from "./common/Header";
import Footer from "./common/Footer";
// import renderHTML from "react-render-html";
import renderHTML from "react-render-html";
import loader from "../images/97739-loader.json";
import Lottie from "react-lottie";

export default class BookCourseDetails extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    answer: false,
    attemptType: window.location.pathname.split("/")[5],
    batch_id: window.location.pathname.split("/")[5],
    subject_id: window.location.pathname.split("/")[4],
    course_id: "",
    batch_wings_id: window.location.pathname.split("/")[7],
    subjectDetails: "",
    courseDetails: "",
    variantDetails: [],
    showBolck: true,
    showDemo: true,
    variants: [],

    // for variant state
    oss: [],
    views: [],
    medias: [],
    osType: [],
    mediaType: [],
    viewType: [],
    variant: [],
    variant_id: "",
    offerPrice: "",
    base_offerPrice: "",
    offPrice: 0,
    isLoading: true,
    userDetails: [],
    about: "",
    data: {
      faculty_detail: {
        about_faculty: "",
      },
    },
    couponApplied: false,

    categoryId: "",
    levelId: "",
    streamId: "",
    subject_filter_id: "",
    courseId: "",
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const { location } = this.props;
    const state = location ? location.state : {};
    const books = state.books || []; // Default to empty array if books not found

    // Retrieve the current URL parameters
    var params = new URLSearchParams(window.location.search);
    var urls = window.location.href;
    //Get Params
    var categoryIdParams = params.get("categoryId");
    var levelIdParams = params.get("levelId");
    var streamIdParams = params.get("streamId");
    var subject_filter_idParams = params.get("subject_filter_id");
    var courseIdParams = params.get("courseId");

    var categoryId = atob(categoryIdParams);
    var levelId = atob(levelIdParams);
    var streamId = atob(streamIdParams);
    var subject_filter_id = atob(subject_filter_idParams);
    var courseId = atob(courseIdParams);

    this.setState(
      {
        categoryId: categoryId,
        levelId: levelId,
        streamId: streamId,
        subject_filter_id: subject_filter_id,
        courseId: courseId,
      },
      () => {
        this.getProductDetails();
        this.getUserInfo();
        this.getActiveVariants();
      }
    );
  }

  // userData = () =>{
  //   let token = localStorage.getItem("token");
  //   // if (init() === "success") {
  //     axios.get(Constants.getUrls.user).then((resUser) => {
  //       this.setState(
  //         {
  //           userDetails: resUser.data,
  //           login: true,
  //         },
  //         () => {
  //         }
  //       );
  //     });
  //   // }
  // }
  getUserInfo = () => {
    if (init() === "success") {
      axios.get(Constants.getUrls.user).then((res) => {
        this.setState({ userDetails: res.data }, () => {});
      });
    }
  };

  getProductDetails = () => {
    axios
      .get(Constants.getUrls.courseDetail + this.state.courseId)
      .then((resp) => {
        console.log(resp);
        // if(resp.data.status === "success")
        // {
        this.setState({
          courseDetails: resp.data.product,
          course_id: resp.data.product.ID,
          mainPrice: resp.data.product.PRICE,
          isLoading: false,
        });
        // }
      })
      .catch((error) => {});
    // this.getActiveVariants();
  };
  componentDidUpdate(prevProps, prevState) {
    // Check if courseDetails state has been updated
    if (prevState.courseDetails !== this.state.courseDetails) {
      console.log(this.state.courseDetails);
      if (prevState.courseDetails.ID !== this.state.courseDetails.ID) {
        console.log(this.state.courseDetails.ID);
      }
      // Now you can perform any actions that depend on courseDetails being updated
    }
  }

  handleCoupon = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  };
  verifyCoupon = () => {
    if (!this.state.couponApplied) {
      this.setState({
        couponFail: false,
        couponSuccess: false,
      });
      const payload = {
        coupon: this.state.coupon_code,
        product_id: this.state.courseId,
      };
      axios.post(Constants.postUrls.coupon, payload).then((res) => {
        if (res.data.status === "fail") {
          this.setState({
            couponFail: true,
            couponSuccess: false,
            coupon_msg: res.data.message,
          });
          setTimeout(() => {
            this.setState({
              openCoupon: false,
              coupon_code: "",
              couponSuccess: false,
              couponFail: false,
            });
          }, 1500);
        } else {
          const couponAmount = parseInt(res.data.coupon_detail.DISCOUNT_AMOUNT);
          const discountedPrice = this.state.offPrice
            ? this.state.offPrice - couponAmount
            : couponAmount;
          const mainPrice = this.state.mainPrice - couponAmount;
          this.setState({
            couponFail: false,
            couponSuccess: true,
            coupon: couponAmount,
            coupon_msg: "Wohho! Coupon Applied! ₹ " + couponAmount + " off.",
            offPrice: discountedPrice,
            mainPrice: mainPrice,
            couponApplied: true,
          });
          setTimeout(() => {
            // this.setState({
            //   openCoupon: false,
            //   coupon_code: "",
            //   couponSuccess: false,
            //   couponFail: false,
            // });
          }, 1500);
        }
      });
    } else {
      this.setState({
        couponFail: true,
        couponSuccess: false,
        coupon_msg: "Coupon already applied",
      });
      setTimeout(() => {
        this.setState({ openCoupon: false });
      }, 1500);
    }
  };

  getActiveVariants = () => {
    console.log(this.state.courseId);
    axios
      .get(Constants.getUrls.getCoursesActiveVarient + this.state.courseId)
      .then((respVarient) => {
        console.log(respVarient);
        this.setState({
          variants: respVarient.data.product.variant,
        });

        let VarArray = respVarient.data.product.variant.filter(
          (variant) =>
            variant.status === "active" || variant.status === "Active"
        );
        if (respVarient.data.product.TYPE === "COURSES") {
          let oss = [];
          let views = [];
          let medias = [];
          if (VarArray ? VarArray?.length > 0 : "") {
            let osidOfZero = VarArray[0].os_type_id;
            VarArray.forEach((item) => {
              if (oss ? oss?.length > 0 : "") {
                const findOS = oss.find((os) => os.id === item.os_type_id);

                if (!findOS) {
                  oss.push(item.os_type);
                }
              } else {
                oss.push(item.os_type);
              }
              if (item.os_type_id === osidOfZero) {
                if (medias ? medias?.length > 0 : "") {
                  const findMedia = medias.find(
                    (media) => media.id === item.media_type_id
                  );
                  if (!findMedia) {
                    medias.push(item.media_type);
                  }
                } else {
                  medias.push(item.media_type);
                }
              }
              if (medias ? medias?.length > 0 : "") {
                if (
                  item.os_type_id === osidOfZero &&
                  medias[0].id === item.media_type_id
                ) {
                  if (views ? views?.length > 0 : "") {
                    const findViews = views.find(
                      (view) => view.id === item.view_and_validity_id
                    );
                    if (!findViews) {
                      views.push(item.view_and_validity);
                    }
                  } else {
                    views.push(item.view_and_validity);
                  }
                }
              }
            });

            this.setState(
              {
                oss,
                views,
                medias,
                osType: osidOfZero,
                mediaType: respVarient.data.product.variant[0].media_type_id,
                viewType:
                  respVarient.data.product.variant[0].view_and_validity_id,
                variant: respVarient.data.product.variant,
                variant_id: respVarient.data.product.variant[0].id,
                price:
                  this.state.courseDetails.TYPE === "BOOKS"
                    ? this.sate.courseDetails.PRICE
                    : respVarient.data.product.variant[0].price,
                // offerPrice: respVarient.data.product.variant[0].price,
                totalAmt:
                  this.state.courseDetails.TYPE === "BOOKS"
                    ? this.sate.courseDetails.PRICE
                    : respVarient.data.product.variant
                    ? respVarient.data.product.variant[0].price -
                      respVarient.data.product.VALUE
                    : this.state.courseDetails.PRICE,
                base_offerPriceVariant:
                  respVarient.data.product.variant[0].price,
              },
              () => {
                this.setState(
                  {
                    mainPrice: this.state.price - this.state.offPrice,
                  },
                  () => {}
                );
              }
            );
          }
        }
      });
  };

  handleOS = (e) => {
    this.setState(
      {
        osType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        // let oss = [];
        let views = [];
        let medias = [];
        if (this.state.variant ? this.state.variant?.length > 0 : "") {
          this.state.variant.forEach((item) => {
            if (item.os_type_id === parseInt(this.state.osType)) {
              if (medias ? medias?.length > 0 : "") {
                const findMedia = medias.find(
                  (media) => media.id === item.media_type_id
                );
                if (!findMedia) {
                  medias.push(item.media_type);
                }
              } else {
                medias.push(item.media_type);
              }
            }
            if (medias ? medias?.length > 0 : "") {
              if (
                item.os_type_id === parseInt(this.state.osType) &&
                item.media_type_id === medias[0].id
              ) {
                if (views ? views?.length > 0 : "") {
                  const findViews = views.find(
                    (view) => view.id === item.view_and_validity_id
                  );
                  if (!findViews) {
                    views.push(item.view_and_validity);
                  }
                } else {
                  views.push(item.view_and_validity);
                }
              }
            }
          });
          const selectedArray = this.state.variant.filter(
            (item) => item.os_type_id === parseInt(this.state.osType)
          );
          this.setState(
            {
              // oss,
              views,
              medias,
              // osType: osidOfZero,
              mediaType: selectedArray[0].media_type_id,
              viewType: selectedArray[0].view_and_validity_id,
              variant_id: selectedArray[0].id,
              price: selectedArray[0].price,
              offerPrice: selectedArray[0].offer_price,
              base_offerPrice: selectedArray[0].offer_price,
            },
            () => {
              this.setState(
                {
                  mainPrice: this.state.coupon
                    ? this.state.price - this.state.coupon
                    : this.state.price - this.state.offPrice,
                },
                () => {}
              );
            }
          );
        }
      }
    );
  };

  handleMedia = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        mediaType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        let views = [];
        if (this.state.variant ? this.state.variant?.length > 0 : "") {
          this.state.variant.forEach((item) => {
            if (
              item.os_type_id === parseInt(this.state.osType) &&
              item.media_type_id === parseInt(this.state.mediaType)
            ) {
              if (views ? views?.length > 0 : "") {
                const findViews = views.find(
                  (view) => view.id === item.view_and_validity_id
                );
                if (!findViews) {
                  views.push(item.view_and_validity);
                }
              } else {
                views.push(item.view_and_validity);
              }
            }
          });
          const selectedArray = this.state.variant.filter(
            (item) =>
              item.os_type_id === parseInt(this.state.osType) &&
              item.media_type_id === parseInt(this.state.mediaType)
          );
          this.setState(
            {
              // oss,
              views,
              // medias,
              // osType: osidOfZero,
              // mediaType: selectedArray[0].media_type_id,
              viewType: selectedArray[0].view_and_validity_id,
              variant_id: selectedArray[0].id,
              price: selectedArray[0].price,
              offerPrice: selectedArray[0].offer_price,
              base_offerPrice: selectedArray[0].offer_price,
            },
            () => {
              this.setState(
                {
                  mainPrice: this.state.price - this.state.offPrice,
                },
                () => {}
              );
            }
          );
        }
      }
    );
  };

  handleViews = (e) => {
    this.setState(
      {
        // [e.target.name]: e.target.value,
        viewType: e,
        // couponFail: false,
        // couponSuccess: false,
        // coupon: 0,
        // coupon_code: "",
      },
      () => {
        const selectedObject = this.state.variant.find(
          (item) =>
            item.os_type_id === parseInt(this.state.osType) &&
            item.media_type_id === parseInt(this.state.mediaType) &&
            item.view_and_validity_id === parseInt(this.state.viewType)
        );
        this.setState(
          {
            variant_id: selectedObject.id,
            price: selectedObject.price,
            offerPrice: selectedObject.offer_price,
            base_offerPrice: selectedObject.offer_price,
          },
          () => {
            this.setState(
              {
                mainPrice: this.state.price - this.state.offPrice,
              },
              () => {}
            );
          }
        );
      }
    );
  };

  showAnswer = (e) => {
    this.setState({
      answer: !this.state.answer,
    });
  };
  download(id, filepath) {
    axios
      .get(
        "https://admin.akclasses.in/api/download_question_pdf/" +
          80 +
          "?file=" +
          filepath
      )
      .then((res) => {});
  }

  render() {
    const { courseDetails } = this.state;
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        <Header />
        <div className="ResponsiveBook">
          <BookCourseDetailsResp />
        </div>
        {/*  */}

        <div className="Web">
          {this.state.isLoading ? (
            <div style={{ marginTop: 100 }}>
              <Lottie
                options={defaultLoader}
                height={80}
                width={80}
                isStopped={this.state.isStopped}
                isPaused={this.state.isPaused}
              />
            </div>
          ) : (
            <>
              <section className="section1" style={{ height: "275px" }}>
                <h1 className="fonts">Book Details</h1>

                <h5 className="titleName">
                  {"Home > " + this.state.courseDetails?.TITLE}
                </h5>
              </section>
              <div className="container contain2">
                <div className="row">
                  <div className="col-sm-8">
                    <section
                      className="section2"
                      style={{ marginTop: "-150px" }}
                    >
                      <div className=" attemptCourseDetail3">
                        {/* {this.state.showDemo ? (
                          <YoutubeEmbed
                            embedId={this.state.courseDetails?.DEMO_LINK}
                            width={"596px"}
                            height={"318.26px"}
                          />
                        ) : (
                          <YoutubeEmbed
                            embedId={this.state.courseDetails?.DEMO_LINK1}
                            width={"596px"}
                            height={"318.26px"}
                          />
                        )} */}
                        <img
                          src={
                            Constants.imgUrl +
                            "/" +
                            this.state.courseDetails?.IMAGE_PATH
                          }
                          alt=""
                          width={"596px"}
                          height={"318.26px"}
                          style={{
                            position: "relative",
                            left: "38px",
                            top: "36px",
                          }}
                        />
                      </div>
                      <div className=" attemptCourseDetailbook">
                        <div className="aboutCourse">About The Book</div>
                        <p
                          className="courseP1"
                          dangerouslySetInnerHTML={{
                            __html: this.state.courseDetails.SHORT_DESCRIPTION
                              ? this.state.courseDetails.SHORT_DESCRIPTION
                              : "No data available",
                          }}
                        ></p>
                        {/* <ul className="activate">
                          <li className="font">
                            Students can activate course instantly after
                            purchase by login to their dashboard/profile on
                            AKClasses Website.{" "}
                            <span className="activationProcess">
                              Click here to know about Activation Process.
                            </span>{" "}
                          </li>
                          <li className="font">
                            Books will be delivered within 5 to 6 days after
                            order completion.
                          </li>
                        </ul> */}
                      </div>
                    </section>
                  </div>
                  <div className="section3">
                    <div className="col-sm-4 rightSide">
                      <div className="variantsBook">
                        <div className="qnti">
                          {this.state.courseDetails?.TITLE}
                        </div>
                        {/* <div className="batchDet">
                          <span className="paramName">
                            {this.state.courseDetails?.batch?.batch_name}
                          </span>{" "}
                          for attempt:{" "}
                          <span className="attem">
                            {this.state.courseDetails?.batch?.attempt?.title}
                          </span>
                        </div> */}
                        <div className="totlPBook">
                          Rs.{this.state.mainPrice}
                        </div>
                        <div className="inclusiveBook">
                          *Prices Inclusive of all taxes
                        </div>
                        <div
                          className="applyBtnAreaBook"
                          onClick={() => {
                            this.setState({ openCoupon: true });
                          }}
                        >
                          <span className="applyCoupon">Apply Coupon</span>
                          <span className="">
                            {this.state.couponSuccess ? (
                              <span className="couponSuccessMessage">
                                {this.state.coupon_msg}
                              </span>
                            ) : null}
                            {this.state.couponFail ? (
                              <span className="couponFailMessage">
                                {this.state.coupon_msg}
                              </span>
                            ) : null}
                          </span>
                        </div>
                        {this.state.openCoupon && (
                          <>
                            <div className={"couponWrapperBook"}>
                              <input
                                name="coupon_code"
                                onChange={this.handleCoupon}
                                value={this.state.coupon_code}
                                placeholder="Enter code here.."
                                style={{
                                  padding: "6px 10px",
                                  width: "62%",
                                }}
                              />
                              <button
                                onClick={this.verifyCoupon}
                                className="verifyBtn"
                              >
                                Verify
                              </button>
                            </div>
                            {/* {this.state.couponSuccess ? (
                              <p className="couponSuccessMessage">
                                {this.state.coupon_msg}
                              </p>
                            ) : null}
                            {this.state.couponFail ? (
                              <p className="couponFailMessage">
                                {this.state.coupon_msg}
                              </p>
                            ) : null} */}
                          </>
                        )}
                        {/* {this.state.courseDetails.stock_status === 0 ? ( */}
                        <div className="buyBtnAreaBook">
                          <Link
                            to={
                              "/checkout?" +
                              "bookId=" +
                              btoa(this.state.courseDetails.ID)
                            }
                            state={{
                              coupon: this.state.coupon_code
                                ? this.state.coupon_code
                                : "",
                              batchId: this.state.batch_id,
                              batch_name:
                                this.state.courseDetails?.batch?.batch_name,
                              attempt:
                                this.state.courseDetails?.batch?.attempt?.title,
                              faculty_name:
                                this.state.courseDetails?.course?.faculties
                                  ?.name,
                              batchWingId: this.state.batch_wings_id,
                              base_total: this.state.totalAmt,
                              coupon_amount: this.state.coupon,
                              grand_total: this.state.coupon_code
                                ? this.state.totalAmt - this.state.coupon
                                : this.state.totalAmt,
                              sku: this.state.courseDetails?.SKU,

                              product_details: {
                                TITLE: this.state.courseDetails?.TITLE,
                                product_id: this.state.course_id,
                                quantity: 1,
                                type: "COURSE",
                                variant_id: this.state.variant_id,
                                os_type_id: this.state.osType,
                                media_type_id: this.state.mediaType,
                                attempt: this.state.attemptType,
                                view_and_validity_id: this.state.mediaType,
                              },
                              product: this.state.data,
                              userDetails: this.state.userDetails,
                              courseDetails: this.state.courseDetails,
                            }}
                            onClick={() =>
                              this.setState(
                                {
                                  base_total: this.state.offerPrice,
                                },
                                () => {}
                              )
                            }
                          >
                            <button className="buyCourseBtn">
                              ADD THE BOOK
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="regardingFac">
                        <p className="facText">About The Faculty</p>
                        <div className="facBorder"></div>
                        <div className="col-md-12 facDetails">
                          <div className="col-md-2">
                            <img
                              src={
                                Constants.imgUrl +
                                this.state.courseDetails.faculties?.image
                              }
                              alt=""
                              style={{ width: "65px", height: "65px" }}
                            />
                          </div>
                          <div className="col-md-6 nameArea">
                            <div className="facname">
                              {this.state.courseDetails.faculties?.name}
                            </div>
                            <div className="facProfession">
                              {this.state.courseDetails.faculties?.about_us}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="knowMore">Know More</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}
