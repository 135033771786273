import React, { Component } from "react";
// import init from "../Components/helper/WindowToken";
import Axios from "axios";
import Constants from "../common/Constants";
import Modal from "react-awesome-modal";
export default class Profile extends Component {
  state = {
    userdetails: {},
    UserType: "",
    visible: false,
  };
  componentDidMount() {
    let userToken = localStorage.getItem("token");

    Axios.get(Constants.getUrls.user, {
      headers: { Authorization: "Bearer " + userToken },
    }).then((res) => {
      // console.log(res);
      this.setState({
        userdetails: res.data,
        UserType: res.data.TYPE,
      });
    });
  }
  deletePermanent = () => {
    // console.log(localStorage.getItem("user_data"));
    let payload = {
      user_id: localStorage.getItem("user_data"),
    };
    Axios.post("https://admin.akclasses.in/api/user/delete", payload).then(
      (resp) => {
        console.log(resp);
        if (resp.data.data.status === "success") {
          localStorage.clear("token");
          localStorage.clear("user_data");
          window.location.href = "/";
        }
      }
    );
  };

  render() {
    return (
      <div className="container profileWrap">
        {/* <h1
          style={{
            fontWeight: "bold",
            fontSize: "30px",
            marginTop: "35px",
          }}
        >
          Dashboard
        </h1>
        <h3
          style={{
            // textAlign: "center",
            fontWeight: "bold",
            fontSize: "17px",
            color: "rgb(218, 106, 6)",
          }}
        >
          Welcome {this.state.userdetails.FIRST_NAME}
        </h3> */}

        <div className="dashboard-right fadeInLeftToRight">
          <div className="dashboard">
            <div className="page-title">
              <h2>My Dashboard</h2>
            </div>
            <div className="welcome-msg">
              <p>Hello, {this.state.userdetails.FIRST_NAME} !</p>
            </div>
            <div className="box-account box-info">
              <div className="box-head">
                <h2>Account Information</h2>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="box">
                    <div className="box-title">
                      <h3>Contact Information</h3>
                      {/* <a href="/pages/userdetails">Edit</a> */}
                    </div>
                    <div className="box-content">
                      <h6>{this.state.userdetails.FIRST_NAME}</h6>
                      <h6>{this.state.userdetails.email}</h6>

                      <h6>
                        {/* <a href="/">Change Password</a> */}
                        <a href="/userdetails">Edit</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="box">
                  <div className="box-title">
                    <h3>Address Book</h3>
                    <a href="/userdetails">Manage Addresses</a>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <h6>Default Billing Address</h6>
                      <address>
                        {this.state.userdetails.ADDRESS_1},
                        {this.state.userdetails.CITY},
                        <br />
                        {this.state.userdetails.STATE}-
                        {this.state.userdetails.PINCODE}
                        <br />
                        {this.state.userdetails.MOBILE}
                        <br />
                        <a href="/userdetails">Edit Address</a>
                      </address>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h4>Delete Account</h4>

                      <button
                        style={{
                          backgroundColor: "red",
                          color: "#fff",
                          borderRadius: "10px",
                          border: "none",
                          height: "4rem",
                        }}
                        onClick={() => {
                          this.setState({
                            visible: true,
                          });
                        }}
                      >
                        Delete Account Permanently
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.visible}
          width="600"
          height="100"
          effect="fadeInUp"
          onClickAway={() => this.setState({ visible: false })}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h5>
                Are you sure you want to delete your account Permanently? This
                Action will delete all your orders and erase all your data.
              </h5>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    backgroundColor: "white",
                    color: "#003399",
                    // border: "none",
                    borderColor: "#003399",
                    height: "3rem",
                    width: "5rem",
                    marginRight: "10px",
                  }}
                  onClick={this.deletePermanent}
                >
                  Yes
                </button>
                <button
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#003399",
                    color: "#fff",
                    border: "none",
                    height: "3rem",
                    width: "5rem",
                  }}
                  onClick={() => {
                    this.setState({
                      visible: false,
                    });
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
