import PropTypes from "prop-types";
const YoutubeEmbed = ({ embedId }) => (
  <div
    // className="video-responsive"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: window.screen.width > 600 ? 32 : 24,
    }}
  >
    <iframe
      style={{
        width: "90%",
        height: window.screen.width > 600 ? "318px" : "185px",
        alignSelf: "center",
      }}
      // width="75%"
      // height="318px"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);
YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};
export default YoutubeEmbed;







