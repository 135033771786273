import Axios from "axios";
import React, { Component } from "react";
import YouTube from "react-youtube";
import Constants from "../../components/common/Constants";
import NotAvailable from "./NotAvailable";

export default class ActivationProcess extends Component {
  state = {
    activationProcess: [],
    actProcess: [],
  };
  componentDidMount() {
    Axios.get(Constants.getUrls.activationProcess + "?type=normal").then(
      (res) => {
        this.setState({
          activationProcess: res.data,
          EmptyAct: res.data.length === 0 ? true : false,
        });
      }
    );
    Axios.get(Constants.getUrls.activationProcess + "?type=troubleshoot").then(
      (res) => {
        this.setState({
          actProcess: res.data,
        });
      }
    );
  }

  render() {
    const opts = {
      height: "200",
      width: "100%",
      playerVars: {
        autoplay: 0,
      },
    };
    return (
      <div>
        <div>
          <h3
            style={{
              textAlign: "center",
              marginBottom: "20px",
              marginTop: "89px",
              fontWeight: "bold",
              fontFamily: "Oswald,sans-serif",
            }}
          >
            Normal Videos
          </h3>

          {!this.state.EmptyAct ? (
            <div className="row">
              {this.state.activationProcess.map((act) => (
                <div className="col-sm-4">
                  <div>
                    <YouTube
                      videoId={act.link ? act.link : "fDNN5IDNj5o"}
                      opts={opts}
                      onReady={this._onReady}
                      ref="vidRef"
                    />
                    <p
                      style={{
                        color: "black",
                        display: "inline",
                        fontWeight: "bold",
                      }}
                    >
                      {act.title ? act.title : "Some Title"}{" "}
                    </p>
                    |
                    <p style={{ color: "black", display: "inline" }}>
                      {" "}
                      {act.description
                        ? act.description
                        : "Some Description"}{" "}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NotAvailable />
          )}
          <h3
            style={{
              textAlign: "center",
              marginBottom: "20px",
              marginTop: "40px",
              fontWeight: "bold",
              fontFamily: "Oswald,sans-serif",
            }}
          >
            Troubleshoot Videos
          </h3>

          {this.state.actProcess.length > 0 ? (
            this.state.actProcess.map((ac) => (
              <div className="col-sm-4">
                <div style={{ marginBottom: "20px" }}>
                  <YouTube
                    videoId={ac.link ? ac.link : "fDNN5IDNj5o"}
                    opts={opts}
                    onReady={this._onReady}
                    ref="vidRef"
                  />
                  <p
                    style={{
                      color: "black",
                      display: "inline",
                      fontWeight: "bold",
                    }}
                  >
                    {ac.title ? ac.title : "Some Title"}{" "}
                  </p>
                  |
                  <p style={{ color: "black", display: "inline" }}>
                    {" "}
                    {ac.description ? ac.description : "Some Description"}{" "}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <NotAvailable />
          )}
        </div>
      </div>
    );
  }
}
