import React, { Component } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";

export default class TermsNconditions extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <Header />
        <div className="container">
          <div className="privacy">
            <h1>TERMS AND CONDITIONS</h1>

            <p> LAST REVISION: Febuary 25th, 2020 </p>
            <p>
              PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING
              THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO
              BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
            </p>
            <p>
              This Terms of Service Agreement (the “Agreement”) governs your use
              of this website, akclasses.in (the “Website”), Aman Khedia Classes
              (“Business Name”) offer of products for purchase on this Website,
              or your purchase of products available on this Website. This
              Agreement includes, and incorporates by this reference, the
              policies and guidelines referenced below. Aman Khedia Classes
              reserves the right to change or revise the terms and conditions of
              this Agreement at any time by posting any changes or a revised
              Agreement on this Website. Aman Kedia Classes will alert you that
              changes or revisions have been made by indicating on the top of
              this Agreement the date it was last revised. The changed or
              revised Agreement will be effective immediately after it is posted
              on this Website. Your use of the Website following the posting any
              such changes or of a revised Agreement will constitute your
              acceptance of any such changes or revisions. Aman Khedia Classes
              encourages you to review this Agreement whenever you visit the
              Website to make sure that you understand the terms and conditions
              governing use of the Website. This Agreement does not alter in any
              way the terms or conditions of any other written agreement you may
              have with Aman Khedia Classes for other products or services. If
              you do not agree to this Agreement (including any referenced
              policies or guidelines), please immediately terminate your use of
              the Website. If you would like to print this Agreement, please
              click the print button on your browser toolbar.
            </p>
            <b>1. PRODUCTS</b>
            <p>
              Terms of Offer. This Website offers for sale certain products (the
              “Products”). By placing an order for Products through this
              Website, you agree to the terms set forth in this Agreement.
            </p>
            <b>Customer Solicitation:</b>
            <p>
              Unless you notify our third party call center reps or direct Aman
              Khedia Classes sales reps, while they are calling you, of your
              desire to opt out from further direct company communications and
              solicitations, you are agreeing to continue to receive further
              emails and call solicitations Aman Khedia Classes and its
              designated in house or third party call team(s).
            </p>
            <b>Opt Out Procedure:</b>
            <p>
              We provide 2 easy ways to opt out of from future solicitations. 1.
              You may use the opt out link found in any email solicitation that
              you may receive. 2. You may also choose to opt out, via sending
              your email address to: bvlhelpdesk@gmail.com Proprietary Rights.
              Aman Khedia Classes has proprietary rights and trade secrets in
              the Products. You may not copy, reproduce, resell or redistribute
              any Product manufactured and/or distributed by Aman Khedia
              Classes. Aman Khedia Classes also has rights to all trademarks and
              trade dress and specific layouts of this webpage, including calls
              to action, text placement, images and other information.
            </p>
            <p>
              Tax. If you purchase any Products, you will be responsible for
              paying any applicable taxes.
            </p>
            <b>1.WEBSITE</b>
            <p>
              Content; Intellectual Property; Third Party Links. In addition to
              making Products available, this Website also offers information
              and marketing materials. This Website also offers information,
              both directly and through indirect links to third-party websites,
              about study courses primarily in finance. Aman Khedia Classes does
              not always create the information offered on this Website; instead
              the information is often gathered from other sources. To the
              extent that Aman Khedia Classes does create the content on this
              Website, such content is protected by intellectual property laws
              of the India, foreign nations, and international bodies.
              Unauthorized use of the material may violate copyright, trademark,
              and/or other laws. You acknowledge that your use of the content on
              this Website is for personal, noncommercial use. Any links to
              third-party websites are provided solely as a convenience to you.
              Aman Khedia Classes does not endorse the contents on any such
              third-party websites Aman Khedia Classes is not responsible for
              the content of or any damage that may result from your access to
              or reliance on these third-party websites. If you link to
              third-party websites, you do so at your own risk.
            </p>
            <p>
              Use of Website; Aman Khedia Classes is not responsible for any
              damages resulting from use of this website by anyone. You will not
              use the Website for illegal purposes. You will (1) abide by all
              applicable local, state, national, and international laws and
              regulations in your use of the Website (including laws regarding
              intellectual property), (2) not interfere with or disrupt the use
              and enjoyment of the Website by other users, (3) not resell
              material on the Website, (4) not engage, directly or indirectly,
              in transmission of “spam”, chain letters, junk mail or any other
              type of unsolicited communication, and (5) not defame, harass,
              abuse, or disrupt other users of the Website
            </p>
            <p>
              License. By using this Website, you are granted a limited,
              non-exclusive, non-transferable right to use the content and
              materials on the Website in connection with your normal,
              noncommercial, use of the Website. You may not copy, reproduce,
              transmit, distribute, or create derivative works of such content
              or information without express written authorization from Aman
              Khedia Classes or the applicable third party (if third party
              content is at issue).
            </p>
            <p>
              Posting. By posting, storing, or transmitting any content on the
              Website, you hereby grant Aman Khedia Classes a perpetual,
              worldwide, non-exclusive, royalty-free, assignable, right and
              license to use, copy, display, perform, create derivative works
              from, distribute, have distributed, transmit and assign such
              content in any form, in all media now known or hereinafter
              created, anywhere in the world. Aman Khedia Classes does not have
              the ability to control the nature of the user-generated content
              offered through the Website. You are solely responsible for your
              interactions with other users of the Website and any content you
              post. Aman Khedia Classes is not liable for any damage or harm
              resulting from any posts by or interactions between users. Aman
              Khedia Classes reserves the right, but has no obligation, to
              monitor interactions between and among users of the Website and to
              remove any content Aman Khedia Classes deems objectionable, in
              Aman Khedia Classes sole discretion.
            </p>
            <b>III. DISCLAIMER OF WARRANTIES</b>
            <p>
              YOUR USE OF THIS WEBSITE AND/OR PRODUCTS ARE AT YOUR SOLE RISK.
              THE WEBSITE AND PRODUCTS ARE OFFERED ON AN “AS IS” AND “AS
              AVAILABLE” BASIS AMAN KHEDIA CLASSES EXPRESSLY DISCLAIMS ALL
              WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, AND
              NON-INFRINGEMENT WITH RESPECT TO THE PRODUCTS OR WEBSITE CONTENT,
              OR ANY RELIANCE UPON OR USE OF THE WEBSITE CONTENT OR PRODUCTS.
              (“PRODUCTS” INCLUDE Open Video Content or PDF document.)
            </p>
            <p>
              WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, AMAN KHEDIA
              CLASSES MAKES NO WARRANTY: THAT THE INFORMATION PROVIDED ON THIS
              WEBSITE IS ACCURATE, RELIABLE, COMPLETE, OR TIMELY. THAT THE LINKS
              TO THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS ACCURATE,
              RELIABLE, COMPLETE, OR TIMELY. NO ADVICE OR INFORMATION, WHETHER
              ORAL OR WRITTEN, OBTAINED BY YOU FROM THIS WEBSITE WILL CREATE ANY
              WARRANTY NOT EXPRESSLY STATED HEREIN.
            </p>
            <p>
              AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
              PRODUCTS OR THAT DEFECTS IN PRODUCTS WILL BE CORRECTED. REGARDING
              ANY PRODUCTS PURCHASED OR OBTAINED THROUGH THE WEBSITE. SOME
              JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO
              SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
            </p>
            <b>1.LIMITATION OF LIABILITY</b>
            <p>
              AMAN KHEDIA CLASSES ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY,
              IN LAW, IN EQUITY, OR OTHWERWISE, WITH RESPECT TO THE WEBSITE
              CONTENT AND PRODUCTS AND/OR FOR ANY BREACH OF THIS AGREEMENT IS
              SOLELY LIMITED TO THE AMOUNT YOU PAID, LESS SHIPPING, HANDLING,
              ANY HARDWARE COST AND ANY BOOKS, FOR PRODUCTS PURCHASED VIA THE
              WEBSITE. AMAN KHEDIA CLASSES WILL NOT BE LIABLE FOR ANY DIRECT,
              INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES IN
              CONNECTION WITH THIS AGREEMENT OR THE PRODUCTS IN ANY MANNER,
              INCLUDING LIABILITIES RESULTING FROM (1) THE USE OR THE INABILITY
              TO USE THE WEBSITE CONTENT OR PRODUCTS; (2) THE COST OF PROCURING
              SUBSTITUTE PRODUCTS OR CONTENT; (3) ANY PRODUCTS PURCHASED OR
              OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH THE WEBSITE; OR (4)
              ANY LOST PROFITS YOU ALLEGE. SOME JURISDICTIONS DO NOT ALLOW THE
              LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
              CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT
              APPLY TO YOU.
            </p>
            <b>1.INDEMNIFICATION</b>
            <p>
              You will release, indemnify, defend and hold Aman Khedia Classes,
              and any of its contractors, agents, employees, officers,
              directors, shareholders, affiliates and assigns from all
              liabilities, claims, damages, costs and expenses, including
              reasonable attorneys’ fees and expenses, of third parties relating
              to or arising out of (1) this Agreement or the breach of your
              warranties, representations and obligations under this Agreement;
              (2) the Website content or your use of the Website content; (3)
              the Products or your use of the Products (including Trial and FREE
              Products); (4) any intellectual property or other proprietary
              right of any person or entity; (5) your violation of any provision
              of this Agreement; or (6) any information or data you supplied to
              Aman Khedia Classes. When Aman Khedia Classes is threatened with
              suit or sued by a third party, Aman Khedia Classes may seek
              written assurances from you concerning your promise to indemnify
              Aman Khedia Classes; your failure to provide such assurances may
              be considered by Aman Khedia Classes to be a material breach of
              this Agreement. Aman Khedia Classes will have the right to
              participate in any defense by you of a third-party claim related
              to your use of any of the Website content or Products, with
              counsel of Aman Khedia Classes choice at its expense Aman Khedia
              Classes will reasonably cooperate in any defense by you of a
              third-party claim at your request and expense. You will have sole
              responsibility to defend Aman Khedia Classes against any claim,
              but you must receive Aman Khedia Classes prior written consent
              regarding any related settlement. The terms of this provision will
              survive any termination or cancellation of this Agreement or your
              use of the Website or Products.
            </p>
            <b>1.PRIVACY</b>
            <p>Please refer to Privacy Policy section on akclasses.in</p>
            <b>1.AGREEMENT TO BE BOUND</b>
            <p>
              By using this Website or ordering Products, you acknowledge that
              you have read and agree to be bound by this Agreement and all
              terms and conditions on this Website.
            </p>
            <b>VIII. GENERAL</b>
            <p>
              Force Majeure. Aman Khedia Classes will not be deemed in default
              hereunder or held responsible for any cessation, interruption or
              delay in the performance of its obligations hereunder due to
              earthquake, flood, fire, storm, natural disaster, act of God, war,
              terrorism, armed conflict, labor strike, lockout, or boycott.
            </p>
            <p>
              Cessation of Operation. Aman Khedia Classes may at any time, in
              its sole discretion and without advance notice to you, cease
              operation of the Website and distribution of the Products.
            </p>
            <p>
              Entire Agreement. This Agreement comprises the entire agreement
              between you and Aman Khedia Classes and supersedes any prior
              agreements pertaining to the subject matter contained herein.
            </p>
            <p>
              Effect of Waiver. The failure of Aman Khedia Classes Limited to
              exercise or enforce any right or provision of this Agreement will
              not constitute a waiver of such right or provision. If any
              provision of this Agreement is found by a court of competent
              jurisdiction to be invalid, the parties nevertheless{" "}
            </p>
            <p>
              agree that the court should endeavor to give effect to the
              parties’ intentions as reflected in the provision, and the other
              provisions of this Agreement remain in full force and effect.
            </p>
            <p>
              Governing Law; This Website originates from the Maharashtra,
              India. This Agreement will be governed by the laws of the State of
              Maharashtra without regard to its conflict of law principles to
              the contrary. Neither you nor Aman Khedia Classes will commence or
              prosecute any suit, proceeding or claim to enforce the provisions
              of this Agreement, to recover damages for breach of or default of
              this Agreement, or otherwise arising under or by reason of this
              Agreement, other than in courts located in Nagpur. By using this
              Website or ordering Products, you consent to the jurisdiction and
              venue of such courts in connection with any action, suit,
              proceeding or claim arising under or by reason of this Agreement.
              You hereby waive any right to trial by jury arising out of this
              Agreement and any related documents.
            </p>
            <p>
              Statute of Limitation. You agree that regardless of any statute or
              law to the contrary, any claim or cause of action arising out of
              or related to use of the Website or Products or this Agreement
              must be filed within one (1) year after such claim or cause of
              action arose or be forever barred. Waiver of Class Action Rights.
              BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY
              RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH THOSE OF OTHER IN THE FORM
              OF A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING
              OUT OF, RELATING TO, OR CONNECTION WITH THIS AGREEMENT MUST BE
              ASSERTED INDIVIDUALLY.
            </p>
            <p>
              Termination. Aman Khedia Classes reserves the right to terminate
              your access to the Website if it reasonably believes, in its sole
              discretion, that you have breached any of the terms and conditions
              of this Agreement. Following termination, you will not be
              permitted to use the Website and Aman Khedia Classes may, in its
              sole discretion and without advance notice to you, cancel any
              outstanding orders for Products. If your access to the Website is
              terminated, Aman Khedia Classes reserves the right to exercise
              whatever means it deems necessary to prevent unauthorized access
              of the Website. This Agreement will survive indefinitely unless
              and until Aman Khedia Classes chooses, in its sole discretion and
              without advance to you, to terminate it.
            </p>
            <p>
              Assignment. You may not assign your rights and obligations under
              this Agreement to anyone. Aman Khedia Classes may assign its
              rights and obligations under this Agreement in its sole discretion
              and without advance notice to you. BY USING THIS WEBSITE OR
              ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL
              OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
            </p>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
