import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import FacetoFaceCheckout from "./FacetoFaceCheckout";

export default function FunctionalFacetoFaceCheckOut(props) {
  // let id = useParams();
  console.log(props);
  const location = useLocation();
  console.log(location.state);
  return (
    <div>
      <Header />
      <FacetoFaceCheckout {...location.state} />
      <Footer />
    </div>
  );
}
