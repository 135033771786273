import React, { Component } from "react";
import init from "../helper/WindowToken";
import Constants from "../common/Constants";
import Axios from "axios";
import notFound from "../../images/77703-no-data-found.json";
import Lottie from "react-lottie";
import loader from "../../images/97739-loader.json";
export default class FacetoFaceOrders extends Component {
  state = {
    facetFace: [],
    isLoading: true,
  };
  componentDidMount() {
    if (init() === "success") {
      Axios.get(Constants.getUrls.facetofaceOrders).then((res) => {
        console.log("ftf", res);
        this.setState({ facetFace: res.data.data, isLoading: false });
      });
    }
  }
  LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
  hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;

    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;

    return s;
  }
  color(r, g, b) {
    return "#" + this.hex2(r) + this.hex2(g) + this.hex2(b);
  }
  // shade(col, light) {
  //   var r = parseInt(col.substr(1, 2), 16);
  //   var g = parseInt(col.substr(3, 2), 16);
  //   var b = parseInt(col.substr(5, 2), 16);

  //   if (light < 0) {
  //     r = (1 + light) * r;
  //     g = (1 + light) * g;
  //     b = (1 + light) * b;
  //   } else {
  //     r = (1 - light) * r + light * 255;
  //     g = (1 - light) * g + light * 255;
  //     b = (1 - light) * b + light * 255;
  //   }

  //   return this.color(r, g, b);
  // }
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: notFound,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const defaultLoader = {
      loop: false,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        {this.state.isLoading ? (
          <Lottie
            options={defaultLoader}
            height={80}
            width={80}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused}
          />
        ) : (
          <>
            {this.state.facetFace?.length > 0 ? (
              <>
                {this.state.facetFace.map((item) => {
                  const percentage1 = 0;
                  // const color1 = this.shade(item.facetoface.color, 0.6);
                  const percentage2 = 100;
                  // const color2 = this.shade(item.facetoface.color, 0.3);
                  return (
                    <div
                      className="facetofaceOrderCard"
                      style={{
                        background: "#77777708",
                        // background: `linear-gradient(180deg, ${color1} ${percentage1}%, ${color2} ${percentage2}%)`,
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-4 ">
                          <img
                            src={Constants.imgUrls + item.facetoface.image}
                            width={"50%"}
                            height={"100%"}
                            alt=""
                          />
                        </div>
                        <div className="col-sm-4">
                          <p className="title"> Product Name </p>

                          <p> {item.facetoface.city}</p>
                          <p className="title"> Order ID </p>
                          <p> {item.order_id} </p>
                        </div>

                        <div className="col-sm-4">
                          <p className="title">Order Date</p>
                          <p>{item.order_date}</p>
                          <p className="title">Total Amount</p>

                          <p>{item.grand_total}.00</p>
                          <p className="title">Payment Status </p>
                          <p
                            style={{
                              color: "#fff",
                              background: item.facetoface.color,
                              width: "100px",
                              borderRadius: "5px",
                              padding: "10px",
                              textAlign: "center",
                            }}
                          >
                            {item.payment_status}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div
                className="col-md-4 regiBatches"
                style={{
                  textAlign: "center",
                  marginTop: "11%",
                  marginLeft: "39%",
                  fontSize: "24px",
                  fontFamily: "raleway",
                }}
              >
                <Lottie
                  options={defaultOptions}
                  height={200}
                  width={300}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
